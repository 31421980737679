import { createSlice } from "@reduxjs/toolkit";
import { initialData } from "./initialState";
import { getActivityAction } from "../../state/actions/getActivityAction";
import { getDashboardAction } from "../actions/getDashboardAction";
import { EStatuses } from "../../../../shared/enums/EStatuses";

export const { actions, reducer } = createSlice({
  name: "dashboard",
  initialState: initialData,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getActivityAction.fulfilled, (state, action) => {
        state.recentActivity.data = action.payload;
        state.recentActivity.status = EStatuses.idle;
      })
      .addCase(getActivityAction.pending, (state) => {
        state.recentActivity.status = EStatuses.pending;
      })
      .addCase(getActivityAction.rejected, (state, action) => {
        state.recentActivity.status = EStatuses.rejected;
        state.error = action.payload;
      });

    builder
      .addCase(getDashboardAction.fulfilled, (state, action) => {
        state.getDashboard.data = action.payload;
        state.getDashboard.status = EStatuses.idle;
      })
      .addCase(getDashboardAction.pending, (state) => {
        state.getDashboard.status = EStatuses.pending;
      })
      .addCase(getDashboardAction.rejected, (state, action) => {
        state.getDashboard.status = EStatuses.rejected;
        state.error = action.payload;
      });
  },
});
export default reducer;
