import { createAsyncThunk } from "@reduxjs/toolkit";
import { get2FAActionAPI } from "../../../../APIs/setting.api";
import {I2FAData} from "../../../../shared/interfaces/setting/index";
import { IValidationErrors } from '../../../../shared/interfaces/error';


export const get2FAAction = createAsyncThunk<
{url : I2FAData} | any,
undefined,
{
    rejectValue: IValidationErrors;
} 
>("admin/get2FA" , async ( _ , thunkAPI) =>{
    try{
        const response = await get2FAActionAPI()
        // console.log(response)
        return{
            url : response.data.data["url"]
        }
    } catch(error : any){
        return  thunkAPI.rejectWithValue({
            message : error.response.data.error,
            name : "admin/get2FA",
            code: error.response.status
        })
    }
})
