import { EStatuses } from "../../../../shared/enums/EStatuses";
import {
  IChosenUserData,
  IValidationErrors,
  TopUsersType,
} from "../../../../shared/interfaces";
import { IStatus } from "../../../../shared/interfaces/IStatus";
import {
  IFetchUser,
  IGetUserById,
} from "../../../../shared/interfaces/user/IUser";
import { TFetchUsers } from "../../../../shared/interfaces/user/TFetchUsers";
import { ITopUserExcel } from "../../../../shared/interfaces/user-management/topUsers/ITopUserExcel";
import { IPatchNewEmailRes } from "../../../../shared/interfaces/user/IPatchNewEmailRes";

export const initialData: {
  fetchUser: {
    status: IStatus;
    data?: TFetchUsers;
  };
  topUsers: {
    data?: TopUsersType;
    status: IStatus;
  };
  chosenUser: {
    data?: IChosenUserData;
    status: IStatus;
  };
  passLog: {
    status: IStatus;
    data: IGetUserById;
  };
  getTopUserExcel: {
    data: ITopUserExcel;
    status: IStatus;
  };
  patchNewEmailRes: {
    data: IPatchNewEmailRes;
    status: IStatus;
  };
  error: IValidationErrors | undefined;
} = {
  fetchUser: {
    status: EStatuses.idle,
  },
  topUsers: {
    status: EStatuses.idle,
  },
  chosenUser: {
    status: EStatuses.idle,
  },
  passLog: {
    data: { passwordLog: [], emailLogs: [] },
    status: EStatuses.idle,
  },
  getTopUserExcel: {
    data: {
      filepath: "",
    },
    status: "idle",
  },
  patchNewEmailRes: {
    data: {
      createdAt: "",
      ip : "",
      newEmail : "",
      userAgent : "",
      oldEmail : ""
    },
    status : "idle"
  },
  error: {},
};
