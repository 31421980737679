import moment, { MomentInput } from "moment";
import BNBBEP20 from "../../assets/images/newNetworks/BNB2.png";
import BTCBitcoin from "../../assets/images/networks/BTC-Bitcoin.png";
import ETHERC20 from "../../assets/images/newNetworks/ETHNew.png";
import TRXTRC20 from "../../assets/images/newNetworks/Tron.png";
import USDTBEP20 from "../../assets/images/networks/USDT-BEP20.png";
import USDTERC20 from "../../assets/images/networks/USDT-ERC20.png";
import USDTTRC20 from "../../assets/images/networks/USDT-TRC20.png";
// @ts-ignore
import walletValidator from "multicoin-address-validator";
import Swal, { SweetAlertOptions, SweetAlertResult } from "sweetalert2";
import { useEffect, useRef } from "react";

import numbro from "numbro";
import Cookie from "js-cookie";
import {
  IUsersData,
  IAdminLogAuthData,
  IDashboardTable,
  IStaticFee,
  IAdminLogWithdrawal,
  IAdminLogMainSetting,
  IAdminLogDepositSetting,
  IAdminLogMaintenance,
  IAdminLogWithdrawSetting,
  IChosenUserData,
} from "../interfaces/user";


import * as Yup from "yup";
import _ from "lodash";

import dayjs from "dayjs";
import { IPassChangesTableDataType } from "../interfaces";
const createBrowserHistory = require("history").createBrowserHistory;

export const history = createBrowserHistory({ forceRefresh: true });
export const noRefreshhistory = createBrowserHistory({ forceRefresh: false });

export const redirectTo = (path: string) => history.push(path);
export const noRefreshRedirectTo = (path: string) =>
  noRefreshhistory.push(path);

type Delay = number | null;
type TimerHandler = (...args: any[]) => void;

// export const TIME_FORMAT = 'YYYY-MM-DD HH:mm:SS Z';
export const TIME_FORMAT = "YYYY MMMM Do HH:mm";
export const NUMERIC_TIME_FORMAT = "YYYY.M.D HH:MM";
export const NUMERIC_TIME_FORMAT_WITH_SZ = "YYYY.MM.DD HH:mm"; //S:seconds Z:timezone
export const CYCLE_DURATION_IN_MILLISECONDS = 2678400000;
export const SWAL_CONF: SweetAlertOptions = {
  showClass: {
    popup: "animate__animated animate__bounceIn animate__faster",
  },
  hideClass: {
    popup: "animate__animated animate__bounceOut animate__faster",
  },
  showCloseButton: true,
  allowOutsideClick: false,
  buttonsStyling: false,
  allowEscapeKey: true,
  allowEnterKey: true,
  heightAuto: false,
  customClass: {
    confirmButton: "swal2ConfirmButton",
    cancelButton: "swal2CancelButton",
    closeButton: "swal2CloseButton",
    actions: "swal2Actions",
  },
};
export const timeFormat = (timeStamp: MomentInput) =>
  moment(moment(timeStamp).toLocaleString()).format(TIME_FORMAT);

export const numericTimeFormat = (timeStamp: MomentInput) =>
  moment(moment(timeStamp).toLocaleString()).format(
    NUMERIC_TIME_FORMAT_WITH_SZ
  );

// eslint-disable-next-line
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const useInterval = (callback: TimerHandler, delay: Delay) => {
  const savedCallbackRef = useRef<TimerHandler>();

  useEffect(() => {
    savedCallbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const handler = (...args: any[]) => savedCallbackRef.current!(...args);

    if (delay !== null) {
      const intervalId = setInterval(handler, delay);
      return () => clearInterval(intervalId);
    }
  }, [delay]);
};

export const REQUEST = "REQUEST";
export const SUCCESS = "SUCCESS";
export const FAILURE = "FAILURE";

export function createRequestTypes(base: string): { [key: string]: string } {
  return [REQUEST, SUCCESS, FAILURE].reduce(
    (acc: { [key: string]: string }, type) => {
      acc[type] = `${base}_${type}`;
      return acc;
    },
    {}
  );
}

export const passwordStrongChecker = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
);

export function ucFirst(str: string) {
  if (str) {
    return str
      .substr(0, str.length)
      .split("-")
      .map((item) => item[0].toUpperCase() + item.slice(1))
      .join(" ");
  }
}

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const depositLifeTime = 21427200;

export const calculateRemainingLifeTime = (start: number, end: number) =>
  `${moment(start * 1000).diff(end * 1000, "months")}  Months`;

export const earningBackgroundColorCalculator = () => {
  const colors = ["206a5d", "fecd1a", "fd8c04", "fa1616"];

  const eightYears = 8 * 365 * 3600000;
  let startDate = 1608488266921,
    endDate = 1608488266921 + eightYears;
  let totalAge = endDate - startDate;

  let firstPeriod = totalAge * 0.33333333333;

  let firstPeriodColorRange = parseInt(colors[1], 16) - parseInt(colors[0], 16);

  if (moment(Date.now()).isSameOrBefore(startDate + firstPeriod)) {
    return (
      parseInt(colors[0], 16) +
      firstPeriodColorRange / (firstPeriod + startDate)
    ).toFixed(0);
  }
};

export const capitalize = (el: string) => {
  if (el) {
    return el
      .substr(0, el.length)
      .split("-")
      .map((item) => item[0].toUpperCase() + item.slice(1))
      .join(" ");
  }
};

export const currencyFormat = (
  value?: number,
  precision?: number,
  spaceSeparated: boolean = false
): string =>
  numbro(value)
    .formatCurrency({
      mantissa: precision ? precision : 2,
      average: false,
      spaceSeparated: spaceSeparated,
      optionalMantissa: true,
      thousandSeparated: true,
      spaceSeparatedCurrency: spaceSeparated,
    })
    .toUpperCase();

export const acceptableCoins = [
  "BTC",
  "ETH",
  "BNB",
  "DOT",
  "XRP",
  "ADA",
  "LTC",
  "LINK",
  "THETA",
  "BCH",
  "XLM",
  "TRX",
  "FIL",
  "BTT",
  "DOGE",
  "VET",
  "EOS",
  "LUNA",
  "XMR",
  "MIOTA",
  "HOT",
  "BSV",
  "ATOM",
  "XTZ",
  "NEO",
  "FTT",
  "XEM",
  "HT",
  "DASH",
  "MKR",
  "SNX",
  "ZIL",
  "ZEC",
  "ENJ",
  "LEO",
  "TFUEL",
  "BAT",
  "ETC",
  "MATIC",
  "ALGO",
];
export function showLoading(
  title: string = "Please Wait...",
  text: string = "Please wait while we process your request..."
): Promise<SweetAlertResult> {
  return Swal.fire({
    ...SWAL_CONF,
    title,
    text,
    allowEscapeKey: false,
    allowOutsideClick: false,
    showCloseButton: false,
    showConfirmButton: false,
    didOpen(modalElement: HTMLElement): void {
      Swal.showLoading();
    },
  });
}

export const GAVerified = () => Cookie.get("verified") === "true";
export const hasToken = () => Cookie.get("token");

export const logout = () => {
  showLoading("", "Please wait while we process your request...");

  Cookie.remove("token");
  Cookie.remove("verified");
  Cookie.remove("twoFaActivationAt");

  localStorage.clear();
  sessionStorage.clear();
  redirectTo("/auth");
};

export const actionCreator = (type: any, payload: any) => ({
  type: type,
  payload: payload,
});

export function ajaxSuccessAlert(
  text: string,
  title?: string
): Promise<SweetAlertResult> {
  return Swal.fire({
    ...SWAL_CONF,
    icon: "success",
    title: title,
    text: `${text}`,
    confirmButtonText: "Continue",
  });
}

export const COINS = [
  {
    assetLogo: {
      imageData: ETHERC20,
    },
    //assetLogo: {Ethereum},
    assetName: "Ethereum",
    assetOriginalSymbol: "ETH",
    assetSymbol: "ETH",
    assetType: "crypto",
    slug: "ethereum",
    disabled: false,
    network: "ERC20",
  },
  {
    assetLogo: {
      imageData: TRXTRC20,
    },
    //assetLogo: {Ethereum},
    assetName: "Tron",
    assetOriginalSymbol: "TRX",
    assetSymbol: "TRX",
    assetType: "crypto",
    slug: "tron",
    disabled: false,
    network: "TRC20",
  },

  {
    assetLogo: {
      imageData: BTCBitcoin,
    },
    assetName: "Bitcoin",
    assetOriginalSymbol: "BTC",
    assetSymbol: "BTC",
    assetType: "crypto",
    slug: "bitcoin",
    disabled: false,
    network: "Bitcoin",
  },
  {
    assetLogo: {
      imageData: USDTTRC20,
    },
    assetName: "Tether",
    assetOriginalSymbol: "USDT",
    assetSymbol: "USDT",
    assetType: "crypto",
    slug: "tether",
    disabled: true,
    network: "TRC20",
  },
  {
    assetLogo: {
      imageData: USDTERC20,
    },
    assetName: "Tether",
    assetOriginalSymbol: "USDT",
    assetSymbol: "USDT",
    assetType: "crypto",
    slug: "tether",
    disabled: true,
    network: "ERC20",
  },
  {
    assetLogo: {
      imageData: USDTBEP20,
    },
    assetName: "Tether",
    assetOriginalSymbol: "USDT",
    assetSymbol: "USDT",
    assetType: "crypto",
    slug: "tether",
    disabled: true,
    network: "BEP20",
  },
  {
    assetLogo: {
      imageData: BNBBEP20,
    },
    assetName: "Binance Coin",
    assetOriginalSymbol: "BNB",
    assetSymbol: "BNB",
    assetType: "crypto",
    slug: "binance-coin",
    disabled: true,
    network: "BEP20",
  },
];

export const WALLETS = [
  {
    key: 1,
    value: "21312785123871952375123",
    label: "23129836128736-192836187230789123",
    disabled: false,
  },
  {
    key: 2,
    value: "21231312785123871952375123",
    label: "2312123989836128736-192836187230789123",
    disabled: false,
  },
];

export const walletValidationSchema = (cointype: string) => {
  
  return Yup.string()
    .required("You need to enter your wallet address")
    .test(
      "user_wallet_address",
      "Please enter a correct wallet address.",
      function (value) {
        if (value) return walletValidator.validate(value, cointype);

        return false;
      }
    );
};

export const usdTocoin = (usd: number, coinPrice: number) => usd / coinPrice;
const ipMaker = () => `192.168.${_.random(0, 256)}.${_.random(0, 256)}`;
const fakeDater = () => dayjs.unix(_.random(1639300092, 1702372092)).toString();
const fakeUserAgents = [
  "Mozilla/5.0 (iPad; U; CPU OS 3_2_1 like Mac OS X; en-us) AppleWebKit/531.21.10 (KHTML, like Gecko) Mobile/7B405",
  "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/108.0.0.0 Safari/537.36",
  "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:35.0) Gecko/20100101 Firefox/35.0",
];
const fakeUserAgenter = () => _.sample(fakeUserAgents);


export const passchageser = (number: number) => {
  let passchagaserArr: IPassChangesTableDataType[] = [];
  for (let i = 0; i < number; i++) {
    passchagaserArr.push({
      ip: ipMaker(),
      date: fakeDater(),
      userAgent: fakeUserAgenter(),
      key: i + 1,
    });
  }
  return passchagaserArr;
};

export const dataUsers: IUsersData[] = [
  {
    key: "1",
    name: "John Brown",
    referral: "850$",
    email: "h.m@gmail.com",
    earning: "500$",
    downlines: 12,
    upline: 9,
    directs: 4,
    withdrawls: "300$",
    passchages: passchageser(1),
    investments: "650$",
    walletAddress: "0xb6ea526334e4a2b9278c5b7d1678a120bdb2554d",
  },
  {
    key: "2",
    name: "Jim Green",
    referral: "850$",
    email: "h.m@gmail.com",
    earning: "500$",
    downlines: 7,
    upline: 9,
    directs: 6,
    withdrawls: "800$",
    passchages: passchageser(2),
    investments: "750$",
    walletAddress: "0x327492f452ec21e35ddd2b2eca74bc281903d740",
  },
  {
    key: "3",
    name: "Joe Black",
    referral: "850$",
    email: "h.m@gmail.com",
    earning: "500$",
    downlines: 6,
    upline: 9,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(3),
    investments: "850$",
    walletAddress: "0xe72752f55e69052ef40d989a843b9fa59e88b26b",
  },
  {
    key: "4",
    name: "Victoria",
    referral: "850$",
    email: "h.m@gmail.com",
    earning: "500$",
    downlines: 9,
    upline: 10,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(4),
    investments: "850$",
    walletAddress: "0xe72752f55e69052ef40d989a843b9fa59e88b26b",
  },
  {
    key: "5",
    name: "Albert",
    referral: "850$",
    email: "h.m@gmail.com",
    earning: "900$",
    downlines: 9,
    upline: 10,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(5),
    investments: "950$",
    walletAddress: "0xb6ea526334e4a2b9278c5b7d1678a120bdb2554d",
  },
  {
    key: "6",
    name: "Alice",
    referral: "850$",
    email: "h.m@gmail.com",
    earning: "900$",
    downlines: 9,
    upline: 3,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(6),
    investments: "950$",
    walletAddress: "0x9fd7b5be060bd5961fb645b6a5f93c1c05f55afa",
  },
  {
    key: "7",
    name: "Elena",
    referral: "850$",
    email: "h.m@gmail.com",
    earning: "900$",
    downlines: 9,
    upline: 3,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(7),
    investments: "950$",
    walletAddress: "0x327492f452ec21e35ddd2b2eca74bc281903d740",
  },
  {
    key: "8",
    name: "Elly",
    referral: "850$",
    email: "h.m@gmail.com",
    earning: "900$",
    downlines: 9,
    upline: 3,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(8),
    investments: "950$",
    walletAddress: "0x2927d643085f7bdea454c9bfc1992ea1240490d4",
  },
  {
    key: "9",
    name: "Julia",
    referral: "850$",
    email: "h.m@gmail.com",
    earning: "300$",
    downlines: 9,
    upline: 3,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(9),
    investments: "650$",
    walletAddress: "0x2927d643085f7bdea454c9bfc1992ea1240490d4",
  },
  {
    key: "10",
    name: "Merlin",
    referral: "850$",
    email: "h.m@gmail.com",
    earning: "700$",
    downlines: 9,
    upline: 3,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(10),
    investments: "750$",
    walletAddress: "0x9fd7b5be060bd5961fb645b6a5f93c1c05f55afa",
  },
  {
    key: "11",
    name: "Osman",
    referral: "850$",
    email: "h.m@gmail.com",
    earning: "700$",
    downlines: 9,
    upline: 3,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(11),
    investments: "850$",
    walletAddress: "0x2927d643085f7bdea454c9bfc1992ea1240490d4",
  },
  {
    key: "12",
    name: "Ahmad",
    referral: "850$",
    email: "h.m@gmail.com",
    earning: "700$",
    downlines: 9,
    upline: 3,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(12),
    investments: "750$",
    walletAddress: "0x9fd7b5be060bd5961fb645b6a5f93c1c05f55afa",
  },
  {
    key: "13",
    name: "pelin",
    referral: "850$",
    email: "h.m@gmail.com",
    earning: "700$",
    downlines: 9,
    upline: 3,
    directs: 3,
    withdrawls: "300$",
    passchages: passchageser(13),
    investments: "650$",
    walletAddress: "0x2927d643085f7bdea454c9bfc1992ea1240490d4",
  },
];

export const dataTop100Users: IUsersData[] = [
  {
    key: "1",
    name: "John Brown",
    referral: "100$",
    email: "h.asdfasdfasdfasfasdfsdfasdfm@gmail.com",
    earning: "500$",
    downlines: 12,
    upline: 9,
    directs: 4,
    withdrawls: "300$",
    passchages: passchageser(4),
    investments: "650$",
    walletAddress: "0xe72752f55e69052ef40d989a843b9fa59e88b26b",
  },
  {
    key: "2",
    name: "Jim Green",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "500$",
    downlines: 7,
    upline: 9,
    directs: 6,
    withdrawls: "800$",
    passchages: passchageser(4),
    investments: "750$",
    walletAddress: "0x6cd98edcabdc53443a35a498d768c5d71bf5acbf",
  },
  {
    key: "3",
    name: "Joe Black",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "500$",
    downlines: 6,
    upline: 9,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(4),
    investments: "850$",
    walletAddress: "0xe72752f55e69052ef40d989a843b9fa59e88b26b",
  },
  {
    key: "4",
    name: "Victoria",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "500$",
    downlines: 9,
    upline: 10,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(4),
    investments: "850$",
    walletAddress: "0x9fd7b5be060bd5961fb645b6a5f93c1c05f55afa",
  },
  {
    key: "5",
    name: "Albert",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "900$",
    downlines: 9,
    upline: 10,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(4),
    investments: "950$",
    walletAddress: "0x6cd98edcabdc53443a35a498d768c5d71bf5acbf",
  },
  {
    key: "6",
    name: "Alice",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "900$",
    downlines: 9,
    upline: 3,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(4),
    investments: "950$",
    walletAddress: "0x9fd7b5be060bd5961fb645b6a5f93c1c05f55afa",
  },
  {
    key: "7",
    name: "Elena",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "900$",
    downlines: 9,
    upline: 3,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(4),
    investments: "950$",
    walletAddress: "0xe72752f55e69052ef40d989a843b9fa59e88b26b",
  },
  {
    key: "8",
    name: "Elly",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "900$",
    downlines: 9,
    upline: 3,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(4),
    investments: "950$",
    walletAddress: "0x6cd98edcabdc53443a35a498d768c5d71bf5acbf",
  },
  {
    key: "9",
    name: "Julia",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "300$",
    downlines: 9,
    upline: 3,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(4),
    investments: "650$",
    walletAddress: "0x6cd98edcabdc53443a35a498d768c5d71bf5acbf",
  },
  {
    key: "10",
    name: "Merlin",
    referral: "300$",
    email: "h.m@gmail.com",
    earning: "700$",
    downlines: 9,
    upline: 3,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(4),
    investments: "750$",
    walletAddress: "0x9fd7b5be060bd5961fb645b6a5f93c1c05f55afa",
  },
  {
    key: "11",
    name: "Osman",
    referral: "800$",
    email: "h.m@gmail.com",
    earning: "700$",
    downlines: 9,
    upline: 3,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(4),
    investments: "850$",
    walletAddress: "0xb6ea526334e4a2b9278c5b7d1678a120bdb2554d",
  },
  {
    key: "12",
    name: "Ahmad",
    referral: "700$",
    email: "h.m@gmail.com",
    earning: "700$",
    downlines: 9,
    upline: 3,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(4),
    investments: "750$",
    walletAddress: "0xb6ea526334e4a2b9278c5b7d1678a120bdb2554d",
  },
  {
    key: "13",
    name: "pelin",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "700$",
    downlines: 9,
    upline: 3,
    directs: 3,
    withdrawls: "300$",
    passchages: passchageser(4),
    investments: "650$",
    walletAddress: "0xb6ea526334e4a2b9278c5b7d1678a120bdb2554d",
  },
];

export const dataTop100Wirhdrawls: IUsersData[] = [
  {
    key: "1",
    name: "John Brown",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "500$",
    downlines: 12,
    upline: 9,
    directs: 4,
    withdrawls: "300$",
    passchages: passchageser(4),
    investments: "650$",
    walletAddress: "0x6cd98edcabdc53443a35a498d768c5d71bf5acbf",
  },
  {
    key: "2",
    name: "Jim Green",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "500$",
    downlines: 7,
    upline: 9,
    directs: 6,
    withdrawls: "800$",
    passchages: passchageser(4),
    investments: "750$",
    walletAddress: "0x6cd98edcabdc53443a35a498d768c5d71bf5acbf",
  },
  {
    key: "3",
    name: "Joe Black",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "500$",
    downlines: 6,
    upline: 9,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(4),
    investments: "850$",
    walletAddress: "0xb6ea526334e4a2b9278c5b7d1678a120bdb2554d",
  },
  {
    key: "4",
    name: "Victoria",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "500$",
    downlines: 9,
    upline: 10,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(4),
    investments: "850$",
    walletAddress: "0xd65848d75b629053b4b0261c22fcb0b809fc6ce0",
  },
  {
    key: "5",
    name: "Albert",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "900$",
    downlines: 9,
    upline: 10,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(4),
    investments: "950$",
    walletAddress: "0xb6ea526334e4a2b9278c5b7d1678a120bdb2554d",
  },
  {
    key: "6",
    name: "Alice",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "900$",
    downlines: 9,
    upline: 3,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(4),
    investments: "950$",
    walletAddress: "0xd65848d75b629053b4b0261c22fcb0b809fc6ce0",
  },
  {
    key: "7",
    name: "Elena",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "900$",
    downlines: 9,
    upline: 3,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(4),
    investments: "950$",
    walletAddress: "0xd65848d75b629053b4b0261c22fcb0b809fc6ce0",
  },
  {
    key: "8",
    name: "Elly",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "900$",
    downlines: 9,
    upline: 3,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(4),
    investments: "950$",
    walletAddress: "0xd65848d75b629053b4b0261c22fcb0b809fc6ce0",
  },
  {
    key: "9",
    name: "Julia",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "300$",
    downlines: 9,
    upline: 3,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(4),
    investments: "650$",
    walletAddress: "0xd65848d75b629053b4b0261c22fcb0b809fc6ce0",
  },
];

export const dataTop100DReferrals: IUsersData[] = [
  {
    key: "1",
    name: "John Brown",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "500$",
    downlines: 12,
    upline: 9,
    directs: 4,
    withdrawls: "300$",
    passchages: passchageser(4),
    investments: "650$",
    walletAddress: "0xd65848d75b629053b4b0261c22fcb0b809fc6ce0",
  },
  {
    key: "2",
    name: "Jim Green",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "500$",
    downlines: 7,
    upline: 9,
    directs: 6,
    withdrawls: "800$",
    passchages: passchageser(4),
    investments: "750$",
    walletAddress: "0xeaf311a5e88f4fe3fff1caa36a18632a9db43c37",
  },
  {
    key: "3",
    name: "Joe Black",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "500$",
    downlines: 6,
    upline: 9,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(4),
    investments: "850$",
    walletAddress: "0xeaf311a5e88f4fe3fff1caa36a18632a9db43c37",
  },
  {
    key: "4",
    name: "Victoria",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "500$",
    downlines: 9,
    upline: 10,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(4),
    investments: "850$",
    walletAddress: "0xd65848d75b629053b4b0261c22fcb0b809fc6ce0",
  },
  {
    key: "5",
    name: "Albert",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "900$",
    downlines: 9,
    upline: 10,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(4),
    investments: "950$",
    walletAddress: "0xaca7ea7e472d4d22b09a83e74716e73ad7103334",
  },
  {
    key: "6",
    name: "Alice",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "900$",
    downlines: 9,
    upline: 3,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(4),
    investments: "950$",
    walletAddress: "0xd65848d75b629053b4b0261c22fcb0b809fc6ce0",
  },
  {
    key: "7",
    name: "Elena",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "900$",
    downlines: 9,
    upline: 3,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(4),
    investments: "950$",
    walletAddress: "0xeaf311a5e88f4fe3fff1caa36a18632a9db43c37",
  },
  {
    key: "8",
    name: "Elly",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "900$",
    downlines: 9,
    upline: 3,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(4),
    investments: "950$",
    walletAddress: "0xeaf311a5e88f4fe3fff1caa36a18632a9db43c37",
  },
  {
    key: "9",
    name: "Julia",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "300$",
    downlines: 9,
    upline: 3,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(4),
    investments: "650$",
    walletAddress: "0xaca7ea7e472d4d22b09a83e74716e73ad7103334",
  },
  {
    key: "10",
    name: "Merlin",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "700$",
    downlines: 9,
    upline: 3,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(4),
    investments: "750$",
    walletAddress: "0xd65848d75b629053b4b0261c22fcb0b809fc6ce0",
  },
  {
    key: "11",
    name: "Osman",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "700$",
    downlines: 9,
    upline: 3,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(4),
    investments: "850$",
    walletAddress: "0xaca7ea7e472d4d22b09a83e74716e73ad7103334",
  },
  {
    key: "12",
    name: "Ahmad",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "700$",
    downlines: 9,
    upline: 3,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(4),
    investments: "750$",
    walletAddress: "0xaca7ea7e472d4d22b09a83e74716e73ad7103334",
  },
  {
    key: "13",
    name: "pelin",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "700$",
    downlines: 9,
    upline: 3,
    directs: 3,
    withdrawls: "300$",
    passchages: passchageser(4),
    investments: "650$",
    walletAddress: "0xd65848d75b629053b4b0261c22fcb0b809fc6ce0",
  },
  {
    key: "13",
    name: "pelin",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "700$",
    downlines: 9,
    upline: 3,
    directs: 3,
    withdrawls: "300$",
    passchages: passchageser(4),
    investments: "650$",
    walletAddress: "0xaca7ea7e472d4d22b09a83e74716e73ad7103334",
  },
  {
    key: "13",
    name: "pelin",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "700$",
    downlines: 9,
    upline: 3,
    directs: 3,
    withdrawls: "300$",
    passchages: passchageser(4),
    investments: "650$",
    walletAddress: "0xaca7ea7e472d4d22b09a83e74716e73ad7103334",
  },
  {
    key: "13",
    name: "pelin",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "700$",
    downlines: 9,
    upline: 3,
    directs: 3,
    withdrawls: "300$",
    passchages: passchageser(4),
    investments: "650$",
    walletAddress: "0xd65848d75b629053b4b0261c22fcb0b809fc6ce0",
  },
  {
    key: "13",
    name: "pelin",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "700$",
    downlines: 9,
    upline: 3,
    directs: 3,
    withdrawls: "300$",
    passchages: passchageser(4),
    investments: "650$",
    walletAddress: "0xeaf311a5e88f4fe3fff1caa36a18632a9db43c37",
  },
  {
    key: "13",
    name: "pelin",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "700$",
    downlines: 9,
    upline: 3,
    directs: 3,
    withdrawls: "300$",
    passchages: passchageser(4),
    investments: "650$",
    walletAddress: "0xaca7ea7e472d4d22b09a83e74716e73ad7103334",
  },
  {
    key: "13",
    name: "pelin",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "700$",
    downlines: 9,
    upline: 3,
    directs: 3,
    withdrawls: "300$",
    passchages: passchageser(4),
    investments: "650$",
    walletAddress: "0xd65848d75b629053b4b0261c22fcb0b809fc6ce0",
  },
];

export const dataTop100DirReferrals: IUsersData[] = [
  {
    key: "1",
    name: "John Brown",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "500$",
    downlines: 12,
    upline: 9,
    directs: 4,
    withdrawls: "300$",
    passchages: passchageser(4),
    investments: "650$",
    walletAddress: "0xaca7ea7e472d4d22b09a83e74716e73ad7103334",
  },
  {
    key: "2",
    name: "Jim Green",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "500$",
    downlines: 7,
    upline: 9,
    directs: 6,
    withdrawls: "800$",
    passchages: passchageser(4),
    investments: "750$",
    walletAddress: "0x2927d643085f7bdea454c9bfc1992ea1240490d4",
  },
  {
    key: "3",
    name: "Joe Black",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "500$",
    downlines: 6,
    upline: 9,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(4),
    investments: "850$",
    walletAddress: "0x2927d643085f7bdea454c9bfc1992ea1240490d4",
  },
  {
    key: "4",
    name: "Victoria",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "500$",
    downlines: 9,
    upline: 10,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(4),
    investments: "850$",
    walletAddress: "0xaca7ea7e472d4d22b09a83e74716e73ad7103334",
  },
  {
    key: "5",
    name: "Albert",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "900$",
    downlines: 9,
    upline: 10,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(4),
    investments: "950$",
    walletAddress: "0x2927d643085f7bdea454c9bfc1992ea1240490d4",
  },
  {
    key: "6",
    name: "Alice",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "900$",
    downlines: 9,
    upline: 3,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(4),
    investments: "950$",
    walletAddress: "0xb6ea526334e4a2b9278c5b7d1678a120bdb2554d",
  },
  {
    key: "7",
    name: "Elena",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "900$",
    downlines: 9,
    upline: 3,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(4),
    investments: "950$",
    walletAddress: "0xb6ea526334e4a2b9278c5b7d1678a120bdb2554d",
  },
  {
    key: "8",
    name: "Elly",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "900$",
    downlines: 9,
    upline: 3,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(4),
    investments: "950$",
    walletAddress: "0x2927d643085f7bdea454c9bfc1992ea1240490d4",
  },
  {
    key: "9",
    name: "Julia",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "300$",
    downlines: 9,
    upline: 3,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(4),
    investments: "650$",
    walletAddress: "0xaca7ea7e472d4d22b09a83e74716e73ad7103334",
  },
  {
    key: "10",
    name: "Merlin",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "700$",
    downlines: 9,
    upline: 3,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(4),
    investments: "750$",
    walletAddress: "0x2927d643085f7bdea454c9bfc1992ea1240490d4",
  },
  {
    key: "11",
    name: "Osman",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "700$",
    downlines: 9,
    upline: 3,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(4),
    investments: "850$",
    walletAddress: "0xb6ea526334e4a2b9278c5b7d1678a120bdb2554d",
  },
  {
    key: "12",
    name: "Ahmad",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "700$",
    downlines: 9,
    upline: 3,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(4),
    investments: "750$",
    walletAddress: "0xaca7ea7e472d4d22b09a83e74716e73ad7103334",
  },
  {
    key: "13",
    name: "pelin",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "700$",
    downlines: 9,
    upline: 3,
    directs: 3,
    withdrawls: "300$",
    passchages: passchageser(4),
    investments: "650$",
    walletAddress: "0x2927d643085f7bdea454c9bfc1992ea1240490d4",
  },
  {
    key: "13",
    name: "pelin",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "700$",
    downlines: 9,
    upline: 3,
    directs: 3,
    withdrawls: "300$",
    passchages: passchageser(4),
    investments: "650$",
    walletAddress: "0x9fd7b5be060bd5961fb645b6a5f93c1c05f55afa",
  },
  {
    key: "13",
    name: "pelin",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "700$",
    downlines: 9,
    upline: 3,
    directs: 3,
    withdrawls: "300$",
    passchages: passchageser(4),
    investments: "650$",
    walletAddress: "0x2927d643085f7bdea454c9bfc1992ea1240490d4",
  },
  {
    key: "13",
    name: "pelin",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "700$",
    downlines: 9,
    upline: 3,
    directs: 3,
    withdrawls: "300$",
    passchages: passchageser(4),
    investments: "650$",
    walletAddress: "0x9fd7b5be060bd5961fb645b6a5f93c1c05f55afa",
  },
  {
    key: "13",
    name: "pelin",
    referral: "500$",
    email: "h.m@gmail.com",
    earning: "700$",
    downlines: 9,
    upline: 3,
    directs: 3,
    withdrawls: "300$",
    passchages: passchageser(4),
    investments: "650$",
    walletAddress: "0x9fd7b5be060bd5961fb645b6a5f93c1c05f55afa",
  },
];

export const dataChosenUsers: IUsersData[] = [
  {
    key: "1",
    name: "John Brown",
    referral: "850$",
    email: "h.a@gmail.com",
    earning: "500$",
    downlines: 12,
    upline: 9,
    directs: 4,
    withdrawls: "300$",
    passchages: passchageser(4),
    investments: "650$",
    walletAddress: "0xb6ea526334e4a2b9278c5b7d1678a120bdb2554d",
  },
  {
    key: "2",
    name: "Jim Green",
    referral: "850$",
    email: "h.b@gmail.com",
    earning: "500$",
    downlines: 7,
    upline: 9,
    directs: 6,
    withdrawls: "800$",
    passchages: passchageser(4),
    investments: "750$",
    walletAddress: "0x2927d643085f7bdea454c9bfc1992ea1240490d4",
  },
  {
    key: "3",
    name: "Joe Black",
    referral: "850$",
    email: "h.c@gmail.com",
    earning: "500$",
    downlines: 6,
    upline: 9,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(4),
    investments: "850$",
    walletAddress: "0x9fd7b5be060bd5961fb645b6a5f93c1c05f55afa",
  },
  {
    key: "4",
    name: "Victoria",
    referral: "850$",
    email: "h.d@gmail.com",
    earning: "500$",
    downlines: 9,
    upline: 10,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(4),
    investments: "850$",
    walletAddress: "0xb6ea526334e4a2b9278c5b7d1678a120bdb2554d",
  },
  {
    key: "5",
    name: "Albert",
    referral: "850$",
    email: "h.e@gmail.com",
    earning: "900$",
    downlines: 9,
    upline: 10,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(4),
    investments: "950$",
    walletAddress: "0x9fd7b5be060bd5961fb645b6a5f93c1c05f55afa",
  },
  {
    key: "6",
    name: "Elena",
    referral: "850$",
    email: "h.f@gmail.com",
    earning: "900$",
    downlines: 9,
    upline: 3,
    directs: 3,
    withdrawls: "600$",
    passchages: passchageser(4),
    investments: "950$",
    walletAddress: "0x327492f452ec21e35ddd2b2eca74bc281903d740",
  },
];

export const dataSort: IChosenUserData[] = [
  {
    key: "1",
    nickname: "John Brown",
    currentYearBalance: "850$",
    email: "h.a@gmail.com",
    remainingBalance: "500$",
    downlinesInvestments: "400$",
    downlineswithdrawal: "200$",
    monthDownlineInvestments: "50$",
    monthDownlineWithdrawals: "100$",
    monthRemainingBalance: "50$",
    withdrawls: "300$",
    passchages: passchageser(4),
    investments: "650$",
    spotWalletAddress: "0xb6ea526334e4a2b9278c5b7d1678a120bdb2554d",
  },
];

export const dataAdminAuthLog: IAdminLogAuthData[] = [
  {
    key: "1",
    fullName: "Jim Green",
    email: "jimm@gmail.com",
    date: "2022.08.06 / 14:30",
    type: "Sign In",
    status: ["Successful"],
    ip: "102.129.166.0",
    userAgent:
      "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36",
  },
  {
    key: "2",
    fullName: "Jim Green",
    email: "jimm@gmail.com",
    date: "2022.09.03 / 7:50",
    type: "Sign In",
    status: ["Unsuccessful"],
    ip: "102.129.166.0",
    userAgent:
      "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36",
  },
  {
    key: "3",
    fullName: "Jim Green",
    email: "jimm@gmail.com",
    date: "2022.09.03 / 16:35",
    type: "Sign In",
    status: ["Successful"],
    ip: "102.129.166.0",
    userAgent:
      "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36",
  },
  {
    key: "4",
    fullName: "Jim Green",
    email: "jimm@gmail.com",
    date: "2022.09.04 / 20:30",
    type: "Sign Out",
    status: ["Successful"],
    ip: "103.130.147.0",
    userAgent:
      "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36",
  },
  {
    key: "5",
    fullName: "Joe Black",
    email: "joe@gmail.com",
    date: "2022.09.09 / 22:44",
    type: "Sign In",
    status: ["Unsuccessful"],
    ip: "103.130.147.0",
    userAgent:
      "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36",
  },
  {
    key: "6",
    fullName: "Joe Black",
    email: "joe@gmail.com",
    date: "2022.09.10 / 13:55",
    type: "Sign Out",
    status: ["Unsuccessful"],
    ip: "103.130.147.0",
    userAgent:
      "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36",
  },
  {
    key: "7",
    fullName: "Joe Black",
    email: "joe@gmail.com",
    date: "2022.09.10 / 15:32",
    type: "Sign Out",
    status: ["Successful"],
    ip: "103.130.147.0",
    userAgent:
      "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36",
  },
  {
    key: "8",
    fullName: "Joe Black",
    email: "joe@gmail.com",
    date: "2022.09.12 / 19:56",
    type: "Sign In",
    status: ["Successful"],
    ip: "103.130.147.0",
    userAgent:
      "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36",
  },
  {
    key: "9",
    fullName: "Joe Black",
    email: "joe@gmail.com",
    date: "2022.09.24 / 12:00",
    type: "Sign Out",
    status: ["Successful"],
    ip: "103.130.147.0",
    userAgent:
      "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36",
  },
  {
    key: "10",
    fullName: "Jim Green",
    email: "jimm@gmail.com",
    date: "2022.09.30 / 12:36",
    type: "Sign In",
    status: ["Successful"],
    ip: "103.130.147.0",
    userAgent:
      "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36",
  },
  {
    key: "11",
    date: "2022.10.01 / 17:39",
    fullName: "Jim Green",
    email: "jimm@gmail.com",
    type: "Sign In",
    status: ["Successful"],
    ip: "103.130.147.0",
    userAgent:
      "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36",
  },
  {
    key: "12",
    date: "2022.10.03 / 15:15",
    fullName: "Jim Green",
    email: "jimm@gmail.com",
    type: "Sign Out",
    status: ["Unsuccessful"],
    ip: "103.130.147.0",
    userAgent:
      "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36",
  },
];

export const dataAdminLogWithdrawal: IAdminLogWithdrawal[] = [
  {
    key: "1",
    price: "150$",
    fullName: "Jim Green",
    email: "jimm@gmail.com",
    date: "2022.08.06 / 14:30",
    status: ["Approve"],
    ip: "102.129.166.0",
    userAgent:
      "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36",
  },
  {
    key: "2",
    price: "850$",
    fullName: "Jim Green",
    email: "jimm@gmail.com",
    date: "2022.09.03 / 7:50",
    status: ["Reject"],
    ip: "102.129.166.0",
    userAgent:
      "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36",
  },
  {
    key: "3",
    price: "650$",
    fullName: "Jim Green",
    email: "jimm@gmail.com",
    date: "2022.09.03 / 16:35",
    status: ["Approve"],
    ip: "102.129.166.0",
    userAgent:
      "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36",
  },
  {
    key: "4",
    price: "350$",
    fullName: "Jim Green",
    email: "jimm@gmail.com",
    date: "2022.09.04 / 20:30",
    status: ["Approve"],
    ip: "103.130.147.0",
    userAgent:
      "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36",
  },
  {
    key: "5",
    price: "200$",
    fullName: "Joe Black",
    email: "joe@gmail.com",
    date: "2022.09.09 / 22:44",
    status: ["Reject"],
    ip: "103.130.147.0",
    userAgent:
      "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36",
  },
  {
    key: "6",
    price: "230$",
    fullName: "Joe Black",
    email: "joe@gmail.com",
    date: "2022.09.10 / 13:55",
    status: ["Reject"],
    ip: "103.130.147.0",
    userAgent:
      "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36",
  },
  {
    key: "7",
    price: "290$",
    fullName: "Joe Black",
    email: "joe@gmail.com",
    date: "2022.09.10 / 15:32",
    status: ["Approve"],
    ip: "103.130.147.0",
    userAgent:
      "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36",
  },
  {
    key: "8",
    price: "690$",
    fullName: "Joe Black",
    email: "joe@gmail.com",
    date: "2022.09.12 / 19:56",
    status: ["Approve"],
    ip: "103.130.147.0",
    userAgent:
      "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36",
  },
  {
    key: "9",
    price: "390$",
    fullName: "Joe Black",
    email: "joe@gmail.com",
    date: "2022.09.24 / 12:00",
    status: ["Approve"],
    ip: "103.130.147.0",
    userAgent:
      "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36",
  },
  {
    key: "10",
    price: "490$",
    fullName: "Jim Green",
    email: "jimm@gmail.com",
    date: "2022.09.30 / 12:36",
    status: ["Approve"],
    ip: "103.130.147.0",
    userAgent:
      "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36",
  },
  {
    key: "11",
    price: "630$",
    date: "2022.10.01 / 17:39",
    fullName: "Jim Green",
    email: "jimm@gmail.com",
    status: ["Approve"],
    ip: "103.130.147.0",
    userAgent:
      "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36",
  },
  {
    key: "12",
    price: "340$",
    date: "2022.10.03 / 15:15",
    fullName: "Jim Green",
    email: "jimm@gmail.com",
    status: ["Reject"],
    ip: "103.130.147.0",
    userAgent:
      "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36",
  },
];

export const dataAdminLogMainSetting: IAdminLogMainSetting[] = [
  {
    key: "1",
    fullName: "Jim Green",
    email: "jimm@gmail.com",
    date: "2022.08.06 / 14:30",
    type: "Change Password",
  },
  {
    key: "2",
    fullName: "Jim Green",
    email: "jimm@gmail.com",
    date: "2022.08.09 / 18:30",
    type: "Change Name",
  },
  {
    key: "3",
    fullName: "Jim Green",
    email: "jimm@gmail.com",
    date: "2022.08.06 / 14:30",
    type: "Change Password",
  },
  {
    key: "4",
    fullName: "Elena",
    email: "elena@gmail.com",
    date: "2022.08.06 / 14:30",
    type: "Change Password",
  },
  {
    key: "5",
    fullName: "Elena",
    email: "elena@gmail.com",
    date: "2022.08.06 / 14:30",
    type: "GA Activation",
  },
  {
    key: "6",
    fullName: "Elena",
    email: "elena@gmail.com",
    date: "2022.08.06 / 14:30",
    type: "2FA Activation",
  },
];

export const dataAdminLogDepositSetting: IAdminLogDepositSetting[] = [
  {
    key: "1",
    fullName: "Jim Green",
    email: "jimm@gmail.com",
    date: "2022.08.06 / 14:30",
    action: "Add Wallet",
    detailes: "BTC -  0xb6ea526334e4a2b9278c5b7d1678a120bdb2554d",
  },
  {
    key: "2",
    fullName: "Jim Green",
    email: "jimm@gmail.com",
    date: "2022.08.09 / 18:30",
    action: "Enable Deposit",
    detailes: "BTC - Enabled",
  },
  {
    key: "3",
    fullName: "Jim Green",
    email: "jimm@gmail.com",
    date: "2022.08.06 / 14:30",
    action: "Edit Wallet",
    detailes:
      "BTC - 0xb6ea526334e4a2b9278c5b7d1678a120bdb2554d  To  BTC - 0xe72752f55e69052ef40d989a843b9fa59e88b26b",
  },
  {
    key: "4",
    fullName: "Elena",
    email: "elena@gmail.com",
    date: "2022.08.06 / 14:30",
    action: "Remove Wallet",
    detailes: "Eth - 0x388c818ca8b9251b393131c08a736a67ccb19297",
  },
  {
    key: "5",
    fullName: "Elena",
    email: "elena@gmail.com",
    date: "2022.08.06 / 14:30",
    action: "Add Wallet",
    detailes: "ETH - 0xf2f5c73fa04406b1995e397b55c24ab1f3ea726c",
  },
  {
    key: "6",
    fullName: "Elena",
    email: "elena@gmail.com",
    date: "2022.08.06 / 14:30",
    action: "Disable Wallet",
    detailes: "BTC - Disabled",
  },
  {
    key: "6",
    fullName: "Elena",
    email: "elena@gmail.com",
    date: "2022.08.06 / 14:30",
    action: "Edit Wallet",
    detailes:
      "BTC - 0xb6ea526334e4a2b9278c5b7d1678a120bdb2554d  To  BTC - 0xe72752f55e69052ef40d989a843b9fa59e88b26b",
  },
];

export const dataAdminLogWithdrawSetting: IAdminLogWithdrawSetting[] = [
  {
    key: "1",
    fullName: "Jim Green",
    email: "jimm@gmail.com",
    date: "2022.08.06 / 14:30",
    fee: "5%",
    crypto: "Bep20 - 1% - Unavailable",
    action: "Deactive Coin",
    details: "Bep20 Coin Deactivated",
  },
  {
    key: "2",
    fullName: "Jim Green",
    email: "jimm@gmail.com",
    date: "2022.08.09 / 18:30",
    fee: "2%",
    crypto: "BTC - 2% - Available",
    action: "Active Coin",
    details: "BTC Coin Activated / Commission Percent change from 5% to 2%",
  },
  {
    key: "3",
    fullName: "Jim Green",
    email: "jimm@gmail.com",
    date: "2022.08.09 / 18:30",
    fee: "2%",
    crypto: "ETH - 5% - Unavailable",
    action: "Deactive Coin",
    details: "ETH Coin Deactivated / Commission Percent change from 2% to 5%",
  },
];

export const dataAdminLogMaintenance: IAdminLogMaintenance[] = [
  {
    key: "1",
    fullName: "Jim Green",
    email: "jimm@gmail.com",
    date: "2022.08.06 / 14:30",
    pageName: "Deposit",
    mode: "Enable",
  },
  {
    key: "2",
    fullName: "Elena",
    email: "elena@gmail.com",
    date: "2022.08.06 / 14:30",
    pageName: "Dashboard",
    mode: "disable",
  },
  {
    key: "3",
    fullName: "Elena",
    email: "elena@gmail.com",
    date: "2022.08.06 / 14:30",
    pageName: "Sign Up",
    mode: "Enable",
  },
];

export const dataDashboardTableCurrentMonth: IDashboardTable[] = [
  {
    key: "1",
    date: "2022.11.01",
    withdraws: "350$",
    deposits: "750$",
  },
  {
    key: "2",
    date: "2022.11.02",
    withdraws: "300$",
    deposits: "250$",
  },
  {
    key: "3",
    date: "2022.11.03",
    withdraws: "100$",
    deposits: "250$",
  },
  {
    key: "4",
    date: "2022.11.04",
    withdraws: "450$",
    deposits: "230$",
  },
  {
    key: "5",
    date: "2022.11.05",
    withdraws: "400$",
    deposits: "250$",
  },
  {
    key: "6",
    date: "2022.11.06",
    withdraws: "300$",
    deposits: "350$",
  },
  {
    key: "7",
    date: "2022.11.07",
    withdraws: "300$",
    deposits: "750$",
  },
  {
    key: "8",
    date: "2022.11.08",
    withdraws: "300$",
    deposits: "750$",
  },
  {
    key: "9",
    date: "2022.11.09",
    withdraws: "300$",
    deposits: "750$",
  },
  {
    key: "10",
    date: "2022.11.10",
    withdraws: "300$",
    deposits: "750$",
  },
  {
    key: "11",
    date: "2022.11.11",
    withdraws: "300$",
    deposits: "750$",
  },
  {
    key: "13",
    date: "2022.11.13",
    withdraws: "300$",
    deposits: "750$",
  },
  {
    key: "14",
    date: "2022.11.14",
    withdraws: "300$",
    deposits: "750$",
  },
  {
    key: "15",
    date: "2022.11.15",
    withdraws: "300$",
    deposits: "750$",
  },
  {
    key: "16",
    date: "2022.11.16",
    withdraws: "300$",
    deposits: "750$",
  },
  {
    key: "17",
    date: "2022.11.17",
    withdraws: "300$",
    deposits: "750$",
  },
  {
    key: "18",
    date: "2022.11.18",
    withdraws: "300$",
    deposits: "750$",
  },
  {
    key: "19",
    date: "2022.11.19",
    withdraws: "300$",
    deposits: "750$",
  },
  {
    key: "20",
    date: "2022.11.20",
    withdraws: "300$",
    deposits: "750$",
  },
  {
    key: "21",
    date: "2022.11.21",
    withdraws: "300$",
    deposits: "750$",
  },
  {
    key: "22",
    date: "2022.11.22",
    withdraws: "300$",
    deposits: "750$",
  },
  {
    key: "23",
    date: "2022.11.23",
    withdraws: "300$",
    deposits: "750$",
  },
  {
    key: "24",
    date: "2022.11.24",
    withdraws: "300$",
    deposits: "750$",
  },
  {
    key: "25",
    date: "2022.11.25",
    withdraws: "300$",
    deposits: "750$",
  },
  {
    key: "26",
    date: "2022.11.26",
    withdraws: "300$",
    deposits: "750$",
  },
  {
    key: "27",
    date: "2022.11.27",
    withdraws: "300$",
    deposits: "750$",
  },
  {
    key: "28",
    date: "2022.11.28",
    withdraws: "300$",
    deposits: "750$",
  },
  {
    key: "29",
    date: "2022.11.29",
    withdraws: "300$",
    deposits: "750$",
  },
  {
    key: "30",
    date: "2022.11.30",
    withdraws: "300$",
    deposits: "750$",
  },
];

export const dataDashboardTableLastMonth: IDashboardTable[] = [
  {
    key: "1",
    date: "2022.10.01",
    withdraws: "350$",
    deposits: "750$",
  },
  {
    key: "2",
    date: "2022.10.02",
    withdraws: "300$",
    deposits: "250$",
  },
  {
    key: "3",
    date: "2022.10.03",
    withdraws: "100$",
    deposits: "250$",
  },
  {
    key: "4",
    date: "2022.10.04",
    withdraws: "450$",
    deposits: "230$",
  },
  {
    key: "5",
    date: "2022.10.05",
    withdraws: "400$",
    deposits: "250$",
  },
  {
    key: "6",
    date: "2022.10.06",
    withdraws: "300$",
    deposits: "350$",
  },
  {
    key: "7",
    date: "2022.10.07",
    withdraws: "300$",
    deposits: "750$",
  },
  {
    key: "8",
    date: "2022.10.08",
    withdraws: "300$",
    deposits: "750$",
  },
  {
    key: "9",
    date: "2022.10.09",
    withdraws: "300$",
    deposits: "750$",
  },
  {
    key: "10",
    date: "2022.10.10",
    withdraws: "300$",
    deposits: "750$",
  },
  {
    key: "11",
    date: "2022.10.11",
    withdraws: "300$",
    deposits: "750$",
  },
  {
    key: "13",
    date: "2022.10.13",
    withdraws: "300$",
    deposits: "750$",
  },
  {
    key: "14",
    date: "2022.10.14",
    withdraws: "300$",
    deposits: "750$",
  },
  {
    key: "15",
    date: "2022.10.15",
    withdraws: "300$",
    deposits: "750$",
  },
  {
    key: "16",
    date: "2022.10.16",
    withdraws: "300$",
    deposits: "750$",
  },
  {
    key: "17",
    date: "2022.10.17",
    withdraws: "300$",
    deposits: "750$",
  },
  {
    key: "18",
    date: "2022.10.18",
    withdraws: "300$",
    deposits: "750$",
  },
  {
    key: "19",
    date: "2022.10.19",
    withdraws: "300$",
    deposits: "750$",
  },
  {
    key: "20",
    date: "2022.10.20",
    withdraws: "300$",
    deposits: "750$",
  },
  {
    key: "21",
    date: "2022.10.21",
    withdraws: "300$",
    deposits: "750$",
  },
  {
    key: "22",
    date: "2022.10.22",
    withdraws: "300$",
    deposits: "750$",
  },
  {
    key: "23",
    date: "2022.10.23",
    withdraws: "300$",
    deposits: "750$",
  },
  {
    key: "24",
    date: "2022.10.24",
    withdraws: "300$",
    deposits: "750$",
  },
  {
    key: "25",
    date: "2022.10.25",
    withdraws: "300$",
    deposits: "750$",
  },
  {
    key: "26",
    date: "2022.10.26",
    withdraws: "300$",
    deposits: "750$",
  },
  {
    key: "27",
    date: "2022.10.27",
    withdraws: "300$",
    deposits: "750$",
  },
  {
    key: "28",
    date: "2022.10.28",
    withdraws: "300$",
    deposits: "750$",
  },
  {
    key: "29",
    date: "2022.10.29",
    withdraws: "300$",
    deposits: "750$",
  },
  {
    key: "30",
    date: "2022.10.30",
    withdraws: "300$",
    deposits: "750$",
  },
];

export const dataWithdrawStaticFee: IStaticFee[] = [
  {
    key: "1",
    feeLevel: "1",
    upTo: "4,999 $",
    percent: "1",
  },
  {
    key: "2",
    feeLevel: "2",
    upTo: "49,999 $",
    percent: "2",
  },
  {
    key: "3",
    feeLevel: "3",
    upTo: "Unlimited",
    percent: "3",
  },
];
export const alwaysOpenPages = [
  "admin-management/profile",
  // "dashboard",
  "setting/main-setting",
];
/**
 * onlysubPagewithseperatePermission
 */
export const logs = "logger";
export const checkIfOpenPage = (alwaysOpenPages: string[], pathname: string) =>
  alwaysOpenPages.some((openPage) =>
    openPage.toLowerCase().includes(pathname.toLowerCase())
  );

// interface IChildedObj<T> {
//   [key: string]: unknown;
//   children: T[];
// }

// const replaceChildren<ChildrenType>=(childedObj: IChildedObj<IChildedObj>[],newChildren:IChildedObj[])=>navitem=
interface IParentObj<ChildrenType> {
  [key: string]: any;
  children?: ChildrenType;
}

export function replaceChildren<ChildrenType>(
  parentObj: IParentObj<ChildrenType>,
  newChildren: ChildrenType
): IParentObj<ChildrenType> | null {
  if (!(newChildren as []).length) return null;

  if (parentObj.children) parentObj.children = newChildren;

  return parentObj;
}

export const networkedCoins = [
  {
    networkName: "ERC20",
    coinSymbol: "ETH",
    icon: ETHERC20,
  },
  {
    networkName: "Bitcoin",
    coinSymbol: "BTC",
    icon: BTCBitcoin,
  },
  {
    networkName: "BEP20",
    coinSymbol: "BNB",
    icon: BNBBEP20,
  },
  {
    networkName: "TRC20",
    coinSymbol: "TRX",
    icon: TRXTRC20,
  },
  {
    networkName: "BEP20",
    coinSymbol: "USDT",
    icon: USDTBEP20,
  },
  {
    networkName: "ERC20",
    coinSymbol: "USDT",
    icon: USDTERC20,
  },
  {
    networkName: "TRC20",
    coinSymbol: "USDT",
    icon: USDTTRC20,
  },
] as const;
