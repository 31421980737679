import React from 'react';
import { Switch , Redirect , RouteComponentProps , Route } from 'react-router-dom';
// import { PrivateRoute } from './PrivateRoute';
//import SignIn from '../features/authentication/pages/SignIn';
import LogInPage from '../features/authentication/pages/LogInPage';
//import Main from '../layout/Main';
interface Props extends RouteComponentProps {}
const AuthRoutes = (props : Props) => {
    const {url}= props.match;
    // console.log(url);
    return (
        <div className='login-main'>
            <Switch>
                    <Route exact={true} path={`${url}/signin`} component={LogInPage}/>
                    <Route exact={true} path={`${url}/`} component={LogInPage}/>
            </Switch>
        </div>
    );
};

export default AuthRoutes;