import { lazy, Suspense } from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import { GARoute } from "./GARoute";
import DepositSettingPage from "../features/setting/pages/DepositSettingPage";
import MainSettingPage from "../features/setting/pages/MainSettingPage";
import WithdrawSetting from "../features/setting/pages/WithdrawSetting";
import { useTwoFaActivated } from "../shared/service/useTwoFaActivated";
import { PermissionRouteGuard } from "./PermissionRouteGuard";
import { ENewPermissions, EPermissions } from "../shared/enums/EPermissions";
const SettingRoutes = (props: RouteComponentProps) => {
  const { url } = props.match;
  const twoFaActivatedAt = useTwoFaActivated();

  if (!twoFaActivatedAt) {
    return (
      <Switch>
        <Route
          exact={true}
          path={`${url}/main-setting`}
          component={MainSettingPage}
        />
      </Switch>
    );
  }
  return (
    <div>
      <Switch>
        <Route
          exact={true}
          path={`${url}/main-setting`}
          component={MainSettingPage}
        />
        <PermissionRouteGuard section={ENewPermissions.setting}>
          <>
            <Route
              exact={true}
              path={`${url}/deposit-setting`}
              component={DepositSettingPage}
            />

            <Route
              exact={true}
              path={`${url}/withdraw-setting`}
              component={WithdrawSetting}
            />
          </>
        </PermissionRouteGuard>
        <Redirect from="/" to={`${url}/main-setting`} />
      </Switch>
    </div>
  );
};

export default SettingRoutes;
