
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IValidationErrors } from "../../../../shared/interfaces/error";
import { patchNewEmailAPI } from "../../../../APIs/user.api";
import { IPatchNewEmailRes } from "../../../../shared/interfaces/user/IPatchNewEmailRes";



export const patchNewEmailAction = createAsyncThunk<
IPatchNewEmailRes,
any,
{
    rejectValue: IValidationErrors;
}
>("user/newEmail" , async(id , thunkAPI) =>{
    try{
        return await patchNewEmailAPI(id)
        .then((response)=>{
            return response.data.data
        })
        .catch((error)=>{
            return thunkAPI.rejectWithValue({
                message : error.response.data.error,
                name: "user/newEmail",
                code: error.response.status,
            })
        })

    } catch(error : any){
        return thunkAPI.rejectWithValue({
            message : error.response.data.error,
            name: "user/newEmail",
            code: error.response.status,
        })
    }
})