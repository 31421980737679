import axios from "axios";
import { TokenClient } from "../shared/service/addTokenToReq";
import { requestInterceptor } from "../shared/service/useRequestInterceptor";
import {
  IGetTopUsersActionAPIArgs,
  TGetUserAPIParams,
} from "../shared/interfaces";

const userAxios = axios.create({
  baseURL: `${process.env.REACT_APP_API_ROOT}/admin/user`,
});
requestInterceptor(userAxios);
TokenClient(userAxios);

export const getUserAPI = (params: any) => userAxios.get(`/user`, params);

export const getChosenUserAPI = (params: TGetUserAPIParams) =>
  userAxios.get(`/chosen`, { params: params });

export const getTopUsersActionAPI = ({
  page,
  limit,
  type,
}: IGetTopUsersActionAPIArgs) =>
  userAxios({
    method: "GET",
    url: "/top",
    params: { page, limit, type },
  });


export const getTopUserExcelAPI = (type : any) => userAxios.get(`/top/file` ,{ params : type});

export const getUserByIdAPI = (id: any) => userAxios.get(`/user/${id}`);

export const patchNewEmailAPI = ({ id, newEmail} : any) => userAxios.patch(`/${id}` , {newEmail});
