import { createSlice, current } from "@reduxjs/toolkit";
import { initialData } from "./initialState";
import { getUserAction } from "../actions/getUserAction";
import { getChosenUserAction } from "../actions/getChosenUserAction";
import { EStatuses } from "../../../../shared/enums/EStatuses";
import { getUserByIdAction } from "../actions/getUserByIdAction";
import { getTopUsersAction } from "../actions/getTopUsersAction";
import { getTopUserExcelAction } from "../actions/getTopUserExcelAction";
import { patchNewEmailAction } from "../actions/patchNewEmailAction";

export const { actions, reducer } = createSlice({
  name: "user",
  initialState: initialData,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTopUserExcelAction.fulfilled, (state, action) => {
        state.getTopUserExcel.status = "idle";
        state.getTopUserExcel.data = action.payload;
      })
      .addCase(getTopUserExcelAction.pending, (state) => {
        state.getTopUserExcel.status = "pending";
      })
      .addCase(getTopUserExcelAction.rejected, (state, action) => {
        state.getTopUserExcel.status = "rejected";
        state.error = action.payload;
      });
    builder
      .addCase(getUserAction.fulfilled, (state, action) => {
        state.fetchUser.status = EStatuses.idle;
        state.fetchUser.data = action.payload;
      })
      .addCase(getUserAction.pending, (state) => {
        state.fetchUser.status = EStatuses.pending;
      })
      .addCase(getUserAction.rejected, (state, action) => {
        state.fetchUser.status = EStatuses.rejected;
        state.error = action.payload;
      });

    builder
      .addCase(getUserByIdAction.fulfilled, (state, action) => {
        state.passLog.status = "idle";
        state.passLog.data = action.payload;
        
      })
      .addCase(getUserByIdAction.pending, (state) => {
        state.passLog.status = "pending";
      })
      .addCase(getUserByIdAction.rejected, (state, action) => {
        state.passLog.status = "rejected";
        state.error = action.payload;
      });
    builder
      .addCase(getTopUsersAction.fulfilled, (state, action) => {
        state.topUsers.status = EStatuses.idle;

        if (action.payload) state.topUsers.data = action.payload;
      })

      .addCase(getTopUsersAction.pending, (state) => {
        state.topUsers.status = EStatuses.pending;
      })
      .addCase(getTopUsersAction.rejected, (state, action) => {
        state.topUsers.status = EStatuses.rejected;
        // if (action.payload?.message) {
        //   ajaxErrorAlert(action.payload.message);
        // }
      });

    builder
      .addCase(getChosenUserAction.fulfilled, (state, action) => {
        state.topUsers.status = EStatuses.idle;

        if (action.payload) state.chosenUser.data = action.payload;
      })

      .addCase(getChosenUserAction.pending, (state) => {
        state.topUsers.status = EStatuses.pending;
      })
      .addCase(getChosenUserAction.rejected, (state) => {
        state.topUsers.status = EStatuses.rejected;
      });

      builder
      .addCase(patchNewEmailAction.fulfilled , (state , action)=>{
        state.patchNewEmailRes.status = "idle"
        state.patchNewEmailRes.data = action.payload
        state.passLog.data.emailLogs.push(state.patchNewEmailRes.data)
      })
      .addCase(patchNewEmailAction.pending , (state)=>{
        state.patchNewEmailRes.status = "pending"
      })
      .addCase(patchNewEmailAction.rejected , (state, action)=>{
        state.patchNewEmailRes.status = "rejected"
        state.error = action.payload
      })
  },
});

export default reducer;
