import React, { lazy, Suspense, useEffect , useCallback } from "react";
import { Loading } from "../../../shared/components/utils/Loading";
import { useAppDispatch  , useAppSelector} from "../../../store";
import { getWalletAction } from "../actions/depositSetting_Actions/getWallet-Action";
const DepositSetting = lazy(() => import("../components/DepositSetting"));
function DepositSettingPage() {
  const dispatch = useAppDispatch();
  const { getWallet } = useAppSelector((state) => state.setting);
  const fetchData = useCallback(async ()=>{
   const res = await dispatch(getWalletAction())
   return res
  } , [DepositSetting])
  // console.log()
  useEffect(()=>{
    fetchData()
  }, [fetchData])
  return (
    <div>

      {
        getWallet.status === "idle" ?
        <Suspense fallback={<Loading />}>
        <DepositSetting />
        </Suspense>
        :
        <Loading />
      }
      
    </div>
  );
}

export default DepositSettingPage;
