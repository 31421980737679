import "antd/dist/antd.css";
import "./assets/scss/index.scss";
import MasterRoutes from "./routes/MasterRoutes";
import RestrictAccess from "./RestrictAccess";
import { isMobile, isTablet } from "react-device-detect";
import { Switch, Route, Redirect } from "react-router-dom";
import { useRedirectToDashboard } from "./shared/service/useRedirectToDashboard";

function App() {
  useRedirectToDashboard();
  if (isMobile || isTablet) {
    return (
      <Switch>
        <Route
          exact={true}
          path={`/restrict-access`}
          component={RestrictAccess}
        />
        <Redirect from="*" to="/restrict-access" />
      </Switch>
    );
  }

  return (
    <div className="App">
      <MasterRoutes />
    </div>
  );
}

export default App;
