// @flow
import { FileExcelOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { IExportToExcelProps } from "../../interfaces";
import { DownloadButton } from "./DownloadButton";

export const ExportToExcel = ({
  downloadButtonClass,
  svgClass,
  onClick,
  disabled
}: IExportToExcelProps) => {
  return (
    <Tooltip placement="right" title={"Export to Excel"}>
      <DownloadButton
        payload={{ downloadType: "excel-export" }}
        type="text"
        shape="round"
        className={downloadButtonClass}
        onClick={onClick}
        disabled={disabled}
      >
        <FileExcelOutlined className={"excelIcon " + svgClass} />
      </DownloadButton>
    </Tooltip>
  );
};
