import { Form, Input, InputNumber, Switch } from "antd";
import { useAppSelector } from "../../../../store";
import { IFetchCrypto } from "../../../../shared/interfaces/setting/index";
import { useState } from "react";

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: any;
  title: any;
  inputType: "number" | "text";
  statusButton: boolean;
  record: IFetchCrypto;
  index: number;
  children: React.ReactNode;
}

export const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  statusButton,
  ...restProps
}) => {
  const inputNode =
    inputType === "number" ? (
      <InputNumber />
    ) : (
      <div className="inputEdit_wrapper">
        <Input className="inputEdit" maxLength={3} />
      </div>
    );
  return (
    <td {...restProps}>
      {editing ? (
        <>
          <Form.Item
            className="inputEdit_wrapper"
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `Please enter commission percent`,
              },
            ]}
          >
            {dataIndex === "commissionPercent" && (
              <div>
                <Input type="number" className="inputEdit" maxLength={3} />
              </div>
            )}
          </Form.Item>
          <Form.Item className="cryptoStatus_wrapper" name={dataIndex}>
            {dataIndex === "isWithdrawAvailable" && (
              <Switch
                defaultChecked={record.isWithdrawAvailable}
                className="Crypto_status"
              />
            )}
          </Form.Item>
        </>
      ) : (
        children
      )}
    </td>
  );
};
