import React from 'react';
import AuthRoutes from '../routes/AuthRoutes';
import { RouteComponentProps } from "react-router-dom";
interface Props extends RouteComponentProps {}
export const Auth = (props : Props) => {
    return (
        <div>
            <AuthRoutes {...props}/>
        </div>
    );
};