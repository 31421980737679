import { createListenerMiddleware } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { actions as sharedActions } from "../reducers/shared-reducer";
import { showLoading } from "./utils";
export const apiPendingMiddleware = createListenerMiddleware();

apiPendingMiddleware.startListening({
  predicate: (action, currState, prevState) => {
    if (/pending|rejected|fulfilled/i.test(action.type)) return true;
    return false;
  },
  effect: async (action, listenerApi) => {
    // console.log("action ", action);
    if (action.type.toLowerCase().includes("pending")) {
      showLoading();
      listenerApi.dispatch(sharedActions.setLoading());
    }

    // console.log("Current state ", listenerApi.getState());
    if (/fulfilled/i.test(action.type)) {
      Swal.close();
      listenerApi.dispatch(sharedActions.setLoading());
    }
    // console.log("Previous state ", listenerApi.getOriginalState());
    // console.log("Current state ", listenerApi.getState());
  },
});
