import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  IUpdateWalletAPIARG,
  IUpdateWalletReturn,
} from "../../../../shared/interfaces/setting/index";
import { IValidationErrors } from "../../../../shared/interfaces/error";
import { updateWalletAPI } from "../../../../APIs/setting.api";

export const updateWalletAction = createAsyncThunk<
  IUpdateWalletReturn | void,
  IUpdateWalletAPIARG,
  any
>("admin/updateWallet", async (walletData, thunkAPI) => {
  try {
    return await updateWalletAPI(walletData)
      .then((response) => {
        return { ...response.data.data, cryptoPairId : walletData.cryptoPairId  };
      })
      .catch((err) => {
        return thunkAPI.rejectWithValue(err);
      });
  } catch (error: any) {
    return error;
  }
});
