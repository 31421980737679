import { createSlice, current } from "@reduxjs/toolkit";
import { initialData } from "./initialState";
import { get2FAAction } from "../actions/mainSetting-Actions/get2FA-Actions";
import { getStaticFeeAction } from "../actions/withdrawSetting-Actions/getStaticFeeAction";
import { fetchCryptoAction } from "../actions/withdrawSetting-Actions/fetchCryptoAction";
import { activate2FAAction } from "../actions/mainSetting-Actions/activate2FA-Action";
import { getWalletAction } from "../actions/depositSetting_Actions/getWallet-Action";
import { createWalletAction } from "../actions/depositSetting_Actions/createWallet-Action";
import { ajaxSuccessAlert } from "../../../shared/components/utils/alert";
import { patchCryptoAction } from "../actions/withdrawSetting-Actions/patchCryptoAction";
import { patchStaticFeeAction } from "../actions/withdrawSetting-Actions/patchStaticFeeAction";
import { updateWalletAction } from "../actions/depositSetting_Actions/updateWallet-Action";
import { deleteWalletAction } from "../actions/depositSetting_Actions/deleteWallet-Action";
import { EStatuses } from "../../../shared/enums/EStatuses";
import { patchCoinStatusAction } from "../actions/depositSetting_Actions/patchCoinStatus-Action";
export const { actions, reducer } = createSlice({
  name: " setting",
  initialState: initialData,
  reducers: {},
  extraReducers: (builder) => {
    //get 2fd
    builder
      .addCase(get2FAAction.fulfilled, (state, action) => {
        state.twoFAResponse.status = EStatuses.idle;
        state.twoFAResponse.data = action.payload;
      })
      .addCase(get2FAAction.pending, (state) => {
        state.twoFAResponse.status = EStatuses.pending;
      })
      .addCase(get2FAAction.rejected, (state, action) => {
        state.twoFAResponse.status = EStatuses.rejected;
        state.error = action.payload;
      });
    //activate 2fa
    builder
      .addCase(activate2FAAction.fulfilled, (state, action) => {
        state.activate2FA.status = EStatuses.idle;
        state.activate2FA.data = action.payload;
        // if ((state.activate2FA.status = EStatuses.idle)) {
        //   ajaxSuccessAlert("2FA Activated");
        //   redirectTo("/dashboard");
        // }
      })
      .addCase(activate2FAAction.pending, (state) => {
        state.activate2FA.status = EStatuses.pending;
      })
      .addCase(activate2FAAction.rejected, (state, action) => {
        state.activate2FA.status = EStatuses.rejected;
        state.error = action.payload;
      });
    // static Fee

    builder
      .addCase(getStaticFeeAction.fulfilled, (state, action) => {
        state.staticFee.status = EStatuses.idle;
        state.staticFee.dataFetched = true;
        if (action.payload) {
          state.staticFee.data = action.payload;
        }
      })
      .addCase(getStaticFeeAction.pending, (state) => {
        state.staticFee.dataFetched = false;
        state.staticFee.status = EStatuses.pending;
      })
      .addCase(getStaticFeeAction.rejected, (state, action) => {
        state.staticFee.status = EStatuses.rejected;
        state.staticFee.dataFetched = false;
        state.error = action.payload;
      });

    // patch static fee
    builder
      .addCase(patchStaticFeeAction.fulfilled, (state, action) => {
        state.patchStaticFee.status = EStatuses.idle;
        state.patchStaticFee.data = action.payload;
        const newIndex = state.staticFee.data.findIndex(
          (e) => e.id === state.patchStaticFee.data.id
        );
        state.staticFee.data[newIndex] = state.patchStaticFee.data;
      })
      .addCase(patchStaticFeeAction.pending, (state) => {
        state.patchStaticFee.status = EStatuses.pending;
      })
      .addCase(patchStaticFeeAction.rejected, (state, action) => {
        state.patchStaticFee.status = EStatuses.rejected;
        state.error = action.payload;
      });
    // Crypto Setting

    builder
      .addCase(fetchCryptoAction.fulfilled, (state, action) => {
        state.fetchCrypto.status = EStatuses.idle;

        state.fetchCrypto.data = action.payload;
      })
      .addCase(fetchCryptoAction.pending, (state) => {
        state.fetchCrypto.status = EStatuses.pending;
      })
      .addCase(fetchCryptoAction.rejected, (state, action) => {
        state.fetchCrypto.status = EStatuses.rejected;
        state.error = action.payload;
      });

    // crypto patch
    builder
      .addCase(patchCryptoAction.fulfilled, (state, action) => {
        state.patchCrypto.status = EStatuses.idle;
        state.patchCryptoRes.data = action.payload;
        const newIndex = state.fetchCrypto.data.findIndex(
          (elem) => elem.cryptoPairId === state.patchCryptoRes.data.cryptoPairId
        );
        state.fetchCrypto.data[newIndex] = state.patchCryptoRes.data;
      })
      .addCase(patchCryptoAction.pending, (state) => {
        state.patchCrypto.status = EStatuses.pending;
      })
      .addCase(patchCryptoAction.rejected, (state, action) => {
        state.patchCrypto.status = EStatuses.rejected;
        state.error = action.payload;
      });

    // deposit setting
    builder
      .addCase(getWalletAction.fulfilled, (state, action) => {
        state.getWallet.status = EStatuses.idle;
        state.getWallet.data = action.payload;
      })
      .addCase(getWalletAction.pending, (state) => {
        state.getWallet.status = EStatuses.pending;
      })
      .addCase(getWalletAction.rejected, (state, action) => {
        state.getWallet.status = EStatuses.rejected;
        state.error = action.payload;
      });

    builder
      .addCase(createWalletAction.fulfilled, (state, action) => {
        state.createDepositResponse.status = EStatuses.idle;
        const pushnewWallet = (obj: any) => {
          return {
            id: obj.id,
            address: obj.address,
            maxLimit: obj.maxLimit,
            limitationStatus: obj.limitationStatus,
          };
        };
        state.createDepositResponse.data = action.payload;
        ajaxSuccessAlert("Wallet Added successfully");

        const cryptopairIndex = state.getWallet.data.findIndex(
          ({ cryptoPairId }) => cryptoPairId === action.payload.cryptoNetwork
        );
        state.getWallet.data[cryptopairIndex].adminWallets.unshift(
          pushnewWallet(action.payload)
        );
      })
      .addCase(createWalletAction.pending, (state) => {
        state.createDepositResponse.status = EStatuses.pending;
      })
      .addCase(createWalletAction.rejected, (state, action) => {
        state.createDepositResponse.status = EStatuses.rejected;
        state.error = action.payload;
      });
    builder
      .addCase(updateWalletAction.fulfilled, (state, action) => {
        state.updateWalletAction.status = EStatuses.idle;

        if (action.payload !== undefined) {
          const newWallet = action.payload;
          const cryptoPairId = action.payload.cryptoPairId;
          const coinId = action.payload.id;

          const coinIndex = state.getWallet.data.findIndex(
            (coin) => coin.cryptoPairId === cryptoPairId
          );

          const walletIndex = state.getWallet.data[
            coinIndex
          ].adminWallets.findIndex((wallet) => wallet.id === coinId);

          state.getWallet.data[coinIndex].adminWallets[walletIndex] = {
            ...state.getWallet.data[coinIndex].adminWallets[walletIndex],
            address: newWallet.address,
            maxLimit: newWallet.maxLimit,
          };
        }
      })
      .addCase(updateWalletAction.pending, (state) => {
        state.updateWalletAction.status = EStatuses.pending;
      })
      .addCase(updateWalletAction.rejected, (state, action) => {
        state.updateWalletAction.status = EStatuses.rejected;
      });

    builder
      .addCase(
        deleteWalletAction.fulfilled,
        (state, { payload: { walletId, cryptoPairId: prevCryptoPairId } }) => {
          const networkIndex = state.getWallet.data.findIndex(
            ({ cryptoPairId }) => cryptoPairId === prevCryptoPairId
          );

          const walletIndex = state.getWallet.data[
            networkIndex
          ].adminWallets.findIndex(({ id }) => id === walletId);

          state.getWallet.data[networkIndex].adminWallets.splice(
            walletIndex,
            1
          );
          state.getWallet.status = EStatuses.idle;
        }
      )
      .addCase(deleteWalletAction.pending, (state) => {
        state.getWallet.status = EStatuses.pending;
      })
      .addCase(deleteWalletAction.rejected, (state) => {
        state.getWallet.status = EStatuses.rejected;
      });

    builder
      .addCase(
        patchCoinStatusAction.fulfilled,
        (
          state,
          {
            payload: {
              cryptoPairId: prevCryptoPairId,
              isDepositAvailable: newIsDepositAvailable,
            },
          }
        ) => {
          const networkIndex = state.getWallet.data.findIndex(
            ({ cryptoPairId }) => cryptoPairId === prevCryptoPairId
          );

          state.getWallet.data[networkIndex].isDepositAvailable =
            newIsDepositAvailable;

          state.getWallet.status = EStatuses.idle;
        }
      )
      .addCase(patchCoinStatusAction.pending, (state) => {
        state.getWallet.status = EStatuses.pending;
      })
      .addCase(patchCoinStatusAction.rejected, (state) => {
        state.getWallet.status = EStatuses.rejected;
      });
  },
});
export default reducer;
