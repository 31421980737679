import { IStatus } from "../../../../shared/interfaces/IStatus";
import { ITransactionExcelFile } from "../../../../shared/interfaces/transaction/ITransactionExcelFile";
import { IValidationErrors } from "../../../../shared/interfaces/error";
import {IFetchTransaction} from "../../../../shared/interfaces/transaction/IFetchTransaction";

export const initialData : {
    error: IValidationErrors;
    fetchTransaction : {
        data : IFetchTransaction
        status : IStatus
    };
    transactionFile : {
        data : ITransactionExcelFile
        status : IStatus
    }
} = {
    transactionFile :{
        data : {
            filepath : ""
        },
        status : "idle"
    },
    fetchTransaction : {
        data : {
            transactions : [],
            totalItems : 0
        },
        status : "idle"

    },
    error: {},
}
