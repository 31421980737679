import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { signoutActionAPI } from "../../../APIs/auth.api";
import { IValidationErrors } from "../../../shared/interfaces";

export const signoutAction = createAsyncThunk<
  unknown | undefined,
  void,
  {
    rejectValue: IValidationErrors;
  }
>("user/signout", async (_, { rejectWithValue }) => {
  try {
    // await axios
    //   .get(`${process.env.REACT_APP_API_ROOT}/admin/auth/sign-out`)
    return signoutActionAPI()
      .then((res) => res)
      .catch((error) => {
        return rejectWithValue({
          message: error.message,
          code: error.response.status,
          name: "user/signout",
        });
      });
  } catch (error: any) {
    let message = "";
    console.log(error);
    return rejectWithValue({
      message: message,
      code: error.response.status,
      name: "user/signout",
    });
  }
});
