import { createAsyncThunk } from "@reduxjs/toolkit";
import { IValidationErrors } from "../../../../shared/interfaces/error";
import {patchProfileAPI} from "../../../../APIs/admin.api"
import {IPatchProfileRes} from "../../../../shared/interfaces/admin-management/IPatchProfileRes"


export const patchProfileAction = createAsyncThunk<
IPatchProfileRes,
{nickname : string},
{
    rejectValue: IValidationErrors;
}
>("admin/PatchProfile" , async (data , thunkAPI) =>{
    try{
        return await patchProfileAPI(data)
        .then((response) =>{
            return response.data.data
        })
        .catch((error) =>{
            return thunkAPI.rejectWithValue({
                message: error.response.message,
                name: "admin/PatchProfile",
                code: error.response.status,
              });
        })

    } catch (error: any) {
        return thunkAPI.rejectWithValue({
          message: error.response.message,
          name: "admin/PatchProfile",
          code: error.response.status,
        });
      }
})