import axios from "axios";
import { TokenClient } from "../shared/service/addTokenToReq";
import { requestInterceptor } from "../shared/service/useRequestInterceptor";

const dashboardAxios = axios.create({
  baseURL: `${process.env.REACT_APP_API_ROOT}/admin/dashboard`,
});
requestInterceptor(dashboardAxios);
TokenClient(dashboardAxios);

export const activityAPI = (params: any) =>
  dashboardAxios.get(`/activity`, params);


export const dashboardAPI = () => dashboardAxios.get(`/dashboard`);
