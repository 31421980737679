import { lazy, Suspense, useEffect } from "react";
import {
  Switch,
  Redirect,
  RouteComponentProps,
  Route,
  useHistory,
} from "react-router-dom";
import { GARoute } from "./GARoute";
import Main from "../layout/Main";
import SettingRoutes from "./SettingRoutes";
import UserManagementRoutes from "./UserManagementRoutes";
import { NoActivityLimiter } from "../shared/service/NoActivityLimiter";
import { Loading } from "../shared/components/utils/Loading";
import { useTwoFaActivated } from "../shared/service/useTwoFaActivated";
import { GAVerified } from "../shared/service/utils";
import { PermissionRouteGuard } from "./PermissionRouteGuard";
import { ENewPermissions, EPermissions } from "../shared/enums/EPermissions";

const Home = lazy(() => import("../features/dashboard/Home"));
const Transactions = lazy(
  () => import("../features/transactions/pages/Transactions")
);
const Withdrawals = lazy(
  () => import("../features/withdrawal/pages/Withdrawals")
);
const AdminManagementRoutes = lazy(() => import("./AdminManagementRoutes"));
const Maintenance = lazy(
  () => import("../features/maintenance/pages/Maintenance")
);

const AdminRoutes = (props: RouteComponentProps) => {
  const { url } = props.match;
  let history = useHistory();
  const { pathname } = history.location;

  const twoFaActivatedAt = useTwoFaActivated();

  useEffect(() => {
    if (!twoFaActivatedAt) {
      history.push(`${url}/setting/main-setting`);
    }
  }, [pathname]);
  if (twoFaActivatedAt && GAVerified())
    return (
      <Switch>
        <NoActivityLimiter>
          <Main>
            <Suspense fallback={<Loading />}>
            <PermissionRouteGuard section={ENewPermissions.dashboard}>
              <GARoute path={`${url}/dashboard`} component={Home} />
              </PermissionRouteGuard>
            </Suspense>

            <Suspense fallback={<Loading />}>
              <PermissionRouteGuard section={ENewPermissions.transaction}>
                <GARoute
                  path={`${url}/transaction`}
                  component={Transactions}
                />
              </PermissionRouteGuard>
            </Suspense>

            <Suspense fallback={<Loading />}>
              <PermissionRouteGuard section={ENewPermissions.withdrawals}>
                <GARoute
                  exact={true}
                  path={`${url}/Withdrawals`}
                  component={Withdrawals}
                />
              </PermissionRouteGuard>
            </Suspense>

            <Suspense fallback={<Loading />}>
              <GARoute
                path={`${url}/admin-management`}
                component={AdminManagementRoutes}
              />
            </Suspense>

            <Suspense fallback={<Loading />}>
              <GARoute path={`${url}/setting`} component={SettingRoutes} />
            </Suspense>

            <Suspense fallback={<Loading />}>
              <PermissionRouteGuard section={ENewPermissions["user-management"]}>
                <GARoute
                  path={`${url}/user-management`}
                  component={UserManagementRoutes}
                />
              </PermissionRouteGuard>
            </Suspense>

            <Suspense fallback={<Loading />}>
              <PermissionRouteGuard section={ENewPermissions.maintenance}>
                <GARoute
                  // exact={true}
                  path={`${url}/maintenance`}
                  component={Maintenance}
                />
              </PermissionRouteGuard>
            </Suspense>

            {/* <Redirect to={`${url}/dashboard`} /> */}
            <Redirect from={`${url}/*`} to={`${url}/admin-management/profile`} />
          </Main>
        </NoActivityLimiter>


        
      </Switch>
    );

  if (twoFaActivatedAt && !GAVerified()) return <Redirect to="/auth" />;

  return (
    <Switch>
      <NoActivityLimiter>
        <Main>
          <Route path={`${url}/setting`} component={SettingRoutes} />
          <Redirect from="*" to={`${url}/setting`} />
        </Main>
      </NoActivityLimiter>
    </Switch>
  );
};

export default AdminRoutes;
