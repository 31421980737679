import { createAsyncThunk } from "@reduxjs/toolkit";
import { IValidationErrors } from "../../../../shared/interfaces/error";
import {profileLoggerAPI} from "../../../../APIs/admin.api"
import { IProfileLogger } from "../../../../shared/interfaces/admin-management/IProfileLogger";

export const profileLoggerAction = createAsyncThunk<
IProfileLogger,
any,
{
    rejectValue: IValidationErrors;
}
>('admin/profileLog' , async (params , thunkAPI)=>{
    try{
        return await profileLoggerAPI({params : params})
        .then((response)=>{
            return response.data.data
        })
        .catch((error)=>{
            return thunkAPI.rejectWithValue({
                message : error.response.message,
                name : 'admin/profileLog',
                code : error.response.status
            })
        })

    } catch(error : any){
        return thunkAPI.rejectWithValue({
            message : error.response.message,
            name : 'admin/profileLog',
            code : error.response.status
        })
    }
})