import { Tabs } from "antd";
import React from "react";
import WithdrawStaticfee from "./WithdrawStaticfee";
import WithdrawCrypto from "./WithdrawCrypto";
const onChange = (key: string) => {
  console.log(key);
};

export const WithdrawSettingTabs: React.FC = () => {
  return (
    <Tabs
      className="settingWithdraw_tabs"
      defaultActiveKey="1"
      onChange={onChange}
      items={[
        {
          label: `Static Fees`,
          key: "1",
          children: <WithdrawStaticfee />,
        },
        {
          label: `Crypto`,
          key: "2",
          children: <WithdrawCrypto />,
        },
      ]}
    />
  );
};
