import { createAsyncThunk } from "@reduxjs/toolkit";
import { IValidationErrors } from "../../../../shared/interfaces/error";
import {
  patchPermissionsAPI,
  patchProfileAPI,
} from "../../../../APIs/admin.api";
import {
  IPatchPermissions,
  IPatchPermissionsBody,
} from "../../../../shared/interfaces";

export const patchPermissionsAction = createAsyncThunk<
  IPatchPermissions,
  IPatchPermissionsBody,
  {
    rejectValue: IValidationErrors;
  }
>("admin/Patch-Permissions", async (data, thunkAPI) => {
  try {
    return await patchPermissionsAPI(data)
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue({
          message: error.response.message,
          name: "admin/Patch-Permissions",
          code: error.response.status,
        });
      });
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      message: error.response.message,
      name: "admin/Patch-Permissions",
      code: error.response.status,
    });
  }
});
