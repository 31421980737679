// @ts-nocheck
import { useEffect } from "react";
import { logout } from "../../shared/service/utils";
import { Row, Col, Breadcrumb } from "antd";
import { useLocation } from "react-router-dom";
import { signOutIcon } from "../../assets/icons";
import { useAppDispatch } from "../../store";
import { signoutAction } from "../../features/authentication/actions/signoutAction";
function Header({ name, href }: any) {
  const dispatch = useAppDispatch();
  useEffect(() => window.scrollTo(0, 0));
  let { pathname } = useLocation();
  // console.log(pathname);
  return (
    <>
      <Row gutter={[24, 0]}>
        <Col span={24} md={8}>
          <Breadcrumb>
            <Breadcrumb.Item>Panel</Breadcrumb.Item>
            <Breadcrumb.Item
              style={{ textTransform: "capitalize" }}
             
            >
              {name.substring(6).replaceAll("/", " / ")}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col span={16} md={16} className="header-control">
          <span
            onClick={() => dispatch(signoutAction())}
            className="btn-sign-in logout"
          >
            <span>Sign Out</span>
            {signOutIcon}
          </span>
        </Col>
      </Row>
    </>
  );
}

export default Header;
