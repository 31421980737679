import { IStatus } from "../../../../shared/interfaces/IStatus";
import { IGetPermissions } from "../../../../shared/interfaces/admin-management/IGetPermissions";
import { IValidationErrors } from "../../../../shared/interfaces/error";
import { IPatchProfileRes } from "../../../../shared/interfaces/admin-management/IPatchProfileRes";
import { IGetAdminListRes } from "../../../../shared/interfaces/admin-management/IGetAdminListRes";
import { IAuthLogger } from "../../../../shared/interfaces/admin-management/IAuthLogger";
import { IMaintenanceLog } from "../../../../shared/interfaces/admin-management/IMaintenanceLog";
import { IDepositLogger } from "../../../../shared/interfaces/admin-management/IDepositLogger";
import { IWithdrawalLog } from "../../../../shared/interfaces/admin-management/IWithdrawalLog";
import {IWithdrawLogs} from "../../../../shared/interfaces/admin-management/IWithdrawLogs";
import { IProfileLogger } from "../../../../shared/interfaces/admin-management/IProfileLogger";

export const initialData: {
  error: IValidationErrors | undefined;
  getPermissoins: {
    data: IGetPermissions[];
    status: IStatus;
  };
  patchProfileRes: {
    data: IPatchProfileRes;
    status: IStatus;
  };
  getAdminList: {
    data: IGetAdminListRes;
    status: IStatus;
  };
  addNewAdmin: {
    status: IStatus;
  };
  authLogger: {
    data: IAuthLogger;
    status: IStatus;
  };
  maintenanceLogger: {
    data: IMaintenanceLog;
    status: IStatus;
  };
  depositLogger: {
    data: IDepositLogger;
    status: IStatus;
  };
  withdrawalLogger: {
    data: IWithdrawalLog;
    status: IStatus;
  };
  withdrawSettinglog :{
    data : IWithdrawLogs
    status : IStatus
  }
  profileLogger :{
    data : IProfileLogger
    status : IStatus
  }
} = {
  error: {},
  getPermissoins: {
    data: [],
    status: "idle",
  },
  patchProfileRes: {
    data: {
      email: "",
      nickname: "",
      twoFaActivationAt: "",
    },
    status: "idle",
  },
  getAdminList: {
    data: {
      admins: [],
      totalItems: 0,
    },
    status: "idle",
  },
  addNewAdmin: {
    status: "idle",
  },
  authLogger: {
    data: {
      auth: [],
      totalItems: 0,
    },
    status: "idle",
  },
  maintenanceLogger: {
    data: {
      maintenanceLogs: [],
      totalItems: 0,
    },
    status: "idle",
  },
  depositLogger: {
    data: {
      depositLogs: [],
      totalItems: 0,
    },
    status: "idle",
  },
  withdrawalLogger: {
    data: {
      withdraws: [],
      totalItems: 0,
    },
    status: "idle",
  },
  withdrawSettinglog : {
    data : {
      withdrawLogs : [] ,
      totalItems : 0
    } ,
    status : "idle"
  } ,
  profileLogger : {
    data :{
      profileLogs :[],
      totalItems : 0
    } , 
    status : "idle"
  }

};
