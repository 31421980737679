import { createAsyncThunk } from "@reduxjs/toolkit";
import { IRequestOtp } from "../../../shared/interfaces/Auth/IAdminData";
import { requestOtpActionAPI } from "../../../APIs/auth.api";
import { IValidationErrors } from '../../../shared/interfaces/error'


export const requestOtpAction = createAsyncThunk<
 | { message: IRequestOtp , statusCode : IRequestOtp , error :  IRequestOtp } | any,
  string,
  {
    rejectValue: IValidationErrors;
  }
>("admin/requestOtp", async (email, thunkAPI) => {
  try {

    const response = await requestOtpActionAPI({
      params : {
        email : email
    }
    })
    
      return{
        message : response.data["message"] ,
        statusCode : response.data["statusCode"] ,
        error : response.data["error"]
      }
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      message: error.response.data.error,
      name: "admin/requestOtp",
      code: error.response.status,
    });
  }
});
