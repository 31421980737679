import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookie from "js-cookie";

const downloadsAction = createAsyncThunk(
  "downloads",
  async (downloadType: { downloadType: string }, thunkAPI) => {
    try {
      let token = Cookie.get("token");
      if (token?.length) {
        const response = await axios.post(
          `${process.env.REACT_APP_API_ROOT}/downloadEndpoint`,
          {
            ...downloadType,
            token: token,
          }
        );

        return response;
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        message: error.response.data.message,
        code: error.response.status,
        name: "downloads",
      });
    }
  }
);
export default downloadsAction;
