import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Card, Typography, Steps, Form } from "antd";
import LogInForm from "./LogInForm";
import VerifyForm from "./VerifyForm";
import OtpInput from "react-otp-input";
import { NavLink, useHistory } from "react-router-dom";
import logo from "../../../assets/images/logo.png";
import { CostumButton } from "../../../shared/components/utils/Button";
import { useAppDispatch, useAppSelector } from "../../../store";
import { verifyOtpAction } from "../../authentication/actions/verify-otp-Action";
import { requestOtpAction } from "../../authentication/actions/request-otp-Action";
import { verify2FAAction } from "../actions/verify-2FA-Action";
import GAForm from "./GAForm";
import { reviseEmail } from "../actions/reviseEmailAction";
import CountDown from "./CountDown";
import { GAVerified, hasToken, redirectTo } from "../../../shared/service/utils";
import reducer, { setCurrent }   from '../reducers/authentication-reducer' 
import {
  ajaxErrorAlert,
  ajaxSuccessAlert,
} from "../../../shared/components/utils/alert";
import { current as  currentEnum } from "../../../shared/interfaces/Auth/IAdminData";
import { assign } from "lodash";
import Cookies from "js-cookie";
import { useTwoFaActivated } from "../../../shared/service/useTwoFaActivated";
const { Step } = Steps;
const steps = [
  {
    title: "First",
    content: "First-content",
  },
  {
    title: "Second",
    content: "Second-content",
  },
];
const LogInCard = () => {
  const [render, setRender] = useState(false);
  const [emailInput, setEmailInput] = useState("");
  const [otp, setOtp] = useState(" ");
  const [GAopt, setGAotp] = useState({ code: "" });
  let { current } = useAppSelector((state) => state.admin);
  const [captchaValue, setCaptchaValue] = useState("");
  const { Title } = Typography;
  const { requestOtpData, verify2FA } = useAppSelector((state) => state.admin);
  const { twoFaActivationAt } = useAppSelector((state) => state.admin.verifyOtpData);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const changeHandlerOtp = (value: any) => {
    setOtp(value);
  };
  let history = useHistory();

  const twoFaActivatedAt= useTwoFaActivated()



useEffect(()=>{

    if (hasToken() &&  twoFaActivatedAt  && !GAVerified()){
     
    dispatch(setCurrent(currentEnum.thirdStep))} 
    // else if(hasToken() && !twoFaActivationAt ){
       
    //     history.push("/admin/setting/main-setting");
    // }
    // else if(hasToken() && !twoFaActivationAt){

    // }

},[twoFaActivationAt,Cookies])

  const GAChangeHandler = (value: any) => {
    setGAotp({ code: value });
  };

  const GAClickHandler = async () => {
    await dispatch(verify2FAAction(GAopt)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        redirectTo("/admin/admin-management/profile");
      }
    });
  };

  const validateEmail = (email: string) => {
    const testEmail = /\S+@\S+\.\S+/;
    return testEmail.test(email);
  };

  const changeHandler = (event: any) => {
    setEmailInput(event.target.value);
  };

  function onChangeCaptcha(value: any) {
    setCaptchaValue(value);
  }

  const signinClickHandler = async () => {
    await dispatch(requestOtpAction(emailInput)).then((res)=>{
      if(res.meta.requestStatus === "fulfilled"){
        ajaxSuccessAlert(res.payload.message)
      }
    })
  };
  const prev = () => {
    setEmailInput("");
    setOtp("");
    dispatch(reviseEmail("REVISE-EMAIL"));
  };

  let dataVerifyOtp = {
    email: emailInput,
    code: otp,
  };

  const clickHandler = async () => {
    await dispatch(verifyOtpAction(dataVerifyOtp));
  };

  const resendOtp = async () => {
    setRender(true);
    await dispatch(requestOtpAction(emailInput))
    .then((res) =>{
      if(res.meta.requestStatus === "fulfilled"){
        ajaxSuccessAlert(res.payload.message)
      }
    })
  };

  const Completionist = () => (
    <CostumButton className="resendButton_login button" onClick={resendOtp}>
      Resend it
    </CostumButton>
  );
  useEffect(() => {
    setRender(false);
  }, [render]);
  const countDown = useMemo(() => {
    return <CountDown complete={Completionist} render={render} />;
  }, [emailInput, render]);
  return (
    <>
      <div className="login_wrapper">
        <Card className="login_card">
          <div className="login_header">
            <img src={logo} alt="posterity" className="login_logo" />
            <h1>Posterity Admin</h1>
            {current === 2 ? (
              <Title level={1}>Google Authenticator Code</Title>
            ) : (
              <Title level={1}>Sign In</Title>
            )}
          </div>
          <Steps className="ant_step" current={current}></Steps>
          <div>
            <div className="button_wrapper">
              <div>
                {current === 0 && (
                  <LogInForm
                    value={emailInput}
                    onChange={changeHandler}
                    onChangeCaptcha={onChangeCaptcha}
                    onKeyPress={(e: any) => {
                      if (e.key === "Enter" && captchaValue && emailInput) {
                        form.submit();
                        signinClickHandler();
                      }
                    }}
                    valueCaptcha={captchaValue}
                    rules={[{ type: "email" }]}
                  />
                )}

                <Form form={form}>
                  {current === 0 &&
                  validateEmail(emailInput) &&
                  captchaValue ? (
                    <CostumButton
                      type="primary"
                      htmlType="submit"
                      key="submit"
                      onClick={signinClickHandler}
                      className="login_button button"
                      loading={
                        requestOtpData.status === "pending" ? true : false
                      }
                    >
                      Sign In
                    </CostumButton>
                  ) : (
                    current === 0 && (
                      <CostumButton
                        disabled={true}
                        type="primary"
                        className="login_button button"
                      >
                        Sign In
                      </CostumButton>
                    )
                  )}
                </Form>
              </div>

              {current === 1 && (
                <>
                  <VerifyForm
                    onChange={changeHandlerOtp}
                    value={otp}
                    onKeyPress={(e: any) => {
                      if (e.key === "Enter" && otp) {
                        form.submit();
                        clickHandler();
                      }
                    }}
                  />
                  {countDown}
                </>
              )}

              {current === 1 && (
                <div className="verify_button">
                  {otp === " " ? (
                    <CostumButton
                      disabled={true}
                      type="primary"
                      className="button"
                    >
                      Verify
                    </CostumButton>
                  ) : (
                    <CostumButton
                      type="primary"
                      className="button"
                      onClick={clickHandler}
                    >
                      Verify
                    </CostumButton>
                  )}

                  <CostumButton onClick={() => prev()} className="button">
                    Revise Email
                  </CostumButton>
                </div>
              )}
            </div>
            {current === 2 &&  (
              <div>
                <GAForm
                  value={GAopt.code}
                  onChange={GAChangeHandler}
                  onKeyPress={(e: any) => {
                    if (e.key === "Enter" && GAopt.code) {
                      form.submit();
                      GAClickHandler();
                    }
                  }}
                />
                <div className="verify_button">
                  {GAopt.code ? (
                    <CostumButton
                      type="primary"
                      className="button"
                      onClick={GAClickHandler}
                      loading={verify2FA.status === "pending" ? true : false}
                    >
                      Verify
                    </CostumButton>
                  ) : (
                    <CostumButton
                      type="primary"
                      className="button"
                      onClick={GAClickHandler}
                      disabled={true}
                    >
                      Verify
                    </CostumButton>
                  )}
                </div>
              </div>
            )}
          </div>
        </Card>
      </div>
    </>
  );
};

export default LogInCard;
