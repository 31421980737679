import { createAsyncThunk } from "@reduxjs/toolkit";
import { postWithdrawalAPI } from "../../../../APIs/withdrawal.api";
import { IValidationErrors } from "../../../../shared/interfaces/error";
import {
  IPostWithdrawal,
  IPostWithdrawalRes,
} from "../../../../shared/interfaces/withdrawal/IPostWithdrawalType";
export const postWithdrawalAction = createAsyncThunk<
  IPostWithdrawalRes,
  IPostWithdrawal,
  {
    rejectValue: IValidationErrors;
  }
>("withdrawal/postWithdrawal", async (withdrawalData, thunkAPI) => {
  try {
    return await postWithdrawalAPI({ ...withdrawalData })
      .then((res) => {
        return res.data.data.id;
      })
      // .catch((error: any) => {
      //   return error;
      // });
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      message: error.response.message,
      name: "withdrawal/postWithdrawal",
      code: error.response.status,
    });
  }
});
