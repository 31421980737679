import axios from "axios";
import {
  IPatchCoinStatusPayload,
  IUpdateWalletAPIARG,
} from "../shared/interfaces";

import { TokenClient } from "../shared/service/addTokenToReq";
import { requestInterceptor } from "../shared/service/useRequestInterceptor";
const settingAxios = axios.create({
  baseURL: `${process.env.REACT_APP_API_ROOT}/admin`,
});

TokenClient(settingAxios);
requestInterceptor(settingAxios);

//main setting APi
export const get2FAActionAPI = () => settingAxios.get(`/auth/2fa`);

export const activate2FAAPI = (payload: any) =>
  settingAxios.post(`/user/2fa`, { ...payload });

//Deposit Setting APi

export const getWalletAPI = () => settingAxios.get(`/wallet/wallet`);

export const createWalletAPI = (payload: any) =>
  settingAxios.post(`/wallet/wallet`, { ...payload });

export const updateWalletAPI = ({
  coinId,
  maxLimit,
  address,
  cryptoPairId ,
}: IUpdateWalletAPIARG) =>
  settingAxios({
    method: "patch",
    url: `/wallet/wallet/${coinId}`,
    data: {
      maxLimit,
      address,
      cryptoPairId ,
    },
  });
export const deleteWalletAPI = (walletID: number) =>
  settingAxios({
    method: "DELETE",
    url: `/wallet/wallet/${walletID}`,
  });
export const patchCoinStatusAPI = (payload: IPatchCoinStatusPayload) =>
  settingAxios({
    method: "PATCH",
    url: "/wallet/deposit",
    data: payload,
  });

export const getStaticFeeAPI = () => settingAxios.get(`/setting/static-fee`);

export const patchStaticFeeAPI = (payload: any) =>
  settingAxios.patch(`/setting/static-fee`, payload);

export const fetchCryptoAPI = () => settingAxios.get(`/setting/crypto`);

export const patchCryptoAPI = (payload: any) =>
  settingAxios.patch(`/setting/crypto`, { ...payload });

export default settingAxios;
