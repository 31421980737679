import axios from "axios";
import { TokenClient } from "../shared/service/addTokenToReq";
import { requestInterceptor } from "../shared/service/useRequestInterceptor";

const authAxios = axios.create({
  baseURL: `${process.env.REACT_APP_API_ROOT}/admin/auth`,
});
requestInterceptor(authAxios);
TokenClient(authAxios);

export const requestOtpActionAPI = (params: any) =>
  authAxios.get(`/otp`, { ...params });

export const verifyOtpActionAPI = (payload: any) =>
  authAxios.post(`/otp`, { ...payload });

export const verify2FAActionAPI = (payload: any) =>
  authAxios.post(`/2fa`, { ...payload });

export const signoutActionAPI = () => authAxios.get(`/sign-out`);

export const getProfileActionAPI = () => authAxios.get(`/profile`);

export default authAxios;
