import React from "react";
import { Card, Typography } from "antd";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import {
  addAdminIcon,
  AdminsListIcon,
  adminusersIcon,
  DashboardIcon,
  DepositIcon,
  LogsIcon,
  maintenanceIcon,
  ProfileIcon,
  settingIcon,
  settingsIcon,
  topusersIcon,
  transactionsIcon,
  usersIcon,
  WithdrawalsIcon,
  withdrawSettingIcon,
} from "../../../assets/icons";

const Title = () => {
  const { Title } = Typography;
  const location = useLocation();

  const pageName = location.pathname.split("/").at(-1);

  const pageTitle = pageName
    ?.split("-")
    .map((word) => _.capitalize(word))
    .join(" ");

  // console.log("title page name>>>>", pageName);
  // console.log("title pageTitle>>>>", pageTitle);

  const iconPicker = () => {
    switch (_.capitalize(pageName)) {
      case "Dashboard":
        return <DashboardIcon />;
      case "Transactions":
        return transactionsIcon;
      case "Withdrawals":
        return WithdrawalsIcon;
      case "Setting":
        return settingIcon;
      case "Main-setting":
        return settingsIcon;
      case "Deposit-setting":
        return DepositIcon;
      case "Withdraw-setting":
        return withdrawSettingIcon;
      case "Profile":
        return ProfileIcon;
      case "Logs":
        return LogsIcon;
      case "Admins-list":
        return AdminsListIcon;
      case "New-admin":
        return addAdminIcon;
      case "Users":
        return usersIcon;
      case "Top-users":
        return topusersIcon;
      case "Maintenance":
        return maintenanceIcon;
    }
  };

  return (
    <div className="Title_wrapper">
      <Card className="Title_card">
        <Title level={2} className="Title_content">
          <span className="title-icon">{iconPicker()}</span>
          {pageTitle}
        </Title>
      </Card>
    </div>
  );
};

export default Title;
