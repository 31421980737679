// @ts-nocheck
import Cookie from "js-cookie";
import authAxios from "../../APIs/auth.api";
import { AxiosInstance } from "axios";




export const TokenClient = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = Cookie.get("token");
      config.headers.authorization = token ? `Bearer ${token}` : "";
      return config;
    },
    (error) => {
      console.log("req errrrrr", error);
      return Promise.reject(error);
    }
  );
};
