import React, { useState } from "react";
import { Input, Button, Form, Card, Radio } from "antd";
import type { RadioChangeEvent } from "antd";
import { CostumButton } from "../../../shared/components/utils/Button";
import { getUserAction } from "../state/actions/getUserAction";
import { useAppDispatch, useAppSelector } from "../../../store";

const FilterUsers = ({
  changeHandlerWallet,
  changeHandlerEmail,
  clickHandler,
  walletValue,
  emailValue,
  placementChange,
  placement,
}: any) => {
  const validateMessages = {
    required: "Email is required!",
    types: {
      email: "Please enter a valid email address",
    },
  };
  const validateEmail = (email: string) => {
    const testEmail = /\S+@\S+\.\S+/;
    return testEmail.test(email);
  };

  return (
    <div className="userfilter_container">
      <div className="userfilter">
        <h2>Search by</h2>
        <div className="userInput">
          <Radio.Group
            value={placement}
            onChange={placementChange}
            className="choosing_input"
          >
            <Radio.Button value="EmailAddress">Email Address</Radio.Button>
            <Radio.Button value="WalletAddress">Wallet Address</Radio.Button>
          </Radio.Group>
          {placement === "EmailAddress" && (
            <Form
              className="input_usercardWrapper"
              validateMessages={validateMessages}
              // onFinish={props.onFinish}
            >
              <Form.Item
                name={["email"]}
                rules={[{ type: "email" }]}
                className="input_usercardWrapper"
              >
                <Input
                  className="input_usercard"
                  placeholder="Search Email Address"
                  allowClear
                  onChange={changeHandlerEmail}
                  value={emailValue}
                  size="small"
                />
              </Form.Item>
            </Form>
          )}

          {placement === "WalletAddress" && (
            <Form className="input_usercardWrapper">
              <Form.Item>
                <Input
                  className="input_usercard"
                  placeholder="Search Wallet Address"
                  allowClear
                  onChange={changeHandlerWallet}
                  value={walletValue}
                  size="small"
                />
              </Form.Item>
            </Form>
          )}
        </div>
        {/* {(emailValue && validateEmail(emailValue)) || walletValue ? (
          <CostumButton
            className="usercard_search button"
            type="primary"
            onClick={clickHandler}
            
          >
            Search
          </CostumButton>
        ) : (
          <CostumButton
            className="usercard_search button"
            type="primary"
            onClick={clickHandler}
            
          >
            Search
          </CostumButton>
        )} */}

        <CostumButton
          className="usercard_search button"
          type="primary"
          onClick={clickHandler}
        >
          Search
        </CostumButton>
      </div>
    </div>
  );
};

export default FilterUsers;
