import React, { useState } from "react";
import {
  Space,
  Table,
  Tag,
  Button,
  Popover,
  Tooltip,
  RadioChangeEvent,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import FilterUsers from "./FilterUsers";
import { IUsersData, IChosenUserData } from "../../../shared/interfaces/user";
import {
  currencyFormat,
  dataChosenUsers,
  dataSort,
} from "../../../shared/service/utils";
import { ScrollableTable } from "../../../shared/components/utils/ScrollableTable";
import { PassChangesTable } from "./PassChangesTable";

import { GraphicCopyToClipboard } from "../../../shared/components/utils/GraphicCopyToClipboard";
import { ScrollableTableExcelled } from "../../../shared/components/utils/ScrollableTableExcelled";
import { useAppDispatch, useAppSelector } from "../../../store";
import { getChosenUserAction } from "../state/actions/getChosenUserAction";
import { TGetUserAPIParams } from "../../../shared/interfaces";

const ChosenUser = () => {
  const { data, status } = useAppSelector((state) => state.user.chosenUser);
  const { userInfo, investInfo, withdrawInfo, balanceInfo } = data ?? {};

  const [search, setsearch] = useState(false);
  const [searchEmail, setsearchEmail] = useState("");
  const [searchWallet, setSearchWallet] = useState("");
  const [placement, setPlacement] = useState("EmailAddress");

  const dispatch = useAppDispatch();

  const columnsInfo: ColumnsType<IChosenUserData> = [
    {
      title: "Name",
      dataIndex: "nickname",
      key: "name",
      render: (text) => <a>{text}</a>,
      align: "center",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "center",
      render: (email) => (
        <GraphicCopyToClipboard text={email}>
          <span>{email}</span>
        </GraphicCopyToClipboard>
      ),
    },
    {
      title: "Wallet Address",
      dataIndex: "spotWalletAddress",
      key: "walletAddress",
      align: "center",
    },
  ];
  function renderToCurrencyFormat(value: string | number | undefined) {
    if (value) return currencyFormat(+value);
    return value;
  }
  const columnsBalance: ColumnsType<IChosenUserData> = [
    {
      title: "Total Remaining Balance",
      key: "remainingBalance",
      dataIndex: "totalRemainingBalance",
      align: "center",
      render: renderToCurrencyFormat,
    },
    {
      title: "Current Year Remaining Balance",
      key: "currentYearBalance",
      dataIndex: "currentYearRemainingBalance",
      align: "center",
      render: renderToCurrencyFormat,
    },
    {
      title: "Current Month Remaining Balance",
      key: "monthRemainingBalance",
      dataIndex: "currentMonthRemainingBalance",
      align: "center",
      render: renderToCurrencyFormat,
    },
  ];
  const columnsWithdraw: ColumnsType<IChosenUserData> = [
    {
      title: "Total Downline Withdrawls",
      key: "withdrawls",
      dataIndex: "totalDownlinewithdrawals",
      align: "center",
      render: renderToCurrencyFormat,
    },
    {
      title: "Current Month Downline Withdrawals",
      key: "monthDownlineWithdrawals",
      dataIndex: "currentMonthDownlineWithdrawals",
      align: "center",
      render: renderToCurrencyFormat,
    },
    {
      title: "Current Year Downline Withdrawals",
      key: "downlineswithdrawal",
      dataIndex: "currentYearDownlineWithdrawals",
      align: "center",
      render: renderToCurrencyFormat,
    },
  ];
  const columnsInvesment: ColumnsType<IChosenUserData> = [
    {
      title: "Total Downline Investments",
      dataIndex: "totalDownlineInvestments",
      key: "investments",
      align: "center",
      render: renderToCurrencyFormat,
    },

    {
      title: "Current Month Downline Investments",
      key: "monthDownlineInvestments",
      dataIndex: "currentMonthDownlineInvestments",
      align: "center",
      render: renderToCurrencyFormat,
    },
    {
      title: "Current Year Downline Investments",
      key: "downlinesInvestments",
      dataIndex: "currentYearDownlineInvestments",
      align: "center",
      render: renderToCurrencyFormat,
    },
  ];
  const searchBtnClickHandler = () => {
    const params = {
      [placement === "EmailAddress" ? "email" : "walletAddress"]:
        searchEmail || searchWallet,
    } as TGetUserAPIParams;

    dispatch(getChosenUserAction(params)).then((res) => {
      return res.meta.requestStatus === "fulfilled" && setsearch(true);
    });
  };

  const changeHandlerEmail = (event: any) => {
    setSearchWallet("");
    setsearchEmail((event.target.value as string).trim());
  };

  const changeHandlerWallet = (event: any) => {
    setsearchEmail("");
    setSearchWallet((event.target.value as string).trim());
  };

  const placementChange = (e: RadioChangeEvent) => {
    setPlacement(e.target.value);
  };

  // const searchClickHandler = () => {
  //   const params = {
  //     [placement]: searchEmail || searchWallet,
  //   } as TGetUserAPIParams;

  //   dispatch(getChosenUserAction(params)).then((res) => {
  //     return res.meta.requestStatus === "fulfilled" && clickHandler();
  //   });
  // };
  return (
    <>
      <FilterUsers
        clickHandler={searchBtnClickHandler}
        emailValue={searchEmail}
        walletValue={searchWallet}
        changeHandlerEmail={changeHandlerEmail}
        changeHandlerWallet={changeHandlerWallet}
        placementChange={placementChange}
        placement={placement}
      />
      <div className="chosenUser_tables">
        {search && (
          <>
            <ScrollableTableExcelled
              columns={columnsInfo}
              dataSource={[userInfo]}
              pagination={false}
              cardProps={{ className: "table_chosenUser" }}
              showTitle="User Info"
            />
            <ScrollableTableExcelled
              columns={columnsBalance}
              dataSource={[balanceInfo]}
              pagination={false}
              cardProps={{ className: "table_chosenUser" }}
              showTitle="Balance Info"
            />
            <ScrollableTableExcelled
              columns={columnsWithdraw}
              dataSource={[withdrawInfo]}
              pagination={false}
              cardProps={{ className: "table_chosenUser" }}
              showTitle="Withdraw Info"
            />
            <ScrollableTableExcelled
              columns={columnsInvesment}
              dataSource={[investInfo]}
              pagination={false}
              cardProps={{ className: "table_chosenUser" }}
              showTitle="Investment Info"
            />
          </>
        )}
      </div>
    </>
  );
};

export default ChosenUser;

///ScrollableTableExcelled
