import React from 'react';

function RestrictAccess() {
  return (
    <div className='restrict-wrapper'>
      <h2>This Web Site Is Available Only On Desktop</h2>
    </div>
  )
}

export default RestrictAccess;
