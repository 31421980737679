import { createSlice, current } from "@reduxjs/toolkit";
import { initialData } from "./initialState";
import { getWithdrawalAction } from "../actions/getWithdrawalsAction";
import { postWithdrawalAction } from "../actions/postWithdrawalAction";
import { getWithdrawByIdAction } from "../actions/getWithdrawByIdAction";
import { getPaidAction } from "../actions/getPaidAction";
import { getWithdrawalExcelAction } from "../actions/getWithdrawalExcelAction";
import { paymentAction } from "../actions/paymentAction";
import { getCheckoutAction } from "../actions/getCheckoutAction";
export const { actions, reducer } = createSlice({
  name: "withdrawal",
  initialState: initialData,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWithdrawalAction.fulfilled, (state, action) => {
        state.responseWithdrawal.status = "idle";
        state.responseWithdrawal.data = action.payload;
      })
      .addCase(getWithdrawalAction.pending, (state, action) => {
        state.responseWithdrawal.status = "pending";
      })
      .addCase(getWithdrawalAction.rejected, (state, action) => {
        state.responseWithdrawal.status = "rejected";
        state.error = action.payload;
      });

    builder
      .addCase(postWithdrawalAction.fulfilled, (state, action) => {
        state.postWithdrwal.status = "idle";
        state.postWithdrawalRes = action.payload;
        if (state.responseWithdrawal.data?.withdrawalRequests) {
          state.responseWithdrawal.data.withdrawalRequests =
            state.responseWithdrawal.data?.withdrawalRequests.filter(
              (e: any) => e.id !== state.postWithdrawalRes
            );
        }
      })
      .addCase(postWithdrawalAction.pending, (state) => {
        state.postWithdrwal.status = "pending";
      })
      .addCase(postWithdrawalAction.rejected, (state, action) => {
        state.postWithdrwal.status = "rejected";
        state.error = action.payload;
      });
    builder
      .addCase(getWithdrawByIdAction.fulfilled, (state, action) => {
        state.withdrawalDataByID.status = "idle";
        state.withdrawalDataByID.data = action.payload;
      })
      .addCase(getWithdrawByIdAction.pending, (state) => {
        state.withdrawalDataByID.status = "pending";
      })
      .addCase(getWithdrawByIdAction.rejected, (state, action) => {
        state.withdrawalDataByID.status = "rejected";
        state.error = action.payload;
      });
    builder
      .addCase(getPaidAction.fulfilled, (state, action) => {
        state.paid.data = action.payload;
        state.paid.status = "idle";
      })
      .addCase(getPaidAction.pending, (state) => {
        state.paid.status = "pending";
      })
      .addCase(getPaidAction.rejected, (state, action) => {
        state.paid.status = "rejected";
        state.error = action.payload;
      });
    // excel file
    builder
      .addCase(getWithdrawalExcelAction.fulfilled, (state, action) => {
        state.witdrawalExcel.status = "idle";
        state.witdrawalExcel.data = action.payload;
      })
      .addCase(getWithdrawalExcelAction.pending, (state) => {
        state.witdrawalExcel.status = "pending";
      })
      .addCase(getWithdrawalExcelAction.rejected, (state, action) => {
        state.witdrawalExcel.status = "rejected";
        state.error = action.payload;
      });


     // payment 
     builder.addCase(paymentAction.fulfilled , (state)=>{
      state.payment.status = "idle"
     })
     .addCase(paymentAction.pending , (state) =>{
      state.payment.status = "pending"
     })
     .addCase(paymentAction.rejected , (state , action)=>{
      state.payment.status = "rejected"
      state.error = action.payload
     })

     // checkout
     builder.addCase(getCheckoutAction.fulfilled , (state , action)=>{
      state.checkout.status = "idle"
      state.checkout.data = action.payload 
     })
     .addCase(getCheckoutAction.pending , (state)=>{
      state.checkout.status = "pending"
     })
     .addCase(getCheckoutAction.rejected , (state , action)=>{
      state.checkout.status = "rejected"
      state.error = action.payload
     })
  },
});

export default reducer;
