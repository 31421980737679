import React from "react";
import { RouteProps, Redirect, Route } from "react-router-dom";
import Cookie from "js-cookie";

interface PrivateRouterProps extends RouteProps {}

export const PrivateRoute = (props: PrivateRouterProps) => {
  return Cookie.get("token")?.length  ? (
    <Route {...props} component={props.component} />
  ) : (
    <Redirect to="/auth" />
  );
};
