import { createAsyncThunk } from "@reduxjs/toolkit";
import { IValidationErrors } from "../../../../shared/interfaces/error";
import {getDepositLoggerAPI} from "../../../../APIs/admin.api";
import { IDepositLogger } from "../../../../shared/interfaces/admin-management/IDepositLogger";

export const getDepositLoggerAction = createAsyncThunk<
IDepositLogger, 
any,
{
    rejectValue : IValidationErrors
}
>("admin/depositLog" , async(params , thunkAPI) =>{
    try{
        return await getDepositLoggerAPI({params : params})
        .then((response)=>{
            return response.data.data
        })
        .catch((error)=>{
            return thunkAPI.rejectWithValue({
                message: error.response.message,
                name : "admin/depositLog",
                code : error.response.status
            })
        })
    } catch(error : any){
        return thunkAPI.rejectWithValue({
            message: error.response.message,
            name : "admin/depositLog",
            code : error.response.status
        })
    }
})