import { createAsyncThunk } from "@reduxjs/toolkit";
import { patchCryptoAPI } from "../../../../APIs/setting.api";
import { IValidationErrors } from "../../../../shared/interfaces/error";
import {
  IPatchCrypto,
  IpatchCryptoRes,
} from "../../../../shared/interfaces/setting/index";
export const patchCryptoAction = createAsyncThunk<
  IpatchCryptoRes,
  IPatchCrypto,
  {
    rejectValue: IValidationErrors;
  }
>("patch/crypto", async (cryptoData, thunkAPI) => {
  try {
    return await patchCryptoAPI(cryptoData)
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue({
          message: error.response.message,
          name: "patch/crypto",
          code: error.response.status,
        });
      });
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      message: error.response.message,
      name: "patch/crypto",
      code: error.response.status,
    });
  }
});
