import React from 'react'
import {PassChangesTable}  from "./PassChangesTable"
import ChangeEmailTable from './ChangeEmailTable'
function LogsTable({id} : any) {
  return (
    <>
      <PassChangesTable/>
      <ChangeEmailTable id={id}/>
    </>
  )
}

export default LogsTable
