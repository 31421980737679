import React, { useState } from "react";
import { Button, Form, Input, Modal } from "antd";
import { useAppDispatch, useAppSelector } from "../../../store";
import { patchNewEmailAction } from "../../../features/user_management/state/actions/patchNewEmailAction";
import { ajaxSuccessAlert } from "./alert";




const ChangeEmailModal = ({id} : any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [value, setValue] = useState("");
  const dispatch = useAppDispatch();

  const showModal = () => {
    setIsModalOpen(true);
    setValue("")
  };

  

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const emailChangeHandler = (event: any) => {
    setValue(event.target.value);
  };

  const handleOk = async () => {
    setIsModalOpen(false);
    await dispatch(patchNewEmailAction({id , newEmail : value})).then((res : any)=>{
      if (res.meta.requestStatus === "fulfilled") {
        ajaxSuccessAlert("Email successfully changed");
      }
    })

  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Change Email
      </Button>
      <Modal
        title="Change Email"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Confirm"
        okButtonProps={value ? { disabled: false } : { disabled: true }}
      >
        <Form className="payModal_Form">
          <Input
            className="payModal_input"
            placeholder="Enter Email Address"
            value={value}
            onChange={emailChangeHandler}
          />
        </Form>
      </Modal>
    </>
  );
};

export default ChangeEmailModal;
