import { createAsyncThunk } from "@reduxjs/toolkit";
import { deleteWalletAPI } from "../../../../APIs/setting.api";
import { IDeleteWallet } from "../../../../shared/interfaces";

export const deleteWalletAction = createAsyncThunk<
  IDeleteWallet,
  IDeleteWallet,
  any
>("admin/deleteWallet", async ({ walletId, cryptoPairId }, thunkAPI) => {
  try {
    return await deleteWalletAPI(walletId)
      .then(() => {
        return { walletId, cryptoPairId };
      })
      .catch((err) => {
        return thunkAPI.rejectWithValue(err);
      });
  } catch (error: any) {
    return error;
  }
});
