import { useHistory } from "react-router-dom";
import { EPermissionsKeys } from "../interfaces";
import { alwaysOpenPages } from "./utils";

export const useIfOpenPage = () => {
  const {
    location: { pathname },
  } = useHistory();
  const checkIfOpenPage = (alwaysOpenPages: string[], pathname: string) =>
    alwaysOpenPages.some((openPage) =>
      pathname.toLowerCase().includes(openPage.toLowerCase())
    );
  const isOpenPage = checkIfOpenPage(alwaysOpenPages, pathname);

  return isOpenPage;
};
