export enum EPermissions {
  "setting"= 1,
  "maintenance",
  "user-management" ,
  "admin-management",
  "withdrawals",
  "transaction",
  "logs",
}
export enum ENewPermissions {
  "setting" = "setting",
  "maintenance" = "maintenance",
  "user-management" = "user-management" ,
  "admin-management" = "admin-management",
  "withdrawals" = "withdrawals",
  "transaction" = "transaction",
  "logger" = "logger",
  "dashboard" = "dashboard"
}