import { createAsyncThunk } from "@reduxjs/toolkit";
import { IValidationErrors, TopUsersType } from "../../../../shared/interfaces";
import { getTopUsersActionAPI } from "../../../../APIs/user.api";
import { IGetTopUsersActionArg } from "../../../../shared/interfaces/user-management/topUsers/IGetTopUsersActionArg";

export const getTopUsersAction = createAsyncThunk<
  TopUsersType | undefined,
  IGetTopUsersActionArg,
  {
    rejectValue: IValidationErrors;
  }
>("user/top", (params, { rejectWithValue }) => {
  try {
    return getTopUsersActionAPI(params)
      .then((res) => {
        // console.log("getTopUsersActionres", res);
        return res.data.data;
      })
      .catch((error) => {
        return rejectWithValue({
          message: error.message,
          code: error.response.status,
          name: "user/signout",
        });
      });
  } catch (error: any) {
    let message = "";
    console.log(error);
    return rejectWithValue({
      message: message,
      code: error.response.status,
      name: "user/signout",
    });
  }
});
