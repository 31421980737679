import { transactionExcelAPI } from "./../../../../APIs/transaction.api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IValidationErrors } from "../../../../shared/interfaces/error";
import { ITransactionExcelFile } from "../../../../shared/interfaces/transaction/ITransactionExcelFile";


export const getTransactionExcelFileAction = createAsyncThunk<
  ITransactionExcelFile,
  void,
  {
    rejectValue: IValidationErrors;
  }
>("transaction/file", async (_, thunkAPI) => {
  try {
    return await transactionExcelAPI()
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue({
          message: error.response.data.error,
          name: "transaction/file",
          code: error.response.status,
        });
      });
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      message: error.response.message,
      name: "transaction/file",
      code: error.response.status,
    });
  }
});
