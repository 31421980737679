import { IPermissionRouteGuardProps } from "../shared/interfaces";
import { errorAlert } from "../shared/components/utils/alert";
import { checkPermission } from "../shared/service/chackPermission";
import { useIfOpenPage } from "../shared/service/useIfOpenPage";
import { Redirect } from "react-router-dom";

export const PermissionRouteGuard = ({
  children,
  section,
}: IPermissionRouteGuardProps) => {
  const { hasPermission } = checkPermission(section);
  const IsOpenPage = useIfOpenPage();

  if (hasPermission) {
    return children;
  }

 
  return <></>;
};


// !IsOpenPage &&
// errorAlert("You Don't Have Permission To access This Section.");