import { createAsyncThunk } from "@reduxjs/toolkit";
import { IValidationErrors } from "../../../../shared/interfaces/error";
import {paymentAPI} from "../../../../APIs/withdrawal.api";
import {IPayment} from "../../../../shared/interfaces/withdrawal/IPayment";
export const paymentAction = createAsyncThunk<
any,
IPayment,
{
    rejectValue: IValidationErrors;
}
>('admin/payment' , async ({privateKey , cryptoPairId} , thunkAPI) =>{
    try{
        return await paymentAPI({privateKey , cryptoPairId })
        .then((response)=>{
            return response.data.message
        })
        .catch((error)=>{
            return thunkAPI.rejectWithValue({
                message : error.response.message,
                name: 'admin/payment',
                code: error.response.status
            })
        })
    } catch(error : any){
        return thunkAPI.rejectWithValue({
            message : error.response.message,
            name: 'admin/payment',
            code: error.response.status
        })
    }
})
