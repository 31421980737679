// @ts-nocheck
import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import ReCAPTCHA from "react-google-recaptcha";
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const validateMessages = {
  required: "Email is required!",
  types: {
    email: "Email is not a valid email!",
  },
};
const LogInForm = ({ onChange, value, onChangeCaptcha, valueCaptcha , onKeyPress }: any) => {
  const onFinish = (values: any) => {
    // console.log(values);
  };
  const [form] = Form.useForm();

  return (
    <div className="loginForm_wrapper">
      <Form
        form={form}
        {...layout}
        name="nest-messages"
        onFinish={onFinish}
        validateMessages={validateMessages}
        className="login_form"
        onKeyPress = {onKeyPress}
      >
        <Form.Item name={["email"]} rules={[{ type: "email" }]}>
          <Input
            className="input_login"
            placeholder="Please enter email"
            onChange={onChange}
            value={value}
          />
        </Form.Item>
      </Form>

      <ReCAPTCHA
        sitekey={process.env.REACT_APP_SITE_KEY}
        onChange={onChangeCaptcha}
        defaultValue={valueCaptcha}
      />
    </div>
  );
};

export default LogInForm;
