import React from 'react';
import { WithdrawSettingTabs } from './WithdrawSettingTabs';
export const WithdrawSettingCard = () => {
    return (
        <div>
            <WithdrawSettingTabs />
        </div>
    );
};

