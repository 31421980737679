import axios from "axios";
import { MaintenanceablePagesParamsType } from "../shared/interfaces";
import { TokenClient } from "../shared/service/addTokenToReq";
import { requestInterceptor } from "../shared/service/useRequestInterceptor";

const maintenanceAxios = axios.create({
  baseURL: `${process.env.REACT_APP_API_ROOT}/admin/maintenance`,
});
requestInterceptor(maintenanceAxios);
TokenClient(maintenanceAxios);

export const getMaintenanceablePagesActionAPI = (
  params: MaintenanceablePagesParamsType
) => maintenanceAxios.get(`/maintenancable-resource`, { ...params });

export const patchMaintenancePagesActionAPI = (
  pageid: number,
  params: { active: boolean }
) =>
  maintenanceAxios({
    method: "patch",
    url: `/maintenancable-resource/${pageid}/`,
    params: params,
  });
// maintenanceAxios.patch(`/maintenancable-resource/${pageid}`, {}, params);
