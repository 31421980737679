import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGear,
  faGears,
  faWrench,
  faUser,
  faUsers,
  faUsersViewfinder,
  faUserTie,
  faCoins,
  faMoneyBillTransfer,
  faMoneyCheckDollar,
  faFilterCircleDollar,
  faUserPlus,
  faAddressCard,
  faCircleDollarToSlot,
  faHandHoldingDollar,
  faDollarSign,
} from "@fortawesome/free-solid-svg-icons";
import {
  LogoutOutlined,
  SolutionOutlined,
  TeamOutlined,
} from "@ant-design/icons";

export const signOutIcon = <LogoutOutlined />;
//sidebar icons
export const maintenanceIcon = <FontAwesomeIcon icon={faWrench} />;
export const userIcon = <FontAwesomeIcon icon={faUser} />;
export const usersIcon = <FontAwesomeIcon icon={faUsers} />;
export const topusersIcon = <FontAwesomeIcon icon={faUsersViewfinder} />;
export const adminusersIcon = <FontAwesomeIcon icon={faUserTie} />;
export const addAdminIcon = <FontAwesomeIcon icon={faUserPlus} />;
export const transactionsIcon = <FontAwesomeIcon icon={faMoneyBillTransfer} />;
export const DepositIcon = <FontAwesomeIcon icon={faCircleDollarToSlot} />;
export const withdrawSettingIcon = (
  <FontAwesomeIcon icon={faMoneyCheckDollar} />
);
export const settingIcon = <FontAwesomeIcon icon={faGear} />;
export const settingsIcon = <FontAwesomeIcon icon={faGears} />;
export const WithdrawalsIcon = <FontAwesomeIcon icon={faFilterCircleDollar} />;
export const ProfileIcon = <FontAwesomeIcon icon={faAddressCard} />;
export const LogsIcon = <SolutionOutlined />;
export const AdminsListIcon = <TeamOutlined />;
//dashboard
export const depositIcon = <FontAwesomeIcon icon={faCoins} />;
export const withdrawIcon = <FontAwesomeIcon icon={faHandHoldingDollar} />;
export const dollarSignIcon = <FontAwesomeIcon icon={faDollarSign} />;

export { ReactComponent as UserIcon } from "./user.svg";
export { ReactComponent as WalletIcon } from "./wallet-svgrepo-com.svg";
//   export { ReactComponent as WithdrawalsIcon } from './withdraw.svg';
export { ReactComponent as DashboardIcon } from "./dashboard.svg";
export { ReactComponent as DollarCircled } from "./dollarCircled.svg";
export { ReactComponent as TwoUsers } from "./twoUsers.svg";
export { ReactComponent as TimerIcon } from "./timer.svg";
