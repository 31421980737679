import { createAsyncThunk } from "@reduxjs/toolkit";
import { patchMaintenancePagesActionAPI } from "../../../../APIs/maintenance.api";
import {
  IPatchMaintenanceActionArg,
  MaintenanceablePagesArrType,
} from "../../../../shared/interfaces";

export const patchMaintenancePagesAction = createAsyncThunk<
  MaintenanceablePagesArrType | void,
  IPatchMaintenanceActionArg,
  any
>("maintenance/patch-maintenanceable-pages", (pageidAndActive) => {
  try {
    return patchMaintenancePagesActionAPI(pageidAndActive.pageid, {
      active: pageidAndActive.active,
    })
      .then((res) => {
        // console.log("patchMaintenancePagesAction res", res);

        return (({
          controller: urlAddress,
          resource: name,
          active: status,
          id,
        }) => [
          {
            urlAddress,
            name,
            status,
            id,
          },
        ])(res.data.data);
      })
      .then((err) => {
        console.error(err);
        return err;
      });
  } catch (error: any) {
    throw error;
  }
});
