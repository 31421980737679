import { createAsyncThunk } from "@reduxjs/toolkit";
import { IValidationErrors } from "../../../../shared/interfaces/error";
import { getUserByIdAPI } from "../../../../APIs/user.api";
import { IGetUserById } from "../../../../shared/interfaces/user/IUser";

export const getUserByIdAction = createAsyncThunk<
  IGetUserById,
  any,
  {
    rejectValue: IValidationErrors;
  }
>("user/user:id", async (id, thunkAPI) => {
  try {
    return await getUserByIdAPI(id)
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue({
          message: error.response.data.error,
          name: "user/user:id",
          code: error.response.status,
        });
      });
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      message: error.response.data.error,
      name: "user/user:id",
      code: error.response.status,
    });
  }
});
