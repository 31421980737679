import { Form, Popconfirm, Table, Typography, Card, Switch } from "antd";
import React, { useState, useEffect, useRef, useCallback } from "react";

import Bep20 from "../../../../assets/images/new-coin-logo/Group 5.png";
import BitCoin from "../../../../assets/images/new-coin-logo/Group 8.png";
import TetherERC20 from "../../../../assets/images/new-coin-logo/Group 3.png";
import TetherBEP20 from "../../../../assets/images/new-coin-logo/Group 4.png";
import TetherTRC20 from "../../../../assets/images/new-coin-logo/Group 2.png";
import TRX from "../../../../assets/images/new-coin-logo/Group 1.png"
import Ethereum from "../../../../assets/images/new-coin-logo/Group 7.png";

import Item from "antd/lib/list/Item";
import { uniqueId } from "lodash";
import { fetchCryptoAction } from "../../actions/withdrawSetting-Actions/fetchCryptoAction";
import {
  IFetchCrypto,
  IpatchCryptoRes,
} from "../../../../shared/interfaces/setting/index";
import { patchCryptoAction } from "../../actions/withdrawSetting-Actions/patchCryptoAction";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { EditableCell } from "./EditableCell";
import { faRecordVinyl } from "@fortawesome/free-solid-svg-icons";
import { ajaxSuccessAlert } from "../../../../shared/components/utils/alert";
import { ColumnType } from "antd/es/table";
import { networkedCoins } from "../../../../shared/service/utils";
interface Item {
  key: string;
  coin: React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >;
  coinName: string;
  percent: string;
  status: boolean;
}
export const usePrevious = (value: any) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

// const originData  = [
//   {
//     id: "1",
//     name: (
//       <img src={Ethereum} alt="coins" className="withdrawSetting_Ethereum" />
//     ),
//     name: "ETH/ERC20",
//     commissionPercent: "0.01",
//     isWithdrawAvailable: true,
//   },
//   {
//     id: "2",
//     name: <img src={TetherERC20} alt="coins" />,
//     name: "USDT/ERC20",
//     commissionPercent: "0.03",
//     isWithdrawAvailable: false,
//   },
//   {
//     id: "3",
//     name: <img src={TetherBEP20} alt="coins" />,
//     name: "USDT/BEP20",
//     commissionPercent: "0.02",
//     isWithdrawAvailable: true,
//   },
//   {
//     id: "4",
//     name: <img src={TetherTRC20} alt="coins" />,
//     name: "USDT/TRC20",
//     commissionPercent: "0.01",
//     isWithdrawAvailable: false,
//   },
//   {
//     id: "5",
//     name: <img src={Bep20} alt="coins" />,
//     name: "BNB/Bep20",
//     commissionPercent: "0.01",
//     isWithdrawAvailable: false,
//   },
//   {
//     id: "6",
//     name: <img src={BitCoin} alt="coins" />,
//     name: "BTC/BITCOIN",
//     commissionPercent: "0.06",
//     isWithdrawAvailable: false,
//   },
//   {
//     id: "7",
//     name: <img src={TRX} alt="coins" />,
//     name: "TRX/TRC20",
//     commissionPercent: "0.06",
//     isWithdrawAvailable: false,
//   },
// ];

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: any;
  title: any;
  inputType: "number" | "text";
  statusButton: boolean;
  record: IFetchCrypto;
  index: number;
  children: React.ReactNode;
}

// const EditableCell: React.FC<EditableCellProps> = ({
//   editing,
//   dataIndex,
//   title,
//   inputType,
//   record,
//   index,
//   children,
//   statusButton,
//   ...restProps
// }) => {
//   const inputNode =
//     inputType === "number" ? (
//       <InputNumber />
//     ) : (
//       <div className="inputEdit_wrapper">
//         <Input className="inputEdit" maxLength={3} />
//       </div>
//     );
//   return (
//     <td {...restProps}>
//       {editing ? (
//         <>
//           <Form.Item
//             className="inputEdit_wrapper"
//             name={dataIndex}
//             style={{ margin: 0 }}
//             rules={[
//               {
//                 required: true,
//                 message: `Please enter commission percent`,
//               },
//             ]}
//           >
//             {dataIndex === "commissionPercent" && (
//               <div>
//                 <Input value={record.commissionPercent}  className="inputEdit" maxLength={3} />
//               </div>
//             )}
//           </Form.Item>
//           <Form.Item className="cryptoStatus_wrapper" name={dataIndex}>
//             {dataIndex === "isWithdrawAvailable" && (
//               <Switch
//                 defaultChecked={record.isWithdrawAvailable}
//                 className="Crypto_status"
//               />
//             )}
//           </Form.Item>
//         </>
//       ) : (
//         children
//       )}
//     </td>
//   );
// };

const WithdrawCrypto: React.FC = () => {
  const [form] = Form.useForm();
  // const [data, setData] = useState(originData);
  const [editingKey, setEditingKey] = useState("");
  const [disabled, setDisabled] = useState(false);
  const prevToggle = usePrevious(disabled);
  const isEditing = (record: IFetchCrypto) =>
    String(record.cryptoPairId) === editingKey;
  const dispatch = useAppDispatch();
  const { fetchCrypto, patchCryptoRes } = useAppSelector(
    (state) => state.setting
  );

  const fetchData = useCallback(async () => {
    await dispatch(fetchCryptoAction());
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const edit = (
    record: Partial<IFetchCrypto> // & { key: React.Key }
  ) => {
    form.setFieldsValue({ name: "", age: "", address: "", ...record });
    setEditingKey(String(record.cryptoPairId));
    // console.log("id", record.id);
    setDisabled(!disabled);
  };

  // const addNewData = useCallback(async (key: React.Key) => {
  //   try {
  //     const row = (await form.validateFields()) as IpatchCryptoRes;
  //     const newData = [patchCryptoRes.data];
  //     const index = newData.findIndex((item) => key === item.cryptoPairId);

  //     if (index > -1) {
  //       const item = newData[index];
  //       newData.splice(index, 1, {
  //         ...item,
  //         ...row,
  //       });
  //       // setData(newData);
  //     } else {
  //       // newData.push(row);
  //       // setData(newData);
  //       setEditingKey("");
  //     }
  //   } catch (errInfo) {
  //     console.log("Validate Failed:", errInfo);
  //   }
  // }, []);

  //@ts-expect-error  should declare values type
  const onFinish = (values) => {
    const cryptoData = {
      commissionPercent: Number(values.commissionPercent) / 100,
      isWithdrawAvailable: values.isWithdrawAvailable,
      cryptoPairId: Number(editingKey),
    };
    dispatch(patchCryptoAction(cryptoData)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        ajaxSuccessAlert("Crypto Setting Upadated Successfully");
      }
    });
    setEditingKey("");
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async () => {
    form.submit();
  };

  function CoinImg(coinName: string, cryptoNetwork: string | null) {
    const currentCoinPait = networkedCoins.find(
      ({ coinSymbol, networkName }) =>
        coinSymbol === coinName && networkName === cryptoNetwork
    );
 
    return currentCoinPait ? (
      <img src={currentCoinPait?.icon} alt={coinName + cryptoNetwork} />
    ) : null;
  }
  // interface IColumns {
  //   title: JSX.Element;
  //   dataIndex: string;
  //   width?: string;
  //   editable?: boolean;
  //   onCell?: (record: IFetchCrypto) => {
  //     record: IFetchCrypto;
  //     inputType: string;
  //     dataIndex: string;
  //     title: JSX.Element;
  //     editing: boolean;
  //   };
  //   render: (
  //     textStatus: boolean | string,
  //     record?: IFetchCrypto
  //   ) => JSX.Element;
  // }
  interface ColumnTypeEditable<RecordType> extends ColumnType<RecordType> {
    editable?: boolean;
  }

  const columns: ColumnTypeEditable<IFetchCrypto>[] = [
    {
      title: <p className="settingWithdraw_ColTable">Coin</p>,
      dataIndex: "cryptoSymbol",
      width: "10%",
      editable: false,
      render: (textStatus: any, record) => (
        <div className="settingWithdraw_ColTable">
          {CoinImg(textStatus, record.networkName)}
        </div>
      ),
    },
    {
      title: <p className="settingWithdraw_ColTable">Name</p>,
      dataIndex: "cryptoName",
      width: "25%",
      editable: false,
      render: (_, record) => (
        <div className="settingWithdraw_ColTable">
          {`${record.cryptoSymbol} ${record.networkName}`}
        </div>
      ),
    },
    {
      title: <p className="settingWithdraw_ColTable">Commission Percent</p>,
      dataIndex: "commissionPercent",
      width: "25%",
      editable: true,
      render: (textStatus: any) => (
        <div className="settingWithdraw_ColTable">{textStatus * 100}%</div>
      ),
    },
    {
      title: <p className="settingWithdraw_ColTable">Status</p>,
      dataIndex: "isWithdrawAvailable",
      width: "25%",
      editable: true,
      render: (textStatus: any) => (
        <div className="settingWithdraw_ColTable">
          <Switch
            key={uniqueId()}
            defaultChecked={!!textStatus}
            disabled={true}
          />
        </div>

        // const editable = isEditing(record);
        // return editable ? (
        //   <div className="settingWithdraw_ColTable">
        //     <Switch onChange={changeHandler} />
        //   </div>
        // ) : (
        //   <div className="settingWithdraw_ColTable">
        //     <Switch disabled={true} onChange={changeHandler} />
        //   </div>
        // );
      ),
    },
    {
      title: <p className="settingWithdraw_ColTable">Operation</p>,
      dataIndex: "operation",
      render: (textStatus: any, record) => {
        const editable = isEditing(record as IFetchCrypto);
        return editable ? (
          <p className="settingWithdraw_ColTable">
            <Typography.Link onClick={() => save()} style={{ marginRight: 8 }}>
              Save
            </Typography.Link>
            <Typography.Link onClick={() => setDisabled(disabled)}>
              <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                <a>Cancel</a>
              </Popconfirm>
            </Typography.Link>
          </p>
        ) : (
          <p className="settingWithdraw_ColTable">
            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => edit(record as IFetchCrypto)}
            >
              Edit
            </Typography.Link>
          </p>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: IFetchCrypto) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <div className="settingWithdraw_wrapper">
      <Card className="settingWithdraw_CRYPTO">
        <Form form={form} component={false} onFinish={(e) => onFinish(e)}>
          <Table
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            dataSource={fetchCrypto.data}
            //@ts-expect-error Let's hope it works!
            columns={mergedColumns}
            rowClassName="editable-row"
            pagination={false}
          />
        </Form>
      </Card>
    </div>
  );
};

export default WithdrawCrypto;
