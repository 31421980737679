import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from "@reduxjs/toolkit";

import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux";

import adminReducer from "./features/authentication/reducers/authentication-reducer";
import maintenanceReducer from "./features/maintenance/state/maintenance-reducer";
import settingReducer from "./features/setting/reducers/setting-reducer";
import withdrawalReducer from "./features/withdrawal/state/reducers/withdrawal-reducer";
import sharedReducer from "./shared/reducers/shared-reducer";
import userReducer from "./features/user_management/state/reducers/user-reducer";
import transactionReducer from "./features/transactions/state/reducers/transaction-reducer";
import adminManagementReducer from "./features/admin-management/state/reducers/admin-reducer";
import dashboardReducer from "./features/dashboard/state/reducers/dashboard-reducer";
import { apiPendingMiddleware } from "./shared/service/apiPendingMiddleware";

// import { apiPendingMiddleware } from "./shared/service/apiPendingMiddleware";
// export const apiPendingMiddleware = createListenerMiddleware();

const appReducer = combineReducers({
  admin: adminReducer,
  maintenance: maintenanceReducer,
  shared: sharedReducer,
  setting: settingReducer,
  withdrawal: withdrawalReducer,
  user: userReducer,
  transaction : transactionReducer,
  adminManagement : adminManagementReducer,
  dashboard : dashboardReducer
});

//@ts-ignore
const rootReducer = (state, action) => {
  // for clearing state on signout
  if (action.type === "User/signout") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(apiPendingMiddleware.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
