import { createAsyncThunk } from "@reduxjs/toolkit";
import { IValidationErrors } from "../../../../shared/interfaces/error";
import { activityAPI } from "../../../../APIs/dashboard.api";
import { IActivity } from "../../../../shared/interfaces/dashboard/IActivity";
import { IActivityParams } from "../../../../shared/interfaces/dashboard/IActivityParams";


export const getActivityAction = createAsyncThunk<
  IActivity,
  IActivityParams,
  {
    rejectValue: IValidationErrors;
  }
>("dashboard/activity", async (params, thunkAPI) => {
  try {
    return await activityAPI({ params: params })
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue({
          message: error.response.message,
          name: "dashboard/activity",
          code: error.response.status,
        });
      });
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      message: error.response.message,
      name: "dashboard/activity",
      code: error.response.status,
    });
  }
});
