import { Tabs } from "antd";
import type { ColumnsType } from "antd/es/table";
import { IUsersData } from "../../../shared/interfaces/user";
import ChosenUser from "./ChosenUser";
import { FC, useEffect, useState } from "react";
import { GraphicCopyToClipboard } from "../../../shared/components/utils/GraphicCopyToClipboard";
import { truncate } from "lodash";
import { ScrollableTableExcelled } from "../../../shared/components/utils/ScrollableTableExcelled";
import { currencyFormat } from "../../../shared/service/utils";
import { ETopUserTypes } from "../../../shared/enums/ETopUserTypes";
import { useAppDispatch, useAppSelector } from "../../../store";
import { getTopUsersAction } from "../state/actions/getTopUsersAction";
import {ITopUserExcelParams} from "../../../shared/interfaces/user-management/topUsers/ITopUserExcelParams";
import { TopUserTypesType } from "../../../shared/interfaces/user-management/topUsers/TopUserTypesType";
import { downloadExcel } from "../../../shared/service/downloadExcel";
import {getTopUserExcelAction} from "../state/actions/getTopUserExcelAction"
import { ITopUserExcel } from "../../../shared/interfaces/user-management/topUsers/ITopUserExcel";
const columns: ColumnsType<IUsersData> = [
  {
    title: "Name",
    dataIndex: "nickname",
    key: "name",
    width: "1%",
    render: (text) => <a>{text}</a>,
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    width: "10%",
    render: (email) => (
      <GraphicCopyToClipboard text={email}>
        <span>{truncate(email, { length: 18 })}</span>
      </GraphicCopyToClipboard>
    ),
  },
  {
    title: "Investments",
    dataIndex: "investments",
    key: "investments",
    align: "center",
    render: (text) => <div>{currencyFormat(text)}</div>,
    width: "10%",
  },
  {
    width: "10%",
    title: "Referrals",
    key: "referralProfits",
    dataIndex: "referralProfits",
    render: (text) => <div>{currencyFormat(text)}</div>,
    align: "center",
  },
  {
    width: "10%",
    title: "Earnings",
    key: "earnings",
    dataIndex: "earnings",
    render: (text) => <div>{currencyFormat(text)}</div>,
    align: "center",
  },
  {
    title: "Downlines",
    width: "5%",
    key: "downlines",
    dataIndex: "downlines",
    align: "center",
  },
  {
    title: "Uplines",
    key: "uplines",
    width: "5%",
    dataIndex: "uplines",
    align: "center",
  },
  {
    title: "Directs",
    key: "directs",
    dataIndex: "directs",
    width: "5%",
    align: "center",
  },
  {
    title: "Withdrawals",
    key: "withdrawals",
    dataIndex: "withdrawals",
    render: (text) => <div>{currencyFormat(text)}</div>,
    align: "center",
    width: "10%",
  },

  // Table.EXPAND_COLUMN,
];

const TopusersTable: FC = () => {
  const dispatch = useAppDispatch();
  const { data, status } = useAppSelector((state) => state.user.topUsers);
  const { totalItems, users } = data ?? {};
  const [type , setType] = useState("invests")
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: totalItems,
  });

  useEffect(() => {
    setPagination({ ...pagination, total: totalItems || 0 });
  }, [totalItems]);

  const onChange = (key: string) => {
    const typedKey = key as TopUserTypesType | "5";
    setType(typedKey)
    // console.log("typedKey" , typedKey)
    if (typedKey !== "5") {
      dispatch(getTopUsersAction({ page: 1, limit: 10, type: typedKey }));
      setPagination({
        current: 1,
        pageSize: 10,
        total: totalItems,
      });
    }
  };

  const paginationOnChange = (
    page: number,
    pageSize: number,
    type: TopUserTypesType
  ) => {
    setPagination({ ...pagination, current: page, pageSize });
    dispatch(getTopUsersAction({ page, limit: pageSize, type }));
  };


  const clickHandler =async()=>{
    // console.log("type" , type)
    await dispatch(getTopUserExcelAction(type as ITopUserExcelParams)).then((res) => {
      const fileName = (res!.payload! as ITopUserExcel).filepath;
      downloadExcel(fileName);
    });
  }

  return (
    <>
      <Tabs
        className="topUsers"
        defaultActiveKey={ETopUserTypes.invests}
        onChange={onChange}
        items={[
          {
            label: "Top 100 High-Profile Users",
            key: ETopUserTypes.invests,
            children: (
              <ScrollableTableExcelled
                columns={columns}
                dataSource={users}
                onClick = {clickHandler}
                excelIsActive ={users ? true : false}
                onChange={({
                  current,
                  pageSize,
                  total,
                }: {
                  current: number;
                  pageSize: number;
                  total: number;
                }) =>
                  paginationOnChange(current, pageSize, ETopUserTypes.invests)
                }
                pagination={pagination}
              />
            ),
          },

          {
            label: "Top 100 Withdrawals",
            key: ETopUserTypes.withdrawals,
            children: (
              <ScrollableTableExcelled
                columns={columns}
                onClick = {clickHandler}
                excelIsActive ={users ? true : false}
                dataSource={users}
                onChange={({
                  current,
                  pageSize,
                  total,
                }: {
                  current: number;
                  pageSize: number;
                  total: number;
                }) => {
                  paginationOnChange(
                    current,
                    pageSize,
                    ETopUserTypes.withdrawals
                  );
                }}
                pagination={pagination}
              />
            ),
          },
          {
            label: "Top 100 Referral Commission",
            key: ETopUserTypes.commissions,
            children: (
              <ScrollableTableExcelled
                columns={columns}
                excelIsActive ={users ? true : false}
                onClick = {clickHandler}
                dataSource={users}
                onChange={({
                  current,
                  pageSize,
                  total,
                }: {
                  current: number;
                  pageSize: number;
                  total: number;
                }) =>
                  paginationOnChange(
                    current,
                    pageSize,
                    ETopUserTypes.commissions
                  )
                }
                pagination={pagination}
              />
            ),
          },
          {
            label: "Top 100 Direct Referrals",
            key: ETopUserTypes.directs,
            children: (
              <ScrollableTableExcelled
                excelIsActive ={users ? true : false}
                columns={columns}
                dataSource={users}
                onClick = {clickHandler}
                onChange={({
                  current,
                  pageSize,
                  total,
                }: {
                  current: number;
                  pageSize: number;
                  total: number;
                }) =>
                  paginationOnChange(current, pageSize, ETopUserTypes.directs)
                }
                pagination={pagination}
              />
            ),
          },
          {
            label: `Chosen User`,
            key: "5",
            children: <ChosenUser />,
          },
        ]}
      />
    </>
  );
};

export default TopusersTable;
