import { createAsyncThunk } from "@reduxjs/toolkit";
import { IValidationErrors } from "../../../../shared/interfaces/error";
import { getWithdrawalExcelAPI } from "../../../../APIs/withdrawal.api";
import {IWithdrawalExcel} from "../../../../shared/interfaces/withdrawal/IWithdrawalExcel";
import {IWithdrawalExcelParams} from "../../../../shared/interfaces/withdrawal/IWithdrawalExcelParams";

export const getWithdrawalExcelAction = createAsyncThunk<
IWithdrawalExcel, 
IWithdrawalExcelParams | undefined,
{
    rejectValue: IValidationErrors;
}
>("withdrawal/file" , async(params , thunkAPI)=>{
    try{
        return await getWithdrawalExcelAPI({params : params})
        .then((response) =>{
            return response.data.data
        })
        .catch((error)=>{
            return thunkAPI.rejectWithValue({
                message : error.response.message,
                name: "withdrawal/file",
                code: error.response.status,
    
            })
        })
    } catch(error : any) {
        return thunkAPI.rejectWithValue({
            message : error.response.message,
            name: "withdrawal/file",
            code: error.response.status,

        })
    }
})