// import { useState } from "react";
import { Menu, Button } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import _, { cloneDeep } from "lodash";
import { NavLink, useLocation, useRouteMatch } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import {
  addAdminIcon,
  AdminsListIcon,
  adminusersIcon,
  DashboardIcon,
  DepositIcon,
  LogsIcon,
  maintenanceIcon,
  ProfileIcon,
  settingIcon,
  settingsIcon,
  topusersIcon,
  transactionsIcon,
  UserIcon,
  usersIcon,
  WithdrawalsIcon,
  withdrawSettingIcon,
} from "../../assets/icons";
import { checkPermission } from "../../shared/service/chackPermission";
import {
  EPermissionsKeys,
  EPermissionsValues,
  TEPermissionsUnion,
} from "../../shared/interfaces";
import {
  alwaysOpenPages,
  checkIfOpenPage,
  logs,
  replaceChildren,
} from "../../shared/service/utils";
import { TPermissions } from "../../shared/interfaces/sharedComponents/IDecodedToken";

function Sidenav({ color }: any) {
  const { pathname } = useLocation();
  const { url } = useRouteMatch();
  const page = pathname.replace("/", "");

  const menuClickHandler = (menuClickItem: any) => {
    // console.log(menuClickItem);
  };
  type TSideNavItemChild = {
    path: string;
    label: string;
    icon: JSX.Element[];
  }[];
  type TNavItemArr = {
    path: EPermissionsKeys | "dashboard";
    label: string;
    icon: JSX.Element[];
    children?: TSideNavItemChild;
  }[];
  const navItemsArr: TNavItemArr = [
    {
      path: "dashboard",
      label: "Dashboard",
      icon: [<DashboardIcon />],
    },
    {
      path: "user-management",
      label: "User Management",

      icon: [<UserIcon />],
      children: [
        {
          path: "users",
          label: "Users",
          icon: [usersIcon],
        },
        {
          path: "top-users",
          label: "Top Users",
          icon: [topusersIcon],
        },
      ],
    },
    {
      path: "admin-management",
      label: "Admin Management",
      icon: [adminusersIcon],
      children: [
        {
          path: "profile",
          label: "Profile",
          icon: [ProfileIcon],
        },
        {
          path: "logger",
          label: "Logger",
          icon: [LogsIcon],
        },
        {
          path: "admins-list",
          label: "Admins List",
          icon: [AdminsListIcon],
        },
      ],
    },

    {
      path: "withdrawals",
      label: "Withdrawals",
      icon: [WithdrawalsIcon],
    },
    {
      path: "transaction",
      label: "Transaction",
      icon: [transactionsIcon],
    },

    {
      path: "maintenance",
      label: "Maintenance",
      icon: [maintenanceIcon],
    },

    {
      path: "setting",
      label: "Setting",
      icon: [settingIcon],
      children: [
        {
          path: "main-setting",
          label: "Main Setting",
          icon: [settingsIcon],
        },
        {
          path: "deposit-setting",
          label: "Deposit Setting",
          icon: [DepositIcon],
        },
        {
          path: "withdraw-setting",
          label: "Withdraw Setting",
          icon: [withdrawSettingIcon],
        },
      ],
    },
  ];

  function makeNavItemsArr(navItemsArr: TNavItemArr) {
    const filteredNaItemsArr = navItemsArr.map((navItem) => {
      const isOpenPage = checkIfOpenPage(alwaysOpenPages, navItem.path);
      const hasChildren = navItem.children;
      const { hasPermission: menuHasPermission } = checkPermission(
        navItem.path as TPermissions
      );
      
      const filterSideNavChildren = (
        childObj: TSideNavItemChild,
        menuHasPermission: boolean
      ) =>
        childObj.filter((child) => {
          if (child.path === logs) {
            const { hasPermission: hasPermissionToLogs = undefined } =
              checkPermission(child.path);
            return hasPermissionToLogs;
          }

          const isChildOpenPage = checkIfOpenPage(alwaysOpenPages, child.path);

          if (isChildOpenPage) return isChildOpenPage;

          return menuHasPermission;
        });

      if (isOpenPage && hasChildren) {
        const newChildren = filterSideNavChildren(
          navItem.children!,
          menuHasPermission
        );

        const newNavItem = replaceChildren(navItem, newChildren);

        return newNavItem;
      }
      if (isOpenPage && !hasChildren) {
        return navItem;
      }

      if (!isOpenPage && !hasChildren && menuHasPermission) {
        return navItem;
      }
      if (!isOpenPage && !hasChildren && !menuHasPermission) {
        return null;
      }
      if (!isOpenPage && hasChildren) {
        const newChildren = filterSideNavChildren(
          navItem.children!,
          menuHasPermission
        );

        const newNavItem = replaceChildren(navItem, newChildren);

        return newNavItem;
      }

      // if (isOpenPage && !hasChildren) {
      //   return navItem;
      // }

      // if (isOpenPage && hasChildren && !hasPermission) {
      //   const filteredChildren = filterChildren(navItem.children!);
      //   navItem.children = filteredChildren;

      //   return navItem;
      // }

      // if (isOpenPage && hasChildren) {
      //
      //   const filteredChildren = filterChildren(navItem.children!);
      //   navItem.children = filteredChildren;
      //   return navItem;
      // }

      // if (hasPermission) {
      //   return navItem;
      // }

      // return null;
    });
    const nullsFiltered = filteredNaItemsArr.filter(
      (item) => item
    ) as TNavItemArr;

    return nullsFiltered;
  }
  const filteredNaItemsArr = makeNavItemsArr(navItemsArr);

  const sideNavItemMaker = (navItems: typeof navItemsArr) => {
    return navItems.map((item: any) =>
      !item.children ? (
        <Menu.Item key={item.path}>
          <NavLink to={`${url}/${item.path}`}>
            <span
              key={item.path}
              className="icon"
              style={{
                background: page === item.path ? color : "",
              }}
            >
              {item.icon}
            </span>
            <span className="label" key={item.path}>
              {item.label}
            </span>
          </NavLink>
        </Menu.Item>
      ) : (
        <SubMenu
          key={item.path}
          title={
            <span>
              <span
                className="icon"
                style={{
                  background: page.includes(item.path) ? color : "",
                }}
              >
                {item.icon}
              </span>
              <span>{item.label}</span>
            </span>
          }
        >
          {item.children.map((childItem: any) => (
            <Menu.Item key={childItem.path}>
              <NavLink to={`${url}/${item.path}/${childItem.path}`}>
                <span className="label ml-6">
                  <span
                    className="sub-icon"
                    style={{
                      background: page === item.path ? color : "",
                    }}
                  >
                    {childItem.icon}
                  </span>

                  {childItem.label}
                </span>
              </NavLink>
            </Menu.Item>
          ))}
        </SubMenu>
      )
    );
  };

  return (
    <>
      <div className="brand">
        <img src={logo} alt="" />
        <span>Posterity Dashboard</span>
      </div>
      <hr />
      <Menu
        theme="light"
        mode="inline"
        inlineIndent={16}
        onClick={menuClickHandler}
      >
        {sideNavItemMaker(filteredNaItemsArr)}
      </Menu>
    </>
  );
}

export default Sidenav;
