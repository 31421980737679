import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  IPatchCoinStatusPayload,
  IUpdateWalletAPIARG,
  IUpdateWalletReturn,
} from "../../../../shared/interfaces/setting/index";
import {
  patchCoinStatusAPI,
  updateWalletAPI,
} from "../../../../APIs/setting.api";

export const patchCoinStatusAction = createAsyncThunk<
  IPatchCoinStatusPayload,
  IPatchCoinStatusPayload,
  any
>("admin/patchCoinStatus", async (coinData, thunkAPI) => {
  try {
    return await patchCoinStatusAPI(coinData)
      .then((response) => {
        return coinData;
      })
      .catch((err) => {
        return thunkAPI.rejectWithValue(err);
      });
  } catch (error: any) {
    return error;
  }
});
