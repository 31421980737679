import axios from "axios";
import { TokenClient } from "../shared/service/addTokenToReq";
import { requestInterceptor } from "../shared/service/useRequestInterceptor";

const withdrawalAxios = axios.create({
  baseURL: `${process.env.REACT_APP_API_ROOT}/admin`,
});
requestInterceptor(withdrawalAxios);
TokenClient(withdrawalAxios);

export const getWithdrawalAPI = (params: any) =>
  withdrawalAxios.get(`/withdraw/withdraw`, { ...params });

export const postWithdrawalAPI = (withdrwalData: any) =>
  withdrawalAxios.post(`/withdraw/withdraw`, { ...withdrwalData });

export const getWithdrawByIdAPI = (id: any) =>
  withdrawalAxios.get(`/withdraw/withdraw/${id}`);

export const getPaidAPI = () => withdrawalAxios.get(`/withdraw/paid`);

export const getWithdrawalExcelAPI = (params: any) =>
  withdrawalAxios.get(`/withdraw/file`, params);

export const paymentAPI = ({ privateKey, cryptoPairId }: any) =>
  withdrawalAxios.post(`/payment/payment`, { privateKey , cryptoPairId });

 export const checkoutAPI = () => withdrawalAxios.get(`/withdraw/checkout`); 
