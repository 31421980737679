import { CopyToClipboard } from "react-copy-to-clipboard";
import { ReactNode } from "react";
import { Tooltip } from "antd";
import Swal from "sweetalert2";
type Props = {
  children: ReactNode;
  text: string;
};
export const GraphicCopyToClipboard = (props: Props) => {
  return (
    <Tooltip title={"copy to clipboard"}>
      <div className="cursor-pointer">
        <CopyToClipboard
          text={props.text}
          onCopy={() =>
            Swal.fire({
              toast: true,
              position: "top",
              showConfirmButton: false,
              timer: 3000,
              icon: "success",
              title: "Copied to Clipboard",
              text: props.text,
              padding: "0.5em",
              customClass: {
                title: "CopyClipTitle",
                container: "mt-0 ",
              },
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            })
          }
        >
          {props.children}
        </CopyToClipboard>
      </div>
    </Tooltip>
  );
};
