export interface IRequestOtp {
  //email : string;
  message: string;
  statusCode: number;
  error: [""];
}
export interface IVeifyOtpData {
  email: string;
  code: string;
}

export interface IVeifyOtpResponse {
  token: string;
  twoFaActivationAt: string;
}
export enum current {
  firstStep = 0,
  secondStep = 1,
  thirdStep = 2,
}
 