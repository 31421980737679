import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  IFetchUser,
  IParamsUserType,
} from "../../../../shared/interfaces/user/IUser";
import { getChosenUserAPI, getUserAPI } from "../../../../APIs/user.api";
import {
  IChosenUserData,
  IValidationErrors,
  TGetUserAPIParams,
} from "../../../../shared/interfaces";

export const getChosenUserAction = createAsyncThunk<
  IChosenUserData,
  TGetUserAPIParams,
  {
    rejectValue: IValidationErrors;
  }
>("user/chosen", async (params, thunkAPI) => {
  try {
    return await getChosenUserAPI(params)
      .then((res) => {
        return res.data.data;
      })
      .catch((error: any) => {
        return thunkAPI.rejectWithValue({
          message: error.response.data.error,
          name: "user/chosen",
          code: error.response.status,
        });
      });
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      message: error.response.data.error,
      name: "user/chosen",
      code: error.response.status,
    });
  }
});
