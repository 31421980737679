import React from 'react';
import LogInCard from '../components/LogInCard';
const LogInPage = () => {
    return (
        <div>
            <LogInCard/>
        </div>
    );
};

export default LogInPage;