import { createAsyncThunk } from "@reduxjs/toolkit";
import { IValidationErrors } from "../../../../shared/interfaces/error";
import { getAdminListAPI } from "../../../../APIs/admin.api";
import { IAdminListParams } from "../../../../shared/interfaces/admin-management/IAdminListParams";
import { IGetAdminListRes } from "../../../../shared/interfaces/admin-management/IGetAdminListRes";


export const getAdminListAction = createAsyncThunk<
  IGetAdminListRes,
  IAdminListParams,
  {
    rejectValue: IValidationErrors;
  }
>("admin/user", async (params, thunkAPI) => {
  try {
    return await getAdminListAPI({ params: params })
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue({
          message: error.response.message,
          name: "ademin/user",
          code: error.response.status,
        });
      });
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      message: error.response.message,
      name: "admin/user",
      code: error.response.status,
    });
  }
});
