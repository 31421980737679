import { createSlice } from "@reduxjs/toolkit";
import Cookie from "js-cookie";
import { requestOtpAction } from "../actions/request-otp-Action";
import { verifyOtpAction } from "../actions/verify-otp-Action";
import { IStatus } from "../../../shared/interfaces/IStatus";
import { IValidationErrors } from "../../../shared/interfaces/error";
import {
  ajaxErrorAlert,
  ajaxSuccessAlert,
} from "../../../shared/components/utils/alert";
import { redirectTo } from "../../../shared/service/utils";
import { logout } from "../../../shared/service/utils";
import { signoutAction } from "../actions/signoutAction";
import { reviseEmail } from "../actions/reviseEmailAction";
import { verify2FAAction } from "../actions/verify-2FA-Action";
import { current } from "../../../shared/interfaces/Auth/IAdminData";


const initialData: {
  requestOtpData: {
    status: IStatus;
  };
  signOutData: {
    status: IStatus;
    data: unknown;
    error: unknown;
  };
  verifyOtpData: {
    //status: IStatus;
    token: string;
    twoFaActivationAt: string;
  };
  verify2FA: {
    status: IStatus;
  };
  dataFetched: boolean;
  error: IValidationErrors | undefined;
  current: number;
  render: boolean;
} = {
  requestOtpData: {
    status: "idle",
  },
  signOutData: {
    status: "idle",
    error: "",
    data: {
      message: "",
      error: [""],
      statusCode: 0,
    },
  },
  verifyOtpData: {
    //status: "idle",
    token: "",
    twoFaActivationAt: "",
  },
  verify2FA: {
    status: "idle",
  },
  dataFetched: false,
  error: {},
  current: current.firstStep,
  render: false,
};

export const { actions, reducer } = createSlice({
  name: "admin",
  initialState: initialData,
  reducers: {
     
    setCurrent(state, action: {
      payload: `${current}` extends `${infer T extends number}` ? T : never; 
      type: string;
  }){

      state.current=action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestOtpAction.fulfilled, (state, action) => {
        state.requestOtpData.status = "idle";
        
        state.current = current.secondStep;
        if ((state.current = current.secondStep)) {
          state.render = true;
        }
      })
      .addCase(requestOtpAction.pending, (state) => {
        state.requestOtpData.status = "pending";
      })
      .addCase(requestOtpAction.rejected, (state, action) => {
        state.requestOtpData.status = "rejected";
        if (action.payload?.message) {
          // ajaxErrorAlert(action.payload.message);
          state.current = current.firstStep;
        }
      });

    builder.addCase("REVISE-EMAIL", (state, action) => {
      state.current = current.firstStep;
    });

    builder
      .addCase(verifyOtpAction.fulfilled, (state, action) => {
        if (action.payload) {
          state.verifyOtpData = action.payload.verifyOtpData;
          state.dataFetched = true;
          state.error = {};
          
          if (action.payload.verifyOtpData.twoFaActivationAt === null) {
            if (Cookie.get("token")) {
              redirectTo("admin/admin-management/profile");
            }
          } else {
            Cookie.set('twoFaActivationAt',action.payload.verifyOtpData.twoFaActivationAt)
            
            state.current = current.thirdStep;
          }
        }
      })
      .addCase(verifyOtpAction.rejected, (state, action) => {
        state.error = action.payload;
        state.dataFetched = false;
      });

    builder
      .addCase(verify2FAAction.fulfilled, (state, action) => {
        state.verify2FA.status = "idle";
        Cookie.set("verified",''+action.payload.verified)

      })
      .addCase(verify2FAAction.pending, (state) => {
        state.verify2FA.status = "pending";
      })
      .addCase(verify2FAAction.rejected, (state, action) => {
        state.verify2FA.status = "rejected";
        state.error = action.payload;
      });

    builder
      .addCase(signoutAction.fulfilled, (state, action) => {
        state.signOutData.status = "idle";
        logout();
      })
      .addCase(signoutAction.pending, (state) => {
        state.signOutData.status = "pending";
      })
      .addCase(signoutAction.rejected, (state, action) => {
        state.signOutData.status = "rejected";
        state.signOutData.error = action.payload;
      });
  },
});
export const { setCurrent } =  actions
export default reducer;
