import { Switch, Route, Redirect } from "react-router-dom";
import { Auth } from "../layout/Auth";
import AdminRoutes from "./AdminRoutes";
import { PrivateRoute } from "./PrivateRoute";
import Cookie from "js-cookie";

export const MasterRoutes = () => {
  return (
    <Switch>
      <PrivateRoute path={`/admin`} component={AdminRoutes} />
      <Route path={`/auth`} component={Auth} />
      {Cookie.get("token") ? (
        <Redirect from="*" to="/admin/admin-management/profile" />
      ) : (
        <>
          <Route path={`/`} component={Auth} />
        </>
      )}
    </Switch>
  );
};
export default MasterRoutes;
