import Cookies from "js-cookie";
import jwt from "jsonwebtoken";
import {
  EPermissionsValues,
  IDecodedToken,
  TEPermissionsUnion,
} from "../interfaces";
import { EPermissions } from "../enums/EPermissions";
import { EAdminRoles } from "../enums/EAdminRoles";
import { TPermissions } from "../interfaces/sharedComponents/IDecodedToken";

export function checkPermission(currentPage: TPermissions) {
  const token = Cookies.get("token");

  let isSuperAdmin = false;
  let hasPermission = false;
  let permissions: TPermissions[] = [];

  // const currentPageNum: TPermissions =
  //   typeof currentPage === "number" ? currentPage : EPermissions[currentPage];

  if (token) {
    const decodedToken = jwt.decode(token);
    const { permissions: tokenPermissions, role } =
      decodedToken as IDecodedToken;

    const omitAdminManagement = () =>
      tokenPermissions?.filter((perm) => perm !==  "admin-management");

    permissions = omitAdminManagement();
    isSuperAdmin = role === EAdminRoles.superAdmin && false;
    hasPermission =
      role === EAdminRoles.superAdmin
        ? true
        : permissions.includes(currentPage);
  }

  return { hasPermission, permissions, isSuperAdmin };
}
