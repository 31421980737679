import { lazy, Suspense } from "react";
import {
  Route,
  RouteComponentProps,
  Switch,
  useParams,
} from "react-router-dom";

import Users from "../features/user_management/pages/Users";
import  TopUsersPage  from "../features/user_management/pages/TopUsers";
import {GARoute} from './GARoute';
//import Detailes from '../features/user_management/components/Detailes';
const UserManagementRoutes = (props: RouteComponentProps) => {
  const { url } = props.match;
  return (
    <div>
      <Switch>
        <Route exact={true} path={`${url}/users`} component={Users} />
        <Route
          exact={true}
          path={`${url}/top-users`}
          component={TopUsersPage}
        />

        {/* <Route exact={true} path={`${url}/users/detailes`} component={Detailes}/> */}
      </Switch>
    </div>
  );
};

export default UserManagementRoutes;
