import { createAsyncThunk } from "@reduxjs/toolkit";
import { IValidationErrors } from "../../../../shared/interfaces/error";
import {getWithdrawSettingLogAPI} from "../../../../APIs/admin.api";
import {IWithdrawLogs} from "../../../../shared/interfaces/admin-management/IWithdrawLogs";

export const getWithdrawSettingLogAction = createAsyncThunk<
IWithdrawLogs, 
any,
{
    rejectValue : IValidationErrors
}
>("admin/withdrawlog" , async(params , thunkAPI)=>{
    try{
        return await getWithdrawSettingLogAPI({params : params})
        .then((response) =>{
            return response.data.data
        })
        .catch((error)=>{
            return thunkAPI.rejectWithValue({
                message : error.response.message,
                name: "admin/withdrawlog",
                code : error.response.status
            })
        })
    } catch(error : any){
        return thunkAPI.rejectWithValue({
            message : error.response.message,
            name: "admin/withdrawlog",
            code : error.response.status
        })
    }
})