import { createAsyncThunk } from "@reduxjs/toolkit";
import { IValidationErrors } from "../../../../shared/interfaces/error";
import { getPermissionsAPI } from "../../../../APIs/admin.api";
import { IGetPermissions } from "../../../../shared/interfaces/admin-management/IGetPermissions";


export const getPermissionsAction = createAsyncThunk<
  IGetPermissions[],
  void,
  {
    rejectValue: IValidationErrors;
  }
>("auth/permissions", async (_, thunkAPI) => {
  try {
    return await getPermissionsAPI()
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue({
          message: error.response.data.error,
          name: "auth/permissions",
          code: error.response.status,
        });
      });
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      message: error.response.message,
      name: "auth/permissions",
      code: error.response.status,
    });
  }
});
