import { useIdleTimer } from "react-idle-timer";
import { logout } from "./utils";
import Swal from "sweetalert2";
import { useAppDispatch } from "../../store";
import { signoutAction } from "../../features/authentication/actions/signoutAction";
export const noInteractionAlert = () => {
  Swal.fire({
    title: "No Activity",
    text: "You are being logged out due to inactivity.",
    icon: "warning",
    showConfirmButton: false,
    iconColor: "red",
  });
};
// @ts-nocheck
export const NoActivityLimiter = ({ children }: any) => {
  const promptTimeout = 3000;
  const timeout = 1000000;

  const onPrompt = () => {
    noInteractionAlert();
  };
  const dispatch = useAppDispatch();
  const onIdle = () => dispatch(signoutAction());

  const idleTimer = useIdleTimer({
    onIdle,
    onPrompt,
    promptTimeout,
    timeout,
    crossTab: true,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
    ],
    syncTimers: 30000,
  });

  return children;
};
