import { createAsyncThunk } from "@reduxjs/toolkit";
import { getMaintenanceablePagesActionAPI } from "../../../../APIs/maintenance.api";
import {
  MaintenanceablePagesParamsType,
  MaintenanceablePagesArrType,
} from "../../../../shared/interfaces";

export const getMaintenanceablePagesAction = createAsyncThunk<
  MaintenanceablePagesArrType,
  boolean | undefined,
  any
>("maintenance/get-maintenanceable-pages", async (maintenanceParams) => {
  try {
    const response = await getMaintenanceablePagesActionAPI(
      maintenanceParams === undefined
        ? undefined
        : {
            params: { active: maintenanceParams },
          }
    ).then((res) =>
      res.data.data.map((page: any) =>
        (({ controller: urlAddress, resource: name, active: status, id }) => ({
          urlAddress,
          name,
          status,
          id,
        }))(page)
      )
    );

    return response;
  } catch (error: any) {
    throw error;
  }
});
