import axios from "axios";
import { TokenClient } from "../shared/service/addTokenToReq";
import { requestInterceptor } from "../shared/service/useRequestInterceptor";

const adminAxios = axios.create({
  baseURL: `${process.env.REACT_APP_API_ROOT}/admin`,
});
requestInterceptor(adminAxios);
TokenClient(adminAxios);

export const getPermissionsAPI = () => adminAxios.get("/auth/permissions");

export const patchProfileAPI = (data: any) =>
  adminAxios.patch(`/auth/profile`, data);

export const getAdminListAPI = (params: any) =>
  adminAxios.get("/user/admin", params);

export const addNewAdminAPI = (data: any) =>
  adminAxios.post(`/auth/register`, data);

export const getAuthLoggerAPI = (params: any) =>
  adminAxios.get(`/logger/auth`, params);

export const patchPermissionsAPI = (data: any) =>
  adminAxios.patch(`/auth/admin`, data);

export const getMaintenanceLoggerAPI = (params: any) =>
  adminAxios.get(`/logger/maintenance`, params);

export const getDepositLoggerAPI = (params: any) =>
  adminAxios.get(`/logger/setting/deposit`, params);

export const getWithdrawalLoggerAPI = (params: any) =>
  adminAxios.get(`/logger/withdraw`, params);

export const getWithdrawSettingLogAPI = (params: any) =>
  adminAxios.get(`/logger/setting/withdraw`, params);

 export const profileLoggerAPI =(params: any)=> adminAxios.get(`/logger/setting/profile` , params) 
