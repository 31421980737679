import { createAsyncThunk } from "@reduxjs/toolkit";
import { IDepositSettingData } from "../../../../shared/interfaces/setting/index";
import { IValidationErrors } from "../../../../shared/interfaces/error";
import { getWalletAPI } from "../../../../APIs/setting.api";

export const getWalletAction = createAsyncThunk<
  IDepositSettingData[] | any,
  {} | undefined,
  {
    rejectValue: IValidationErrors;
  }
>("admin/getWallet", async (_, thunkAPI) => {
  try {
    return await getWalletAPI().then((response) => {
      return response.data.data;
    });
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      message: error.response.data.error,
      name: "admin/getWallet",
      code: error.response.status,
    });
  }
});
