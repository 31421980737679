import { Card } from "antd";
import { ExportToExcel } from "../downloadBTN/ExportToExcel";
import { ScrollableTable } from "./ScrollableTable";

export const ScrollableTableExcelled = (props: any) => {
  return (
    <Card
      {...props.cardProps}
      title={
        props.showTitle ? (
          props.showTitle
        ) : (
          <div>
            <ExportToExcel
              // {...props.excelIsActive}
              disabled={!props.excelIsActive}
              downloadButtonClass={`export-button ${
                props.ExportToExcelClass ? props.ExportToExcelClass : "ml-4"
              }`}
              onClick = {props.onClick}
            />
          </div>
        )
      }
    >
      <ScrollableTable {...props} />
    </Card>
  );
};
