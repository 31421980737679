import { createSlice } from "@reduxjs/toolkit";
import { initialData } from "./initialState";
import { getPermissionsAction } from "../actions/getPermissionsAction";
import { patchProfileAction } from "../actions/patchProfileAction";
import { getAdminListAction } from "../actions/getAdminListAction";
import { addNewAdminAction } from "../actions/addNewAdminAction";
import { patchPermissionsAction } from "../actions/patchPermissionsAction";
import { EStatuses } from "../../../../shared/enums/EStatuses";
import { getAuthLoggerAction } from "../actions/getAuthLoggerAction";
import {getMaintenanceLoggerAction} from "../actions/getMaintenanceLogger";
import {getDepositLoggerAction} from "../actions/getDepositLoggerAction";
import {getWithdrawalLoggerAction} from "../actions/getWithdrawalLoggerAction"
import {getWithdrawSettingLogAction} from "../actions/getWithdrawSettingLogAction";
import { profileLoggerAction } from "../actions/profileLoggerAction";

export const { actions, reducer } = createSlice({
  name: "admin",
  initialState: initialData,
  reducers: {},
  extraReducers: (builder) => {
    // get permissions
    builder
      .addCase(getPermissionsAction.fulfilled, (state, action) => {
        state.getPermissoins.status = EStatuses.idle;
        state.getPermissoins.data = action.payload;
      })
      .addCase(getPermissionsAction.pending, (state) => {
        state.getPermissoins.status = EStatuses.pending;
      })
      .addCase(getPermissionsAction.rejected, (state, action) => {
        state.getPermissoins.status = EStatuses.rejected;
        state.error = action.payload;
      });

    // patch profile
    builder
      .addCase(patchProfileAction.fulfilled, (state, action) => {
        state.patchProfileRes.status = EStatuses.idle;
        state.patchProfileRes.data = action.payload;
      })
      .addCase(patchProfileAction.pending, (state) => {
        state.patchProfileRes.status = EStatuses.pending;
      })
      .addCase(patchProfileAction.rejected, (state, action) => {
        state.patchProfileRes.status = EStatuses.rejected;
        state.error = action.payload;
      });

    // admin list
    builder
      .addCase(getAdminListAction.fulfilled, (state, action) => {
        state.getAdminList.status = EStatuses.idle;
        state.getAdminList.data = action.payload;
      })
      .addCase(getAdminListAction.pending, (state) => {
        state.getAdminList.status = EStatuses.pending;
      })
      .addCase(getAdminListAction.rejected, (state, action) => {
        state.getAdminList.status = EStatuses.rejected;
        state.error = action.payload;
      });

    // add new admin
    builder
      .addCase(addNewAdminAction.fulfilled, (state) => {
        state.addNewAdmin.status = EStatuses.idle;
      })
      .addCase(addNewAdminAction.pending, (state) => {
        state.addNewAdmin.status = EStatuses.pending;
      })
      .addCase(addNewAdminAction.rejected, (state, action) => {
        state.addNewAdmin.status = EStatuses.rejected;
        state.error = action.payload;
      });

    builder
      .addCase(patchPermissionsAction.fulfilled, (state, action) => {
        state.getAdminList.status = EStatuses.idle;
        const changedAdmin = state.getAdminList.data.admins.findIndex(
          (admin) => admin.id === action.payload.id
        );
        state.getAdminList.data.admins[changedAdmin] = action.payload;
      })
      .addCase(patchPermissionsAction.pending, (state) => {
        state.getAdminList.status = EStatuses.pending;
      })
      .addCase(patchPermissionsAction.rejected, (state, action) => {
        state.getAdminList.status = EStatuses.rejected;
        state.error = action.payload;
      });

      //Auth Logger
    builder
      .addCase(getAuthLoggerAction.fulfilled, (state, action) => {
        state.authLogger.status = "idle";
        state.authLogger.data = action.payload;
      })
      .addCase(getAuthLoggerAction.pending, (state) => {
        state.authLogger.status = "pending";
      })
      .addCase(getAuthLoggerAction.rejected, (state, action) => {
        state.authLogger.status = "rejected";
        state.error = action.payload;
      });

      // maintenance logger
      builder.addCase(getMaintenanceLoggerAction.fulfilled , (state , action)=>{
        state.maintenanceLogger.status = "idle"
        state.maintenanceLogger.data = action.payload
      })
      .addCase(getMaintenanceLoggerAction.pending , (state)=>{
        state.maintenanceLogger.status = "pending"
      }) 
      .addCase(getMaintenanceLoggerAction.rejected , (state , action)=>{
        state.maintenanceLogger.status = "rejected"
        state.error = action.payload
      })

      // deposit logger
      builder.addCase(getDepositLoggerAction.fulfilled , (state , action) =>{
        state.depositLogger.status = "idle"
        state.depositLogger.data = action.payload
      })
      .addCase(getDepositLoggerAction.pending , (state)=>{
        state.depositLogger.status = "pending"
      })
      .addCase(getDepositLoggerAction.rejected , (state , action) =>{
        state.depositLogger.status = "rejected"
        state.error = action.payload
      })

      // withdrawal logger
      builder.addCase(getWithdrawalLoggerAction.fulfilled , (state , action)=>{
        state.withdrawalLogger.status = "idle"
        state.withdrawalLogger.data = action.payload
      })
      .addCase(getWithdrawalLoggerAction.pending , (state)=>{
        state.withdrawalLogger.status ="pending"
      })
      .addCase(getWithdrawalLoggerAction.rejected , (state , action)=>{
        state.withdrawalLogger.status = "rejected"
        state.error = action.payload
      })

     // withdraw setting logger
     builder.addCase(getWithdrawSettingLogAction.fulfilled , (state , action)=>{
      state.withdrawSettinglog.status = "idle"
      state.withdrawSettinglog.data = action.payload
     })
     .addCase(getWithdrawSettingLogAction.pending , (state)=>{
      state.withdrawSettinglog.status = "pending"
     })
     .addCase(getWithdrawSettingLogAction.rejected , (state , action)=>{
      state.withdrawSettinglog.status = "rejected"
      state.error = action.payload
     })
     // profile logger
     builder.addCase(profileLoggerAction.fulfilled , (state , action) =>{
      state.profileLogger.status = "idle"
      state.profileLogger.data = action.payload
     })
     .addCase(profileLoggerAction.pending , (state)=>{
      state.profileLogger.status = "pending"
     })
     .addCase(profileLoggerAction.rejected , (state , action)=>{
      state.profileLogger.status = "rejected"
      state.error = action.payload
     })
  },
});
export default reducer;
