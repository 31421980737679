import { createAsyncThunk } from "@reduxjs/toolkit";
import { getWithdrawalAPI } from "../../../../APIs/withdrawal.api";
import { IGetWithdrawl } from "../../../../shared/interfaces/withdrawal/IWithdraw";
import { IValidationErrors } from "../../../../shared/interfaces/error";
import { TGetWithdrawl } from "../../../../shared/interfaces/withdrawal/TGetWithdrawl";

export const getWithdrawalAction = createAsyncThunk<
  TGetWithdrawl,
  any,
  {
    rejectValue: IValidationErrors;
  }
>("withdrawal/fetchWithdrawal", async (params, thunkAPI) => {
  try {
    return await getWithdrawalAPI({ params: params })
      .then((res) => {
        return res.data.data;
      })
      .catch((error: any) => {
        return thunkAPI.rejectWithValue({
          message: error.response.message,
          name: "withdrawal/fetchWithdrawal",
          code: error.response.status,
        });
      });
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      message: error.response.message,
      name: "withdrawal/fetchWithdrawal",
      code: error.response.status,
    });
  }
});
