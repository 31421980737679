import { createAsyncThunk } from "@reduxjs/toolkit";
import { patchStaticFeeAPI } from "../../../../APIs/setting.api";
import { IPatchStaticFee } from "../../../../shared/interfaces/setting/IPatchStaticFee";
import {IPatchStaticFeeRes} from "../../../../shared/interfaces/setting/IPatchStaticFeeRes";
import { IValidationErrors } from "../../../../shared/interfaces/error";

export const patchStaticFeeAction = createAsyncThunk<
  IPatchStaticFeeRes,
  IPatchStaticFee,
  {
    rejectValue: IValidationErrors;
  }
>("patch/static-fee", async (data, thunkAPI) => {
  try {
    return await patchStaticFeeAPI(data)
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue({
          message: error.response.message,
          name: "setting/staticFee",
          code: error.response.status,
        });
      });
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      message: error.response.message,
      name: "setting/staticFee",
      code: error.response.status,
    });
  }
});
