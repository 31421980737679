import { createAsyncThunk } from "@reduxjs/toolkit";
import { IValidationErrors } from "../../../../shared/interfaces/error";
import { getWithdrawByIdAPI } from "../../../../APIs/withdrawal.api";
import { IGetWithdrawById } from "../../../../shared/interfaces/withdrawal/IGetWithdrawById";
export const getWithdrawByIdAction = createAsyncThunk<
  IGetWithdrawById,
  any,
  {
    rejectValue: IValidationErrors;
  }
>("withdraw/withdraw:id", async (id , thunkAPI) => {
  try {
    return await getWithdrawByIdAPI(id)
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue({
          message: error.response.data.error,
          name: "withdraw/withdraw:id",
          code: error.response.status,
        });
      });
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      message: error.response.data.error,
      name: "withdraw/withdraw:id",
      code: error.response.status,
    });
  }
});
