import React , {lazy , Suspense} from 'react'
import { Loading } from '../../../shared/components/utils/Loading'
//import MainSetting from './MainSetting';
const MainSetting = lazy(() => import("../components/MainSetting"))
function MainSettingPage() {
  return (
    <div>
        <Suspense fallback={<Loading/>}>
        <MainSetting/>
        </Suspense>
    </div>
  )
}

export default MainSettingPage;