import { createSlice } from "@reduxjs/toolkit";
import downloadsAction from "../actions/downloadThunk";
import { getProfileAction } from "../actions/getProfileAction";
import { IStatus } from "../interfaces/IStatus";
import { IProfile } from "../interfaces/IProfile";

// const initialData: {
//   error: IValidationErrors | undefined;
//   userWallets: IWalletInfo[];
//   status: 'idle' | 'rejected' | 'pending';
//   dataFetched: boolean;
// } = {
//   error: {},
//   userWallets: [],
//   status: 'idle',
//   dataFetched: false,
// };
const initialData: {
  error: any | undefined;
  status: "idle" | "rejected" | "pending";
  dataFetched: boolean;
  loading: boolean;
  getProfile: {
    status: IStatus;
    data: IProfile;
  };
} = {
  error: {},
  status: "idle",
  dataFetched: false,
  loading: false,
  getProfile: {
    status: "idle",
    data: {
      email: "",
      nickname: "",
      twoFaActivationAt: null,
    },
  },
};

export const { actions , reducer } = createSlice({
  name: "shared",
  initialState: initialData,
  reducers: {
    setErrors(state, action) {},
    setLoading(state) {
      state.loading = !state.loading;
    },
    changeNickname(state, action){
      state.getProfile.data = action.payload
    },
    clearErrors(state) {
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    // FETCH USER WALLETS
    builder
      .addCase(downloadsAction.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload) {
          state.dataFetched = true;
          state.error = {};
        }
      })
      .addCase(downloadsAction.pending, (state) => {
        state.status = "pending";
        state.dataFetched = false;
      })
      .addCase(downloadsAction.rejected, (state, action) => {
        state.status = "rejected";
        state.dataFetched = false;
        state.error = action.payload;
      });

    // get profile

    builder
      .addCase(getProfileAction.fulfilled, (state, action) => {
        state.getProfile.status = "idle";
        state.getProfile.data = action.payload;
      })
      .addCase(getProfileAction.pending, (state) => {
        state.getProfile.status = "pending";
      })
      .addCase(getProfileAction.rejected, (state, action) => {
        state.getProfile.status = "rejected";
        state.error = action.payload;
      });
  },
});
// export const { increment, decrement, incrementByAmount } = actions;
export default reducer;
