import { createAsyncThunk } from "@reduxjs/toolkit";
import { IValidationErrors } from "../../../../shared/interfaces/error";
import { activityAPI, dashboardAPI } from "../../../../APIs/dashboard.api";
import { IActivity } from "../../../../shared/interfaces/dashboard/IActivity";
import { IActivityParams } from "../../../../shared/interfaces/dashboard/IActivityParams";
import { IGetDashboard } from "../../../../shared/interfaces/dashboard/IGetDashboard";

export const getDashboardAction = createAsyncThunk<
  IGetDashboard,
  void,
  {
    rejectValue: IValidationErrors;
  }
>("dashboard/dashboard", async (undefined, thunkAPI) => {
  try {
    return await dashboardAPI()
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue({
          message: error.response.message,
          name: "dashboard/activity",
          code: error.response.status,
        });
      });
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      message: error.response.message,
      name: "dashboard/activity",
      code: error.response.status,
    });
  }
});
