import { useCallback, useEffect, useState } from "react";
import UserTable from "../components/UsersTable";
import { getUserAction } from "../state/actions/getUserAction";
import { useAppDispatch } from "../../../store";
import type { TablePaginationConfig } from "antd/es/table";
import { TFetchUsers } from "../../../shared/interfaces/user/TFetchUsers";
interface TableParams {
  pagination?: TablePaginationConfig;
  // sortField?: string;
  // sortOrder?: string;
  // filters?: Record<string, FilterValue>;
}

const getRandomuserParams = (params: TableParams) => ({
  results: params.pagination?.pageSize,
  page: params.pagination?.current,
  ...params,
});

const Users = () => {
  const dispatch = useAppDispatch();
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 1,
    },
  });
  const [searchEmail, setsearchEmail] = useState("");
  const [searchWallet, setSearchWallet] = useState("");
  const [placement, setPlacement] = useState("EmailAddress");
  // const [pagination, setPagination] = useState({});

  const handleTableChange = async (pagination: TablePaginationConfig) => {
    setTableParams({
      pagination,
    });

    await dispatch(
      getUserAction({ page: pagination.current, limit: pagination.pageSize })
    );
  };

  const fetchData = useCallback(async () => {
    await dispatch(getUserAction({ page: 1 })).then(({ payload }: any) => {
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: payload.totalItems,
        },
      });
    });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const clickHandler = async () => {
    if (placement === "EmailAddress") {
      const searchUserByEmail = await dispatch(
        getUserAction({ email: searchEmail.trim() })
      );
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: (searchUserByEmail?.payload as TFetchUsers).totalItems,
        },
      });
    } else if (placement === "WalletAddress") {
      const searchUserByWallet = await dispatch(getUserAction({ walletAddress: searchWallet.trim() }));
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: (searchUserByWallet?.payload as TFetchUsers).totalItems,
        },
      });
    }
  };

  return (
    <div>
      {/* <FilterUsers /> */}

      <UserTable
        pagination={tableParams.pagination}
        onChange={handleTableChange}
        // onFinish = {onFinish}
        clickHandler={clickHandler}
        emailValue={searchEmail}
        walletValue={searchWallet}
        setsearchEmail={setsearchEmail}
        setSearchWallet={setSearchWallet}
        setPlacement={setPlacement}
        placement={placement}
      />
      {/* {status === "idle" ? <UserTable /> : <Loading />} */}
    </div>
  );
};
export default Users;
