// @flow
import { Card, Space, Table, Typography } from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  IPassChangesTableDataType,
  IPassChangesTableProps,
} from "../../../shared/interfaces";
import { useAppSelector, useAppDispatch } from "../../../store";
import {IPasswordLog , IGetUserById} from "../../../shared/interfaces/user/IUser"
import moment from "moment";
import { Loading } from "../../../shared/components/utils/Loading";
import { ScrollableTable } from "../../../shared/components/utils/ScrollableTable";


const { Title } = Typography;
const columns: ColumnsType<IPasswordLog> = [
  // {
  //   title: "Row",
  //   dataIndex: "key",
  //   key: "key",
  //   // render: (text) => <a>{text}</a>,
    
  // },
  {
    title: "Date",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (text) => <div>{moment(text).format('YYYY MMMM DD HH:MM:ss')}</div>,
  },
  {
    title: "IP",
    dataIndex: "ip",
    key: "ip",
  },
  {
    title: "User Agent",
    key: "userAgent",
    dataIndex: "userAgent",
    width: "20%",
    render(render) {
      return <p className="userAgentCell">{render}</p>;
    },
  },
];

export const PassChangesTable = () => {
  const { data , status } = useAppSelector((state) => state.user.passLog);
  // console.log("passChange" , data.passwordLog)
  return (
    <Card
      className="passChangeCard"
      title={<Title level={5}>Password changes:</Title>}
    >
      {/* {
        status === "idle"
      } */}
      <Table
        columns={columns}
        dataSource={data.passwordLog}
        className="ipTable"
        pagination={false}
        scroll={
          data.passwordLog.length > 5 ? { y: 300, x: "max-content" } : undefined
        }
      />
    </Card>
  );
};
