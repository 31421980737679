import { RouteProps, Redirect, Route } from "react-router-dom";
import { GAVerified } from "../shared/service/utils";
interface GARouterProps extends RouteProps {}

export const GARoute = (props: GARouterProps) => {
  return GAVerified() ? (
    <Route {...props} component={props.component} />
  ) : (
    <Redirect to="/auth" />
  );
};
