import { createAsyncThunk } from "@reduxjs/toolkit";
import { getPaidAPI } from "../../../../APIs/withdrawal.api";
import { IValidationErrors } from "../../../../shared/interfaces/error";
import { IPaid } from "../../../../shared/interfaces/withdrawal/IPaid";


export const getPaidAction = createAsyncThunk<
  IPaid,
  void,
  {
    rejectValue: IValidationErrors;
  }
>("withdrawal/paid", async (_, thunkAPI) => {
    try{
        return await getPaidAPI()
        .then((response) =>{
            return response.data.data
        })
        .catch((error) => {
            return thunkAPI.rejectWithValue({
              message: error.response.data.error,
              name: "withdrawal/paid",
              code: error.response.status,
            });
          });

    } catch (error: any) {
        return thunkAPI.rejectWithValue({
          message: error.response.data.error,
          name: "withdrawal/paid",
          code: error.response.status,
        });
      }
});
