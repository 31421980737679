import { createAsyncThunk } from "@reduxjs/toolkit";
import {verify2FAActionAPI} from "../../../APIs/auth.api";
import { IValidationErrors } from '../../../shared/interfaces/error';
import Cookie from "js-cookie";
export const verify2FAAction = createAsyncThunk<
{statusCode : number , verified : boolean} ,
{code : string},
{
    rejectValue: IValidationErrors;
}
>("admin/2fa" , async (twoFACode , thunkAPI) => {
    try{
        return await verify2FAActionAPI(twoFACode)
        .then((response) => {
         
            return{
                statusCode : response.data.statusCode ,
                verified : response.data.data.verified ,
            }
        })
    } catch(error : any){
        return  thunkAPI.rejectWithValue({
            message : error.response.data.error,
            name : "admin/get2FA",
            code: error.response.status
        })
    }
})