import { createAsyncThunk } from "@reduxjs/toolkit";
import { IValidationErrors } from "../interfaces";
import {IProfile} from "../interfaces/IProfile";
import {getProfileActionAPI} from "../../APIs/auth.api";

export const getProfileAction = createAsyncThunk<
IProfile | any,
void,
{
    rejectValue: IValidationErrors;
}
>
("admin/getProfile" , async(_ , thunkAPI)=>{
    try{
        return await getProfileActionAPI()
        .then((response)=>{
            return response.data.data
        })
        .catch((error) =>{
            return error
        })
    } catch(error : any){
        return  thunkAPI.rejectWithValue({
            message : error.response.data.error,
            name : "admin/get2FA",
            code: error.response.status
        })
    }
})