import { AxiosInstance } from "axios";
import { ajaxErrorAlert } from "../components/utils/alert";
import { logout } from "./utils";

// import { useAppDispatch } from "../store";

// import { signoutAction } from "./authentication";
// import { ajaxErrorAlert, logout } from "./utils";

export const requestInterceptor = (axiosinstance: AxiosInstance) => {
  // const dispatch = useAppDispatch();
  axiosinstance.interceptors.response.use(
    (Response) => Response,
    function (error) {
      let interceptedMessage =
        error.response.data.error || error.response.data.message;
      let errorStatus = error.response.status;
      switch (true) {
        case errorStatus === 400:
          console.log(error.response);
          interceptedMessage =
            error.response.data.error?.[0] || error.response.data.message;

          break;
        // case errorStatus > 400 && errorStatus < 500:
        //   console.log(error.response);
        //   interceptedMessage =
        //     error.response.data.error?.[0] || error.response.data.message;

        //   break;
        // case errorStatus === 406:
        //   console.log("error.response", error.response);
        //   interceptedMessage =
        //     error.response.data.error?.[0] || error.response.data.message;

        //   break;
        case errorStatus === 500:
          interceptedMessage =
            "Something went wrong on our side, please try again later.";
          break;
        default:
          interceptedMessage =
            error.response.data.error?.[0] || error.response.data.message;
      }

      error.interceptedMessage = interceptedMessage;
      // console.log("response err", interceptedMessage);
      ajaxErrorAlert(interceptedMessage).then(
        (something) => error.response.status === 401 && logout()
      );
      return Promise.reject(error);
    }
  );
};
