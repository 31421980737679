import { IStatus } from "./../../../shared/interfaces/IStatus";
import {
  I2FAData,
  IActivate2FA,
  IDepositSettingData,
  ICreateDepositResponse,
  IGetWithdrawSetting,
  IFetchCrypto,
  IpatchCryptoRes,
  IPatchCrypto,
} from "../../../shared/interfaces/setting/index";
import { IPatchStaticFeeRes } from "../../../shared/interfaces/setting/IPatchStaticFeeRes";
import { IValidationErrors } from "../../../shared/interfaces/error";
import { EStatuses } from "../../../shared/enums/EStatuses";
export const initialData: {
  twoFAResponse: {
    status: IStatus;
    data: I2FAData;
  };
  activate2FA: {
    status: IStatus;
    data: IActivate2FA;
  };
  getWallet: {
    status: IStatus;
    data: IDepositSettingData[];
  };
  createDepositResponse: {
    status: IStatus;
    data: ICreateDepositResponse;
  };
  staticFee: {
    dataFetched: boolean;
    status: IStatus;
    data: IGetWithdrawSetting[];
  };
  fetchCrypto: {
    status: IStatus;
    data: IFetchCrypto[];
  };
  patchCrypto: {
    status: IStatus;
  };
  patchCryptoRes: {
    data: IpatchCryptoRes;
  };
  patchStaticFee: {
    status: IStatus;
    data: IPatchStaticFeeRes;
  };
  updateWalletAction: { status: IStatus };
  error: IValidationErrors | undefined;
} = {
  twoFAResponse: {
    status: EStatuses.idle,
    data: {
      url: "",
    },
  },
  activate2FA: {
    status: EStatuses.idle,
    data: {
      verified: false,
    },
  },
  getWallet: {
    status: EStatuses.idle,
    data: [
      {
        cryptoPairId : 0,
        cryptoSymbol : "",
        networkName : "",
        isDepositAvailable: false,
        adminWallets: [
          {
            id: 0,
            address: "",
            limitationStatus: false,
            maxLimit: "",
          },
        ],
      },
    ],
  },
  createDepositResponse: {
    status: EStatuses.idle,
    data: {
      address: "",
      id: 0,
      limitationStatus: false,
      maxLimit: 0,
      cryptoNetwork: 0,
      balance: "",
      createdAt: "",
      updatedAt: "",
    },
  },
  updateWalletAction: {
    status: EStatuses.idle,
  },

  staticFee: {
    dataFetched: false,
    data: [],
    status: EStatuses.idle,
  },
  fetchCrypto: {
    data: [],
    status: EStatuses.idle,
  },
  patchCrypto: {
    status: EStatuses.idle,
  },
  patchCryptoRes: {
    data: {
      cryptoPairId: 0,
      commissionPercent: 0,
      networkName: null,
      cryptoName: "",
      cryptoSymbol: null,
      isWithdrawAvailable: true,
    },
  },
  patchStaticFee: {
    status: EStatuses.idle,
    data: {
      id: 0,
      level: "",
      commissionPercent: 0,
      maxAmount: 0,
    },
  },

  error: {},
};
