import { createAsyncThunk } from "@reduxjs/toolkit";
import { IValidationErrors } from "../../../../shared/interfaces/error";
import {getWithdrawalLoggerAPI} from "../../../../APIs/admin.api";
import {IWithdrawalLog} from "../../../../shared/interfaces/admin-management/IWithdrawalLog";

export const getWithdrawalLoggerAction = createAsyncThunk<
IWithdrawalLog,
any,
{
    rejectValue : IValidationErrors
}
>("admin/withdrawalLog" , async(params , thunkAPI)=>{
    try{
        return await getWithdrawalLoggerAPI({params : params})
        .then((response)=>{
            return response.data.data
        })
        .catch((error)=>{
            return thunkAPI.rejectWithValue({
                message : error.response.message,
                name : "admin/withdrawalLog",
                code : error.response.status
            })
        })

    } catch(error : any){
        return thunkAPI.rejectWithValue({
            message : error.response.message,
            name : "admin/withdrawalLog",
            code : error.response.status
        })
    }
})