import { createSlice } from "@reduxjs/toolkit";
import { initialData } from "./initialState";
import { getTransactionExcelFileAction } from "../actions/transactionExcelFileAction";
import { fetchTransactionAction } from "../actions/fetchTransactionsAction";

export const { actions, reducer } = createSlice({
  name: "transaction",
  initialState: initialData,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTransactionExcelFileAction.fulfilled, (state, action) => {
        state.transactionFile.data = action.payload;
        state.transactionFile.status = "idle";
      })
      .addCase(getTransactionExcelFileAction.pending, (state) => {
        state.transactionFile.status = "pending";
      })
      .addCase(getTransactionExcelFileAction.rejected, (state, action) => {
        state.transactionFile.status = "rejected";
      });
    builder
      .addCase(fetchTransactionAction.fulfilled, (state, action) => {
        state.fetchTransaction.status = "idle";
        state.fetchTransaction.data = action.payload;
      })
      .addCase(fetchTransactionAction.pending, (state) => {
        state.fetchTransaction.status = "pending";
      })
      .addCase(fetchTransactionAction.rejected, (state, action) => {
        state.fetchTransaction.status = "rejected";
      });
  },
});
export default reducer;
