import React, { useEffect } from "react";
import TopUsers from "../components/TopUser";
import { getTopUsersAction } from "../state/actions/getTopUsersAction";
import { ETopUserTypes } from "../../../shared/enums/ETopUserTypes";
import { useAppDispatch } from "../../../store";
const TopUsersPage = () => {
  const dispatch  = useAppDispatch();
  useEffect(() => {
    dispatch(
      getTopUsersAction({ page: 1, limit: 10, type: ETopUserTypes.invests })
    );
  }, []);

  return (
    <div>
      <TopUsers />
    </div>
  );
};
export default TopUsersPage;
