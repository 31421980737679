import { createAsyncThunk } from "@reduxjs/toolkit";
import { IValidationErrors } from "../../../../shared/interfaces/error";
import { checkoutAPI } from "../../../../APIs/withdrawal.api";
import { ICheckout } from "../../../../shared/interfaces/withdrawal/ICheckout";

export const getCheckoutAction = createAsyncThunk<
  ICheckout,
  void,
  {
    rejectValue: IValidationErrors;
  }
>("withdrawal/checkout", async (_, thunkAPI) => {
  try {
    return checkoutAPI()
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue({
          message: error.response.message,
          name: "withdrawal/checkout",
          code: error.response.status,
        });
      });
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      message: error.response.message,
      name: "withdrawal/checkout",
      code: error.response.status,
    });
  }
});
