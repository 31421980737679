import { useHistory } from "react-router-dom";
import { EPermissionsKeys, TEPermissionsUnion } from "../interfaces";
import { checkPermission } from "./chackPermission";
import { useEffect } from "react";
import { alwaysOpenPages, logs } from "./utils";
import { TPermissions } from "../interfaces/sharedComponents/IDecodedToken";

/**
 * redirect to dashboard if didn't have permission to the page.
 */
export const useRedirectToDashboard = () => {
  const {
    location: { pathname },
    push,
  } = useHistory();

  useEffect(() => {
    const isOpenPage = alwaysOpenPages.some((openPage) =>
      pathname.includes(openPage)
    );
    const currentSubPage = pathname.split("/").at(-1);
   
    const currentPage = pathname.slice(7).split("/").at(0) as TPermissions;

    const { hasPermission } = checkPermission(currentPage);

    const { hasPermission: hasPermissionToSubPage = hasPermission } =
      currentSubPage === logs ? checkPermission(currentSubPage) : {};


    if (isOpenPage) return;
    //admin list only for superAd

    if (!hasPermission) return push("/admin/admin-management/profile");
    if (!hasPermissionToSubPage) push("/admin/admin-management/profile");
  }, [pathname]);
};
