import { createAsyncThunk } from "@reduxjs/toolkit";
import { IValidationErrors } from "../../../../shared/interfaces/error";
import { addNewAdminAPI } from "../../../../APIs/admin.api";
import { IAddNewAdmin } from "../../../../shared/interfaces/admin-management/IAddNewAdmin";

export const addNewAdminAction = createAsyncThunk<
  unknown,
  IAddNewAdmin,
  {
    rejectValue: IValidationErrors;
  }
>("admin/newAdmin", async (data, thunkAPI) => {
  try {
    return await addNewAdminAPI(data)
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue({
          message: error.response.message,
          name: "admin/newAdmin",
          code: error.response.status,
        });
      });
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      message: error.response.message,
      name: "admin/newAdmin",
      code: error.response.status,
    });
  }
});
