import axios from "axios";
import Cookies from "js-cookie";
export const downloadExcel = (fileName: any) => {
  const token = Cookies.get("token");
  axios({
    method: "get",
    url: `${process.env.REACT_APP_EXCEL_FILEPATH}${fileName}`,
    headers: {
      "content-type":
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      Authorization: `Bearer ${token}`,
    },
    responseType: "blob",
  })
    .then((res) => {
      const blob = new Blob([res.data] , { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "excelFile";
      document.body.appendChild(link); // we need to append the element to the dom -> otherwise it will not work in firefox
      link.click();
      link.remove(); //afterwards we remove the element again
    })
    .catch(function (e) {
      console.log(e);
    });
};
