import axios from "axios";
import { TokenClient } from "../shared/service/addTokenToReq";
import { requestInterceptor } from "../shared/service/useRequestInterceptor";

const transactionAxios = axios.create({
  baseURL: `${process.env.REACT_APP_API_ROOT}/admin/transaction`,
});
requestInterceptor(transactionAxios);
TokenClient(transactionAxios);

export const fetchTransactionAPI = (params: any) => transactionAxios.get(`/transaction` , params);



export const transactionExcelAPI = () => transactionAxios.get(`/file`)

