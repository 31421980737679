import React from "react";
import OtpInput from "react-otp-input";
import { Form } from "antd";
function GAForm({ value, onChange , onKeyPress }: any) {
  const [form] = Form.useForm();
  return (
    <div className="login_verifyInputs">
      <Form form={form} onKeyPress = {onKeyPress}>
      <Form.Item>
        <OtpInput
          className="Input_otp"
          value={value}
          numInputs={6}
          separator={<span> </span>}
          onChange={onChange}
          inputStyle={"otpInput"}
        />
      </Form.Item>
      </Form>
    </div>
  );
}

export default GAForm;
