import { createAsyncThunk } from "@reduxjs/toolkit";
import { getTopUserExcelAPI } from "../../../../APIs/user.api";
import { IValidationErrors } from "../../../../shared/interfaces/error";
import {ITopUserExcelParams} from "../../../../shared/interfaces/user-management/topUsers/ITopUserExcelParams";
import {ITopUserExcel} from "../../../../shared/interfaces/user-management/topUsers/ITopUserExcel";

export const getTopUserExcelAction = createAsyncThunk<
ITopUserExcel,
ITopUserExcelParams,
  {
    rejectValue: IValidationErrors;
  }
>("topUser/Excel", async (type, thunkAPI) => {
  try {
    return await getTopUserExcelAPI({type : type})
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue({
          message: error.response.data.error,
          name: "topUser/Excel",
          code: error.response.status,
        });
      });
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      message: error.response.message,
      name: "transaction/file",
      code: error.response.status,
    });
  }
});
