// @ts-nocheck
import {
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Table,
  Typography,
  Card,
  Switch,
} from "antd";
import React, { useState, useCallback, useEffect } from "react";
import { IStaticFee } from "../../../../shared/interfaces/user";
import { currencyFormat } from "../../../../shared/service/utils";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { IGetWithdrawSetting } from "../../../../shared/interfaces/setting/index";
import { getStaticFeeAction } from "../../actions/withdrawSetting-Actions/getStaticFeeAction";
import { patchStaticFeeAction } from "../../actions/withdrawSetting-Actions/patchStaticFeeAction";
import {
  ajaxErrorAlert,
  ajaxSuccessAlert,
  ajaxWarningAlert,
} from "../../../../shared/components/utils/alert";
import { Loading } from "../../../../shared/components/utils/Loading";

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text";
  record: IStaticFee;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === "number" ? (
      <InputNumber />
    ) : (
      <div className="inputEdit_wrapper">
        <Input className="inputEdit" maxLength={3} />
      </div>
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          className="inputEdit_wrapper"
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please enter commission percent`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const WithdrawStaticfee: React.FC = () => {
  const [form] = Form.useForm();

  const [editingKey, setEditingKey] = useState("");

  const isEditing = (record: IGetWithdrawSetting) => record.id === editingKey;
  const dispatch = useAppDispatch();
  const { staticFee } = useAppSelector((state) => state.setting);

  const edit = (record: Partial<IGetWithdrawSetting> & { key: React.Key }) => {
    form.setFieldsValue({ name: "", age: "", address: "", ...record });
    setEditingKey(record.id);
  };

  const dataFetch = useCallback(() => {
    dispatch(getStaticFeeAction());
    // .then((res) => {
    //   console.log("res.meta.requestStatus", res);
    //   if (res.meta.requestStatus === "rejected") {
    //     ajaxErrorAlert("Error");
    //   }
    // })
    // .catch((error) => {
    //   if (error) {
    //     ajaxErrorAlert("Error");
    //   }
    // });
  }, [dispatch]);

  useEffect(() => {
    dataFetch();
  }, [dataFetch]);
  const [data, setData] = useState(staticFee.data);

  const addNewData = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as IGetWithdrawSetting;

      const newData = [...data];
      const index = newData.findIndex((item) => key === item.id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const onFinish = (values) => {
    const staticFeeData = {
      commissionPercent: Number(values.commissionPercent) / 100,
      id: Number(editingKey),
    };
    dispatch(patchStaticFeeAction(staticFeeData)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        ajaxSuccessAlert("Static-Fee Setting Upadated Successfully");
      }
    });
    setEditingKey("");
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async () => {
    form.submit();
  };

  const columns = [
    {
      title: <p className="settingWithdraw_ColTable">Fee Level</p>,
      dataIndex: "level",
      width: "10%",
      render: (text: any) => (
        <div className="settingWithdraw_ColTable">{text}</div>
      ),
      editable: false,
    },
    {
      title: <p className="settingWithdraw_ColTable">Up To</p>,
      dataIndex: "maxAmount",
      width: "40%",
      render: (text: any) => (
        <div className="settingWithdraw_ColTable">
          {text != 0 ? (
            currencyFormat(text)
          ) : (
            <div className="settingWithdraw_ColTable">Unlimited</div>
          )}
        </div>
      ),
      editable: false,
    },
    {
      title: <p className="settingWithdraw_ColTable">Commission Percent</p>,
      dataIndex: "commissionPercent",
      width: "40%",
      render: (text: any) => (
        <div className="settingWithdraw_ColTable">{+(text * 100).toFixed()}%</div>
      ),
      editable: true,
    },
    {
      title: <p className="settingWithdraw_ColTable">Operation</p>,
      dataIndex: "operation",
      render: (_: any, record: IGetWithdrawSetting) => {
        const editable = isEditing(record);
        return editable ? (
          <p className="settingWithdraw_ColTable">
            <Typography.Link
              onClick={() => save(record.id)}
              style={{ marginRight: 8 }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </p>
        ) : (
          <p className="settingWithdraw_ColTable">
            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              Edit
            </Typography.Link>
          </p>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: IGetWithdrawSetting) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <div className="settingWithdraw_wrapper">
      {staticFee.dataFetched ? (
        <Card className="settingWithdraw_STATICFEE">
          <Form form={form} component={false} onFinish={(e) => onFinish(e)}>
            <Table
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              dataSource={staticFee.data}
              columns={mergedColumns}
              rowClassName="editable-row"
              pagination={false}
            />
          </Form>
        </Card>
      ) : (
        <Loading />
      )}

      {/* {
        !staticFee.dataFetched && ajaxErrorAlert("HIII")
      } */}
    </div>
  );
};

export default WithdrawStaticfee;
