import { createAsyncThunk } from "@reduxjs/toolkit";
import { activate2FAAPI } from "../../../../APIs/setting.api";
import { IValidationErrors } from '../../../../shared/interfaces/error';
import {IActivate2FA} from "../../../../shared/interfaces/setting/index";

export const activate2FAAction = createAsyncThunk<
{verified : IActivate2FA} | any,
{code : string} | undefined ,
{
    rejectValue: IValidationErrors;
} 
>("user/2fa" , async (twoFACode , thunkAPI)=>{
    try{
        return await activate2FAAPI(twoFACode)
        .then((res)=>{
            return {
                verified : res.data.data["verified"]
            }
        })
    } catch(error : any){
        return thunkAPI.rejectWithValue({
            message: error.response.data.error,
            name: "user/2fa",
            code: error.response.status,
          });
    }
})