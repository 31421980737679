import { createAsyncThunk } from "@reduxjs/toolkit";
import { IValidationErrors } from "../../../../shared/interfaces/error";
import {getAuthLoggerAPI} from "../../../../APIs/admin.api";
import {IAuthLogger} from "../../../../shared/interfaces/admin-management/IAuthLogger";

export const getAuthLoggerAction = createAsyncThunk<
IAuthLogger,
any,
{
    rejectValue: IValidationErrors;
}
>("admin/AuthLog" , async(params , thunkAPI)=>{
    try{
        return await getAuthLoggerAPI({params : params})
        .then((response) =>{
            return response.data.data
        })
        .catch((error) =>{
            return  thunkAPI.rejectWithValue({
                message : error.response.message,
                name : "admin/AuthLog",
                code : error.response.status
            })
        })

    } catch(error : any) {
        return thunkAPI.rejectWithValue({
            message : error.response.message,
            name : "admin/AuthLog",
            code : error.response.status
        })
    }
})