import { createAsyncThunk } from "@reduxjs/toolkit";
import { IValidationErrors } from "../../../../shared/interfaces/error";
import { getUserAPI } from "../../../../APIs/user.api";
import {
  IFetchUser,
  IParamsUserType,
} from "../../../../shared/interfaces/user/IUser";
import { TFetchUsers } from "../../../../shared/interfaces/user/TFetchUsers";
export const getUserAction = createAsyncThunk<
  TFetchUsers,
  undefined | IParamsUserType,
  {
    rejectValue: IValidationErrors;
  }
>("user/user", async (params, thunkAPI) => {
  try {
    return await getUserAPI({ params })
      .then((res) => {
        return res.data.data;
      })
      .catch((error: any) => {
        return thunkAPI.rejectWithValue({
          message: error.response.data.error,
          name: "user/user",
          code: error.response.status,
        });
      });
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      message: error.response.data.error,
      name: "user/user",
      code: error.response.status,
    });
  }
});
