import { FC, ReactNode } from "react";
import { useAppDispatch } from "../../../store";
import downloadsAction from "../../actions/downloadThunk";
import { IDownloadButton } from "../../interfaces";
import { CostumButton } from "../utils/Button";
// import { downloadsAction } from '../../features/downloads';
// import { useAppDispatch } from '../../store';
// import {Button} from './Button';

// a component for download button

export const DownloadButton: FC<IDownloadButton> = (props) => {
  const dispatch = useAppDispatch();

  return (
    <CostumButton
      {...props}
      // onClick={() => dispatch(downloadsAction(props.payload))}
      onClick={props.onClick}
    >
      {props.children ? props.children : ""}
    </CostumButton>
  );
};
