import { createAsyncThunk } from "@reduxjs/toolkit";
import { ICreateDepositData } from "../../../../shared/interfaces/setting/index";
import { IValidationErrors } from "../../../../shared/interfaces/error";
import { createWalletAPI } from "../../../../APIs/setting.api";
import { ICreateWalletResponse } from "../../../../shared/interfaces/setting/IDepositSetting";

export const createWalletAction = createAsyncThunk<
  ICreateWalletResponse,
  ICreateDepositData,
  {
    rejectValue: IValidationErrors;
  }
>("admin/createWallet", async (walletData, thunkAPI) => {
  try {
    return await createWalletAPI(walletData).then((response) => {
      return response.data.data;
    });
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      message: error.response.data.error,
      name: "admin/createWallet",
      code: error.response.status,
    });
  }
});
