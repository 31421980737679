// @ts-nocheck
import { useState , useRef , useEffect , useCallback } from "react";
import { Form } from "antd";
import OtpInput from "react-otp-input";
import Countdown, { zeroPad } from "react-countdown";
import { TimerIcon } from "../../../assets/icons";
const VerifyForm = ({ onChange, value, onKeyPress , render }: any) => {
  const [form] = Form.useForm();
  return (
    <div>
      <div className="login_verifyInputs">
        <Form form={form} onKeyPress = {onKeyPress}>
        <Form.Item>
          <OtpInput
            //shouldAutoFocus={true}
            className="Input_otp"
            value={value}
            numInputs={6}
            separator={<span> </span>}
            onChange={onChange}
            inputStyle={"otpInput"}
          />
        </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default VerifyForm;
