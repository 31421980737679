import React from "react";
import { Card, Space, Table, Typography } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useAppSelector, useAppDispatch } from "../../../store";
import moment from "moment";
import { IPasswordLog } from "../../../shared/interfaces/user/IPasswordLog";
import { CostumButton } from "../../../shared/components/utils/Button";
import ChangeEmailModal from "../../../shared/components/utils/ChangeEmailModal";
import { IEmailLog } from "../../../shared/interfaces/user/IEmailLog";
const { Title } = Typography;
const columns: ColumnsType<IPasswordLog> = [
  {
    title: "Old Email",
    dataIndex: "oldEmail",
    key: "oldEmail",
    render: (text) => <div>{text}</div>,
  },
  {
    title: "New Email",
    dataIndex: "newEmail",
    key: "newEmail",
    render: (text) => <div>{text}</div>,
  },
  {
    title: "Date",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (text) => <div>{moment(text).format("YYYY MMMM DD HH:MM:ss")}</div>,
  },
  {
    title: "IP",
    dataIndex: "ip",
    key: "ip",
  },
  {
    title: "User Agent",
    key: "userAgent",
    dataIndex: "userAgent",
    width: "20%",
    render(render) {
      return <p className="userAgentCell">{render}</p>;
    },
  },
];
function ChangeEmailTable({id} : any) {
  const { data, status } = useAppSelector((state) => state.user.passLog);
  return (
    <Card
      className="passChangeCard"
      title={<Title level={5}>Email changes:</Title>}
    >
      <div className="changeEmail-button">
        <ChangeEmailModal id={id} />
      </div>
      <Table
        pagination={false}
        columns={columns}
        dataSource={data.emailLogs ?? null}
        className="ipTable"
        scroll={
          data.emailLogs.length > 5 ? { y: 300, x: "max-content" } : undefined
        }
      />
    </Card>
  );
}

export default ChangeEmailTable;
