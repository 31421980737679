import { createAsyncThunk } from "@reduxjs/toolkit";
import { getStaticFeeAPI } from "../../../../APIs/setting.api";
import { IGetWithdrawSetting } from "../../../../shared/interfaces/setting/index";
import { IValidationErrors } from "../../../../shared/interfaces/error";

export const getStaticFeeAction = createAsyncThunk<
  IGetWithdrawSetting[],
  void,
  {
    rejectValue: IValidationErrors;
  }
>("setting/staticFee", async (_, thunkAPI) => {
  try {
    return await getStaticFeeAPI()
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue({
          message: error.response.message,
          name: "setting/staticFee",
          code: error.response.status,
        });
      });
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      message: error.response.message,
      name: "setting/staticFee",
      code: error.response.status,
    });
  }
});
