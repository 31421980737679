import { createSlice } from "@reduxjs/toolkit";

import { maintenanceInitialState } from "./maintenance-initialState";
import { getMaintenanceablePagesAction } from "./actions/getMaintenanceablePagesAction";
import { patchMaintenancePagesAction } from "./actions/patchMaintenancePagesAction";
import _, { remove } from "lodash";

export const { actions, reducer } = createSlice({
  name: "maintenance",
  initialState: maintenanceInitialState,
  reducers: {
    setSelectedPage(state, action) {
      state.selectedPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getMaintenanceablePagesAction.fulfilled,
        (state, { payload }) => {
          state.maintenanceablePages.status = "idle";

          const activeTMP: typeof state.maintenanceablePages.active = [];
          const underMaintenanceTMP: typeof state.maintenanceablePages.underMaintenance =
            [];

          payload.forEach((page) => {
            page.status ? activeTMP.push(page) : underMaintenanceTMP.push(page);
          });

          state.maintenanceablePages.active = activeTMP;
          state.maintenanceablePages.underMaintenance = underMaintenanceTMP;
        }
      )
      .addCase(getMaintenanceablePagesAction.pending, (state, action) => {
        state.maintenanceablePages.status = "pending";
      })
      .addCase(getMaintenanceablePagesAction.rejected, (state, action) => {
        state.maintenanceablePages.status = "rejected";
      });

    builder
      .addCase(patchMaintenancePagesAction.fulfilled, (state, { payload }) => {
        state.maintenanceablePages.status = "idle";

        if (payload !== undefined) {
          let newChangedPage = payload[0];
          state.selectedPage = undefined;

          if (newChangedPage.status) {
            state.maintenanceablePages.active.push(newChangedPage);
            remove(
              state.maintenanceablePages.underMaintenance,
              (page) => newChangedPage.id === page.id
            );
          }
          if (!newChangedPage.status) {
            state.maintenanceablePages.underMaintenance.push(newChangedPage);
            remove(
              state.maintenanceablePages.active,
              (page) => newChangedPage.id === page.id
            );
          }

          // const activeTMP: typeof state.maintenanceablePages.active = [];
          // const underMaintenanceTMP: typeof state.maintenanceablePages.underMaintenance =
          //   [];

          // payload?.forEach((page) => {
          //   page.status ? activeTMP.push(page) : underMaintenanceTMP.push(page);
          // });

          // state.maintenanceablePages.active = activeTMP;
          // state.maintenanceablePages.underMaintenance = underMaintenanceTMP;
        }
      })
      .addCase(patchMaintenancePagesAction.pending, (state, action) => {
        state.maintenanceablePages.status = "pending";
      })
      .addCase(patchMaintenancePagesAction.rejected, (state, action) => {
        state.maintenanceablePages.status = "rejected";
      });
  },
});

export default reducer;
