import { IStatus } from "../../../../shared/interfaces/IStatus";
import { IGetWithdrawl } from "../../../../shared/interfaces/withdrawal/IWithdraw";
import { IPostWithdrawalRes } from "../../../../shared/interfaces/withdrawal/IPostWithdrawalType";
import { IGetWithdrawById } from "../../../../shared/interfaces/withdrawal/IGetWithdrawById";
import { IValidationErrors } from "../../../../shared/interfaces/error";
import { TGetWithdrawl } from "../../../../shared/interfaces/withdrawal/TGetWithdrawl";
import {IPaid} from "../../../../shared/interfaces/withdrawal/IPaid";
import {IWithdrawalExcel} from "../../../../shared/interfaces/withdrawal/IWithdrawalExcel";
import { ICheckout } from "../../../../shared/interfaces/withdrawal/ICheckout";

interface ITxId {
  txid : string;
  id : number;
  key : number
}

export const initialData: {
  responseWithdrawal: {
    status: IStatus;
    data?: TGetWithdrawl;
  };
  postWithdrwal: {
    status: IStatus;
  };
  paid :{
    data : IPaid
    status : IStatus
  }
  witdrawalExcel :{
    data :IWithdrawalExcel
    status : IStatus 
  }
  postWithdrawalRes: IPostWithdrawalRes;
  error: IValidationErrors | undefined;
  withdrawalDataByID: {
    data: IGetWithdrawById;
    status: IStatus;
  };
  payment : {
    status : IStatus
  }
  checkout :{
    data : ICheckout
    status : IStatus
  }
  txIDSet : ITxId
} = {
  responseWithdrawal: {
    status: "idle",
  },
  error: {},
  postWithdrwal: {
    status: "idle",
  },
  postWithdrawalRes: { id: 0 },
  withdrawalDataByID: {
    data: {
      totalInvestments: "",
      totalDownlineInvestments: 0,
      totalDownlinesDeposits: 0,
    },

    status: "idle",
  },
  paid : {
    data : [], 
    status : "idle"
  } ,
  witdrawalExcel :{
    data :{
      filepath : ""
    } , 
    status : "idle"
  } ,
  payment :{
    status : "idle"
  } ,
  checkout : {
  data : [],
  status : "idle"
  } , 
  txIDSet : {
    id : 0,
    key : 0,
    txid : ""
  }
};
