import Countdown, { zeroPad } from "react-countdown";
import { TimerIcon } from "../../../assets/icons";

function CountDown({ complete, render }: any) {
  const renderer = ({ minutes, seconds, completed }: any) => {
    //setRender(true);
    if (completed) {
      // Render a complete state
      return complete();
    } else {
      // Render a countdown
      return (
        <span className="counter">
          <div className="timerIcon">
            <TimerIcon />
          </div>
          {zeroPad(minutes)}:{zeroPad(seconds)}
        </span>
      );
    }
  };

  const clickHandler = ({ minutes, seconds }: any) => {
    return (
      <span className="counter">
        <div className="timerIcon">
          <TimerIcon />
        </div>
        {zeroPad(minutes)}:{zeroPad(seconds)}
      </span>
    );
  };

  return (
    <div>
      {!render && <Countdown date={Date.now() + 120000} renderer={renderer} />}
      {render && (
        <Countdown date={Date.now() + 120000} renderer={clickHandler} /> 
      )}
    </div>
  );
}

export default CountDown;
