import { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { Layout, Affix } from "antd";
import Sidenav from "./components/Sidenav";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { useAppDispatch } from "../store";
import { getProfileAction } from "../shared/actions/getProfileAction";
import _ from "lodash";
import Title from "../shared/components/utils/Title";

const { Header: AntHeader, Content, Sider } = Layout;

function Main({ children }: any) {
  const [sidenavColor, setSidenavColor] = useState("#003247");
  const [sidenavType, setSidenavType] = useState("transparent");
  const [fixed, setFixed] = useState(false);

  const handleSidenavType = ({ type }: any) => setSidenavType(type);
  const handleSidenavColor = ({ color }: any) => setSidenavColor(color);
  const handleFixedNavbar = ({ type }: any) => setFixed(type);

  let { pathname } = useLocation();
  pathname = pathname.replace("/", "");
  const arrayPathname = pathname.split("/");
  const breadCrumbpath = arrayPathname.join("/");
  const dispatch = useAppDispatch();

  const getProfileFetch = useCallback(async () => {
    await dispatch(getProfileAction());
  }, []);

  useEffect(() => {
    getProfileFetch();
  }, []);

  return (
    <>
      <Layout
        className={`layout-dashboard ${
          pathname === "profile" ? "layout-profile" : ""
        } ${pathname === "rtl" ? "layout-dashboard-rtl" : ""}`}
      >
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          onCollapse={(collapsed, type) => {
            // console.log(collapsed, type);
          }}
          trigger={null}
          width={250}
          theme="light"
          className={`sider-primary ant-layout-sider-primary ${
            sidenavType === "#fff" ? "active-route" : ""
          }`}
          style={{ background: sidenavType }}
        >
          <Sidenav color={sidenavColor} />
        </Sider>
        <Layout>
          {fixed ? (
            <Affix>
              <AntHeader className={`${fixed ? "ant-header-fixed" : ""}`}>
                <Header
                  name={pathname}
                  subName={pathname}
                  href={breadCrumbpath}
                  handleSidenavColor={handleSidenavColor}
                  handleSidenavType={handleSidenavType}
                  handleFixedNavbar={handleFixedNavbar}
                />
              </AntHeader>
            </Affix>
          ) : (
            <AntHeader className={`${fixed ? "ant-header-fixed" : ""}`}>
              <Header
                name={pathname}
                subName={pathname}
                href={breadCrumbpath}
                handleSidenavColor={handleSidenavColor}
                handleSidenavType={handleSidenavType}
                handleFixedNavbar={handleFixedNavbar}
              />
            </AntHeader>
          )}
          <Content className="content-ant">
            <Title />
            {children}
          </Content>
          <Footer />
        </Layout>
      </Layout>
    </>
  );
}

export default Main;
