import React from "react";
import { WithdrawSettingCard } from "../components/settingWithdraw/WithdrawSetting";
const WithdrawSetting = () => {
  return (
    <div>
      <WithdrawSettingCard />
    </div>
  );
};

export default WithdrawSetting;
