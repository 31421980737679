import { Card, Table, Tag} from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import  { useState, useCallback, useEffect } from "react";
import { ScrollableTable } from "../../../shared/components/utils/ScrollableTable";
import { IUsersData } from "../../../shared/interfaces/user";
import { dataUsers, passchageser } from "../../../shared/service/utils";
import FilterUsers from "./FilterUsers";
import { truncate } from "lodash";
import { PassChangesTable } from "./PassChangesTable";
import { GraphicCopyToClipboard } from "../../../shared/components/utils/GraphicCopyToClipboard";
import { useAppSelector, useAppDispatch } from "../../../store";
import { Loading } from "../../../shared/components/utils/Loading";
import { getUserAction } from "../state/actions/getUserAction";
import { IFetchUser } from "../../../shared/interfaces/user/IUser";
import { getUserByIdAction } from "../state/actions/getUserByIdAction";
import { currencyFormat } from "../../../shared/service/utils";
import type { RadioChangeEvent } from "antd";
import { CaretDownOutlined, CaretRightOutlined } from "@ant-design/icons";
import { ColumnGroupType } from "antd/lib/table";
import ChangeEmailTable from "./ChangeEmailTable";
import LogsTable from "./LogsTable";

// const ExpandableIcon = ({  key }: any) => {
//   const [expanded, setExpanded] = useState(false);
//   const handleClick = () => {
//     setExpanded(!expanded);
//     console.log("expanded", expanded);
//     // if(expanded){
//     //   return (
//     //     <PassChangesTable/>
//     //   )
//     // }
//     expandClickHandler()
//   };

//   return expanded ? (
//     <>
//     <button
//       key={key}
//       type="button"
//       className="ant-table-row-expand-icon ant-table-row-expand-icon-expanded"
//       aria-label="Collapse row"
//       aria-expanded="true"
//       onClick={handleClick}
//     ></button>
//     <PassChangesTable/>
//     </>
//   ) : (
//     <button
//       type="button"
//       key={key}
//       className="ant-table-row-expand-icon ant-table-row-expand-icon-collapsed"
//       aria-label="Expand row"
//       aria-expanded="false"
//       onClick={handleClick}
//     ></button>
//   );
// };

const UserTable = ({
  pagination,
  onChange,
  onFinish,
  searchEmail,
  searchWallet,
  setsearchEmail,
  setSearchWallet,
  placement,
  setPlacement,
  clickHandler
}: any) => {
  // const [searchEmail, setsearchEmail] = useState("");
  // const [searchWallet, setSearchWallet] = useState("");
  // const [placement, setPlacement] = useState("EmailAddress");
  const { data, status } = useAppSelector((state) => state.user.fetchUser);
  const [id , setId] = useState(0)
  // const [expanded, setExpanded] = useState(false);
  let newData = data?.users.map((e: any) => ({
    ...e,
    key: e.id,
    passchages: passchageser(2),
  }));

  const expandClickHandler = async (expanded: any, record?: any) => {
    if (expanded) {
      await dispatch(getUserByIdAction(record.id));
    }
    setId(record.id)
  };

  const ExpandableIcon = ({ key }: any) => {
    const [expanded, setExpanded] = useState(false);
    const handleClick = () => {
      setExpanded(!expanded);
      // console.log("expanded", expanded);
    };

    return expanded ? (
      <>
        <button
          key={key}
          type="button"
          className="ant-table-row-expand-icon ant-table-row-expand-icon-expanded"
          aria-label="Collapse row"
          aria-expanded="true"
          onClick={handleClick}
        ></button>
      </>
    ) : (
      <button
        type="button"
        key={key}
        className="ant-table-row-expand-icon ant-table-row-expand-icon-collapsed"
        aria-label="Expand row"
        aria-expanded="false"
        onClick={handleClick}
      ></button>
    );
  };

  // const columns: ColumnGroupType<IFetchUser> | ColumnsType<IFetchUser> = [
  //   {
  //     title: "Nickname",
  //     dataIndex: "nickname",
  //     key: "nickname",
  //     render: (text) => <a>{text}</a>,
  //     width: "11%",
  //   },

  //   {
  //     title: "Email",
  //     dataIndex: "email",
  //     key: "email",
  //     width: "11%",
  //     render: (email, record) => {
  //       return (
  //         <>
  //           <div className="changeEmail">
  //             <GraphicCopyToClipboard text={email}>
  //               <span>{truncate(email, { length: 18 })}</span>
  //             </GraphicCopyToClipboard>
  //             {/* <ExpandableIcon
  //               // onExpand={setExpanded}
  //               // expanded={expanded}
  //               key={record.id}
  //             /> */}
  //           </div>
  //         </>
  //       );
  //     },
  //   },
  //   {
  //     title: "Wallet Address",
  //     dataIndex: "walletAddress",
  //     key: "walletAddress",
  //     width: "11%",
  //     render: (wallet) => (
  //       <GraphicCopyToClipboard text={wallet}>
  //         <span>{truncate(wallet, { length: 18 })}</span>
  //       </GraphicCopyToClipboard>
  //     ),
  //   },
  //   {
  //     title: "Investments",
  //     dataIndex: "investments",
  //     key: "investments",
  //     width: "11%",
  //     render: (text) => <div>{currencyFormat(text)}</div>,
  //     align: "center",
  //   },
  //   {
  //     title: "Referrals",
  //     key: "activeDirectRefarrals",
  //     dataIndex: "activeDirectRefarrals",
  //     render: (text) => <div>{text}</div>,
  //     width: "11%",
  //     align: "center",
  //   },
  //   {
  //     title: "Invest Earnings",
  //     key: "investEarnings",
  //     dataIndex: "investEarnings",
  //     width: "11%",
  //     render: (text) => <div>{currencyFormat(text)}</div>,
  //     align: "center",
  //   },
  //   {
  //     title: "Downlines",
  //     key: "downlines",
  //     dataIndex: "downlines",
  //     width: "11%",
  //     align: "center",
  //   },
  //   {
  //     title: "Upline",
  //     key: "upline",
  //     dataIndex: "upline",
  //     width: "11%",
  //     align: "center",
  //   },
  //   {
  //     title: "Affiliate Earnings",
  //     key: "affiliateEarnings",
  //     dataIndex: "affiliateEarnings",
  //     width: "11%",
  //     render: (text) => <div>{currencyFormat(text)}</div>,
  //     align: "center",
  //   },
  //   {
  //     title: "withdrawals",
  //     key: "withdrawals",
  //     dataIndex: "withdrawals",
  //     width: "11%",
  //     render: (text) => <div>{currencyFormat(text)}</div>,
  //     align: "center",
  //   },
  //   Table.EXPAND_COLUMN,
  // ];


  const getColumnData : any = ()=>{
    const columns = [
      {
        title: "Nickname",
        dataIndex: "nickname",
        key: "nickname",
        render: (text : string) => <a>{text}</a>,
        width: "11%",
      },
  
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        width: "11%",
        render: (email : any, record : any) => {
          return (
            <>
              <div className="changeEmail">
                <GraphicCopyToClipboard text={email}>
                  <span>{truncate(email, { length: 18 })}</span>
                </GraphicCopyToClipboard>
                {/* <ExpandableIcon
                  // onExpand={setExpanded}
                  // expanded={expanded}
                  key={record.id}
                /> */}
              </div>
            </>
          );
        },
      },
      {
        title: "Wallet Address",
        dataIndex: "walletAddress",
        key: "walletAddress",
        width: "11%",
        render: (wallet : string) => (
          <GraphicCopyToClipboard text={wallet}>
            <span>{truncate(wallet, { length: 18 })}</span>
          </GraphicCopyToClipboard>
        ),
      },
      {
        title: "Investments",
        dataIndex: "investments",
        key: "investments",
        width: "11%",
        render: (text : number) => <div>{currencyFormat(text)}</div>,
        align: "center",
      },
      {
        title: "Referrals",
        key: "activeDirectRefarrals",
        dataIndex: "activeDirectRefarrals",
        render: (text : string) => <div>{text}</div>,
        width: "11%",
        align: "center",
      },
      {
        title: "Invest Earnings",
        key: "investEarnings",
        dataIndex: "investEarnings",
        width: "11%",
        render: (text : number) => <div>{currencyFormat(text)}</div>,
        align: "center",
      },
      {
        title: "Downlines",
        key: "downlines",
        dataIndex: "downlines",
        width: "11%",
        align: "center",
      },
      {
        title: "Upline",
        key: "upline",
        dataIndex: "upline",
        width: "11%",
        align: "center",
      },
      {
        title: "Affiliate Earnings",
        key: "affiliateEarnings",
        dataIndex: "affiliateEarnings",
        width: "11%",
        render: (text : number) => <div>{currencyFormat(text)}</div>,
        align: "center",
      },
      {
        title: "withdrawals",
        key: "withdrawals",
        dataIndex: "withdrawals",
        width: "11%",
        render: (text : number) => <div>{currencyFormat(text)}</div>,
        align: "center",
      },
      Table.EXPAND_COLUMN,
    ]
    // console.log("data" , data?.users.find((obj) => obj?.status))
    if(data?.users.find((obj) => obj?.status)){
      columns.push({title : "Status" , key : "status" , dataIndex : "status" , width: "11%",
      render: (text : number) => <Tag color="blue">{text}</Tag>, align: "center"})
      delete columns[4]  
    } 
    return columns
  }

  const dispatch = useAppDispatch();

  const changeHandlerEmail = (event: any) => {
    setsearchEmail((event.target.value as string).trim());
  };

  const changeHandlerWallet = (event: any) => {
    setSearchWallet((event.target.value as string).trim());
  };

  const placementChange = (e: RadioChangeEvent) => {
    // console.log(e.target.value);
    setPlacement(e.target.value);
  };
  // const clickHandler = async () => {
  //   if (placement === "EmailAddress") {
  //     const searchUserByEmail = await dispatch(
  //       getUserAction({ email: searchEmail.trim() })
  //     );
  //   } else if (placement === "WalletAddress") {
  //     await dispatch(getUserAction({ walletAddress: searchWallet.trim() }));
  //   }
  // };

  const expandedRowRender = (record: any) => {
    return <LogsTable id={id}/>;
  };

  // const expandedRowRender = (record : any) => {
  //   return (
  //     <div>
  //       <p>{record.id}</p>
  //       <p>{record.email}</p>
  //     </div>
  //   );
  // };
  return (
    <>
      <FilterUsers
        clickHandler={clickHandler}
        emailValue={searchEmail}
        walletValue={searchWallet}
        changeHandlerEmail={changeHandlerEmail}
        changeHandlerWallet={changeHandlerWallet}
        placementChange={placementChange}
        placement={placement}
        // onFinish={onFinish}
      />
      {status === "pending" ? (
        <Loading />
      ) : (
        <Card>
          <div className="users">
            <ScrollableTable
              expandable={{
                expandedRowRender: expandedRowRender,
                rowExpandable: () => true,
                expandIconAsCell: true,
                // onClick: (e : any) => console.log("e" , e.target.value)
              }}
              onExpand={expandClickHandler}
              columns={getColumnData()}
              dataSource={newData ? newData : null}
              pagination={pagination}
              onChange={onChange}
            />
          </div>
        </Card>
      )}

      {/* <Card>
          <div className="users">
            <ScrollableTable
              expandable={{
                expandedRowRender: (record: any) => (
                  <PassChangesTable data={record.passchages} />
                ),
              }}
              columns={columns}
              dataSource={newData ? newData : null}
            />
          </div>
        </Card> */}
    </>
  );
};

export default UserTable;
