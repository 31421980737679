import Swal, { SweetAlertOptions, SweetAlertResult } from "sweetalert2";
export const SWAL_CONF: SweetAlertOptions = {
  showClass: {
    popup: "animate__animated animate__bounceIn animate__faster",
  },
  hideClass: {
    popup: "animate__animated animate__bounceOut animate__faster",
  },
  showCloseButton: true,
  allowOutsideClick: false,
  buttonsStyling: false,
  allowEscapeKey: true,
  allowEnterKey: true,
  heightAuto: false,
  customClass: {
    confirmButton: "swal2ConfirmButton",
    cancelButton: "swal2CancelButton",
    closeButton: "swal2CloseButton",
    actions: "swal2Actions",
  },
};

export function ajaxSuccessAlert(
  text: string,
  title?: string
): Promise<SweetAlertResult> {
  return Swal.fire({
    ...SWAL_CONF,
    icon: "success",
    title: title,
    text: `${text}`,
    confirmButtonText: "Continue",
  });
}

export function ajaxWarningAlert(
  text?: string,
  title?: string,
  html?: string
): Promise<SweetAlertResult> {
  return Swal.fire({
    ...SWAL_CONF,
    icon: "warning",
    title: title,
    text: `${text}`,
    html: html,
    reverseButtons: true,
    showCancelButton: true,
    showConfirmButton: true,
    confirmButtonText: "Confirm",
    cancelButtonText: "Cancel",
  });
}

export function ajaxErrorAlert(
  text?: string,
  title?: string,
  options?: SweetAlertOptions<any, any>
): Promise<SweetAlertResult> {
  return Swal.fire({
    ...SWAL_CONF,
    icon: "error",
    title: title,
    text: `${text}`,
    ...options,
  });
}
export function errorAlert(
  text?: string,
  title?: string,
  options?: SweetAlertOptions<any, any>
): Promise<SweetAlertResult> {
  return Swal.fire({
    ...SWAL_CONF,
    icon: "error",
    title: title,
    text: `${text}`,
    ...options,
  });
}
