import { createAsyncThunk } from "@reduxjs/toolkit";
import { verifyOtpActionAPI } from "../../../APIs/auth.api";
import Cookie from 'js-cookie';
import { IVeifyOtpData , IVeifyOtpResponse } from "../../../shared/interfaces/Auth/IAdminData";
import { IValidationErrors } from '../../../shared/interfaces/error'
export const verifyOtpAction = createAsyncThunk<
| { verifyOtpData : IVeifyOtpResponse } | undefined ,
IVeifyOtpData | undefined,
{
    rejectValue: IValidationErrors;
}
>("admin/verifyOtp" , async (credential , thunkAPI) => {
    try{
        return await verifyOtpActionAPI(credential)
        .then((response)=>{
            Cookie.set("token" , response.data.data.token)
            return {
                verifyOtpData : response.data.data
            }
        })
    } catch(error : any){
        return thunkAPI.rejectWithValue({
            message: error.response.data.error,
            name: "admin/verifyOtp",
            code: error.response.status,
          });
    }

})