import { EStatuses } from "../../../../shared/enums/EStatuses";
import { IStatus } from "../../../../shared/interfaces/IStatus";
import { IActivity } from "../../../../shared/interfaces/dashboard/IActivity";
import { IGetDashboard } from "../../../../shared/interfaces/dashboard/IGetDashboard";
import { IValidationErrors } from "../../../../shared/interfaces/error";
const financialReportInit = {
  depositCount: 0,
  withdrawCount: 0,
  interestCount: 0,
  referralCount: 0,
};

export const initialData: {
  error: IValidationErrors | undefined;
  recentActivity: {
    data: IActivity;
    status: IStatus;
  };
  getDashboard: {
    data: IGetDashboard;
    status: IStatus;
  };
} = {
  error: {},
  recentActivity: {
    data: {
      activities: [],
      totalItems: 0,
    },
    status: EStatuses.idle,
  },
  getDashboard: {
    data: {
      totalReport: {
        totalInvestments: 0,
        totalActiveInvestments: 0,
        totalExpiredInvestments: 0,
        totalActiveUsers: 0,
        totalDepositBnb: 0,
        totalDepositEth: 0,
        totalPaidInterest: 0,
        totalPaidWithdrawals: 0,
        totalWithdrawBnb: 0,
        totalWithdrawEth: 0,
        totalWithdrawCommssionUsd: 0,
      },
      activeUsers: {
        newUsers: [],
        activeUsers: [],
      },
      financialReport: {
        lastWeekFinancialReport: financialReportInit,
        currentWeekFinancialReport: financialReportInit,
        currentMonthFinancialReport: financialReportInit,
        lastMonthFinancialReport: financialReportInit,
      },
    },
    status: EStatuses.idle,
  },
};
